.grid {
  position: relative;
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: -$main-pad-mb * 0.5;
  margin-right: -$main-pad-mb * 0.5;

  @for $i from 1 through 4 {
    &.dt-#{$i} {
      > * {
        @include desktop {
          flex: 0 0 percentage(1/$i);
          max-width: percentage(1/$i);
        }
      }
    }
    &.tbl-#{$i} {
      > * {
        @include tablet {
          flex: 0 0 percentage(1/$i);
          max-width: percentage(1/$i);
        }
      }
    }
    &.mbl-#{$i} {
      > * {
        @include mobile {
          flex: 0 0 percentage(1/$i);
          max-width: percentage(1/$i);
        }
      }
    }
  }

  .col {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 $main-pad-mb * 0.5 $main-pad-mb * 0.5;
    &.hidden { display: none; }
  }

  @include tablet {
    margin-left: -$main-pad-tb * 0.5;
    margin-right: -$main-pad-tb * 0.5;
    .col {
      padding: 0 $main-pad-tb * 0.5 $main-pad-tb * 0.5;
    }
  }
  @include desktop {
    margin-left: -$main-pad-dt * 0.5;
    margin-right: -$main-pad-dt * 0.5;
    .col {
      padding: 0 $main-pad-dt * 0.5 $main-pad-dt * 0.5;
    }
  }

}

@include mobile{
  .is-mb-row {
    flex-direction: row;
  }
  .is-mb-column {
    flex-direction: column;
  }
}

@include tablet{
  .is-tb-row {
    flex-direction: row;
  }
  .is-tb-column {
    flex-direction: column;
  }
}

@include desktop{
  .is-dt-row {
    flex-direction: row;
  }
  .is-dt-column {
    flex-direction: column;
  }
}
