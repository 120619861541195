.logo {
  position: relative;
  display: block;
  height: 50px;
  img {
    display: block;
    width: auto;
    height: 100%;
  }
  img.wide { display: block; }
  img.short { display: none; }
  &.short {
    img.wide { display: none; }
    img.short { display: block; }
  }
  &.responsive {
    @include mobile {
      img.wide { display: none !important; }
      img.short { display: block !important; }
    }
  }

}
