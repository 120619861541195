@import './modules/burgermenu';

footer.pageFooter {
  position: fixed;
  display: flex;
  padding: $padding-section $padding-section*2;
  background: $base-color;
  border-top: 4px solid $color-blue;
  outline: 4px solid $base-color;
  bottom: 0;
  width: 100vw;
  z-index: 2;
  @include mobile {
    padding: $padding-15;
    .logo {
      height: 25px;
    }
  }
  .container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    z-index: 3;
    .menu-container {
      display: flex;
      .branding-footer-logo {
        display: none;
      }
    }
  }
  &.is-branded {
    .container {
      justify-content: space-between;
      .menu-container {
        align-items: center;
        padding-left: 56.5px; // NOTE: 0.5 x width of MCP
        flex: 0 0 50%;
        max-width: 50%;
      }
      .branding-footer-logo {
        display: block;
        @include mobile {
          display: none;
        }
        @include from($tablet) {
          max-height: 50px;
          margin-left: auto;
          margin-right: auto;
          max-width: 200px;
        }
      }
      .burger-menu {
        @include from($tablet) {
          margin-left: 0;
        }
      }
    }

  }
}
.footer-navigation {
  position: absolute;
  background: $color-blue;
  left: 0;
  bottom: 94px;
  width: 100%;
  height: 0;
  transition: height .5s ease-in-out;
  overflow: hidden;
  @include mobile {
    bottom: 59px;
  }
  &.is-active {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 94px);
    @include mobile {
      height: calc(100vh - 59px);
    }
  }
  &.is-branded {
    @include mobile {
      background: $base-color;
    }
    .branding-container {
      display: none;
      @include mobile {
        display: flex;
        flex-direction: column;
        width: 100%;
        .branding-text {
          text-align: center;
          padding-bottom: $padding-15;
        }
        .branding-logo {
          align-self: center;
          height: 50px;
          width: 225px;
        }
      }
    }
    .menu-hl {
      @include mobile {
        color: $color-blue;
      }
    }
    nav ul li a, .button {
      @include mobile {
        color: $color-blue;
        &:hover, &:active {
          background-color: $color-blue;
          color: $base-color;
        }
      }
    }
  }
  .menu-wrapper {
    display: flex;
    flex-direction: column;
    border-bottom: 4px solid $base-color;
    // NOTE: A better solution for the padding-bottom magic numbers (depending on footer height) should be found.
    height: calc(100vh - 94px);
    padding-bottom: 88px;
    @include mobile {
      height: calc(100vh - 59px);
      padding-bottom: 60px;
    }
  }
  .menu-hl {
    font-size: $fnt_size_xxl;
    font-weight: $fnt_weight_medium;
    color: $base-color;
    margin-bottom: auto;
    padding: $padding-30;
    @include mobile {
      padding: $padding-15;
    }
  }

  nav {
    margin-top: auto;
    padding-top: $padding-30;
    padding-right: $padding-30;
    padding-left: $padding-30;
    @include mobile {
      padding-top: $padding-15;
      padding-right: $padding-15;
      padding-left: $padding-15;
    }
    ul {
      display: block;
      text-align: center;
      li {
        border-top: 2px solid $color-orange;
        &:last-child {
          border-bottom: 2px solid $color-orange;
        }
        a, .button {
          color: $base-color;
          display: flex;
          flex-direction: column;
          font-size: $fnt_size_xxl;
          justify-content: center;
          padding: $padding-15;
          text-decoration: none;
          text-transform: uppercase;
          &:hover, &:active {
            background-color: $base-color;
            color: $color-blue;
          }
          @include mobile {
            align-items: center;
            flex-direction: row;
            padding-left: 0;
            padding-right: 0;
            &:hover, &:active {
              margin-left: -$padding-15;
              margin-right: -$padding-15;
              i {
                margin-left: $padding-15;
              }
              span {
                margin-left: -$padding-15;
              }
            }
            i {
              margin-right: auto;
              position: relative;
            }
            span {
              margin-right: auto;
            }
          }
        }
      }
    }
  }
}
