#main.tutorial {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &.has-assistant {
    justify-content: flex-start;
  }
  .slider {
    // margin-top: 110px;
  }
  .slide-container {
    position: relative;
    display: flex;
    justify-content: center;
    padding-bottom: $padding-section;
    .slide-container-slide {
      position: relative;
      display: flex;
      flex: none;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      background: $color-blue;
      border-radius: $padding-section;
      box-shadow: 0 3px 6px 0 rgba(0,0,0,0.3);
      max-width: 300px;
      height: 427px;
      margin: 0 15px;
      flex: 1;
      .image-container {
        position: relative;
        display: inline-block;
        padding: 15px;
        margin: 30px auto;
        background: rgba($base-color, .5);
        border-radius: 50%;
        .image {
          position: relative;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          width: 180px;
          height: 180px;
          background: $base-color;
          border-radius: 50%;
          border: 1px solid $color-orange;
          img {
            max-width: 115px;
            max-height: 115px;
          }
        }
      }
      .text {
        position: relative;
        display: block;
        padding: 0 $padding-15 $padding-section;
        width: 100%;
        p {
          position: relative;
          display: block;
          color: $base-color;
          font-weight: bold;
          padding: 0;
          padding-left: $padding-section;
          &:before {
            content: "\e903";
            color: $color-orange;
            font-family: "KC21";
            font-size: $padding-section;
            position: absolute;
            left: -2px;
            top: 0;
          }
        }
      }
      @include tablet-only {
        max-width: 400px;
        height: 550px;
        .text {
          max-width: 300px;
        }
      }
    }
  }
  .slider-control-bottomcenter {
    transform: translate(-50%, 110%) !important;
    .controller {
      position: relative;
      display: flex;
      height: 50px;
      justify-content: space-between;
      align-items: center;
      width: 300px;
      background: $base-color;
      border-radius: 50px;
      // border: 1px solid rgba(black, .15);
      box-shadow: 0px 2px 5px 0px rgba(black, .2);
      button.skip {
        position: relative;
        display: block;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: none;
        background: transparent;
        -webkit-appearance: none;
        -moz-appearance: none;
        line-height: 0;
        span {
          position: relative;
          display: flex;
          width: 100%;
          height: 100%;
          justify-content: center;
          align-items: center;
          i { font-size: 20px; }
        }
        &:not([disabled]) {
          cursor: pointer;
          span i { color: $color-orange; }
        }
      }
      .dots-holder {
        position: relative;
        display: flex;
        .dot {
          background: transparent;
          border: 1px solid $color-orange;
          border-radius: 50%;
          width: $padding-section/2;
          height: $padding-section/2;
          padding: 0;
          margin: 0 $padding-section/4;
          outline: 0;
          line-height: 0;
          &.active {
            background: $color-orange;
          }
          &:not(.active) {
            cursor: pointer;
          }
        }
      }
    }
  }
  @include mobile {
    .slider {
      margin-top: 0;
    }
  }
  @include until($desktop) {
    .slider-control-bottomcenter {
      transform: translate(-50%, -50%) !important;
      .controller {
        justify-content: center;
        box-shadow: none;
        background: transparent;
        button.skip { display: none; }
      }
    }
  }
}
