%easeInOut-Inf {
  display: inline-block;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
%stroked {
  fill: none;
  stroke-miterlimit: 10;
  stroke-width: 0.47px;
}

.assistant {
  position: relative;
  display: block;
  width: 280px;
  .holder {
    position: relative;
    display: block;
    width: 100%;
    // padding-top: 92.85714286%;
    padding-top: 93%;
    // height: 260px;
  }
  line-height: 0;
  // border: 1px solid red;
  // z-index: 300;
  .apng {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
  }

  svg {
    $col-body-base: #aab7c0;
    $col-body-shade: #b9c9d2;
    $col-metal-dark: #899ba4;
    $col-metal-light: #b2c2ca;
    $col-eye-socket: #94a6b0;
    $col-accent: $color-blue;

    position: absolute;
    display: block;
    width: 85%;
    height: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -47.5%);
    z-index: 0;
    opacity: 0;
    .guide {
      @extend %stroked;
      opacity: 0;
    }
    .body {
      .antenna {
        .ball,
        .socket {
          fill: $col-accent;
        }
        .wire {
          @extend %stroked;
          stroke-width: 3.15px;
          stroke: $col-body-base;
        }
      }
      .ball {
        .mainshape {
          fill: $col-body-base;
        }
        .shade {
          fill: $col-body-shade;
        }
        .equator {
          @extend %stroked;
          stroke: $col-body-base;
        }
      }
      .mouth {
        fill: #e8e8e8;
      }
      .eye-group {
        .socket {
          fill: $col-eye-socket;
        }
        .eyeball,
        .glow {
          fill: $base-color;
        }
        .iris {
          fill: $col-accent;
        }
        .shine {
          fill: $base-color;
        }
      }
      .blink-group {
        .slit {
          @extend %stroked;
          stroke: $col-eye-socket;
        }
        .eyeball {
          fill: $base-color;
        }
      }
    }
    .armsockets {
      .socket {
        fill: $col-accent;
      }
    }
    .arm-group {
      .socket {
        fill: $col-accent;
      }
      .joint {
        fill: $col-metal-dark;
      }
      .arm {
        fill: $col-metal-light;
      }
      .hand {
        fill: $col-metal-light;
      }
    }
    .jet-group {
      .funnel {
        fill: $col-body-shade;
      }
      .socket {
        fill: $col-metal-dark;
      }
      .flame-left {
        fill: url(#flameJetL);
        opacity: 0;
      }
      .flame-right {
        fill: url(#flameJetR);
        opacity: 0;
      }
    }
    g#Blink {
      display: none;
    }
    &.blink g#Blink {
      display: inline-block;
    }

    .flameTip,
    .flameBase {
      @extend %easeInOut-Inf;
    }
    .flameTip {
      stop-color: #fcee21;
      stop-opacity: 0.6;
      animation-duration: 0.8s;
      animation-name: flameTip;
    }
    .flameBase {
      stop-color: #ff931e;
      stop-opacity: 1;
      animation-duration: 0.8s;
      animation-name: flameBase;
    }
    .blobShadow.inner {
      stop-color: rgb(0, 0, 0);
      stop-opacity: 0.3;
    }
    .blobShadow.outer {
      stop-color: rgb(0, 0, 0);
      stop-opacity: 0;
    }

    g#Arm_L {
      transform-origin: 205.21px 151.93px;
      transform: rotate(40deg);
    }
    g#Arm_R {
      transform-origin: 84.79px 151.93px;
      transform: rotate(-45deg);
    }
    g#UnterarmL {
      transform-origin: 251.71px 180.22px;
      transform: rotate(-57deg);
    }
    g#UnterarmR {
      // transform-origin: 38.37px 157.5px;
      transform-origin: 39px 175px;
      transform: rotate(64deg);
    }
  }
  &.idle {
    svg {
      opacity: 1;
      g#Robot,
      g#Arm_L,
      g#Arm_R,
      g#UnterarmR,
      g#UnterarmL,
      g#Robot,
      g#Arm_L,
      g#Arm_R {
        @extend %easeInOut-Inf;
        animation-duration: 2s;
      }
      g#UnterarmL {
        animation-duration: 3s;
      }
      g#UnterarmR {
        animation-duration: 4s;
      }
      g#Robot {
        animation-name: idleRobot;
      }
      g#Arm_L {
        animation-name: idleArmL;
        animation-delay: 0.25s;
      }
      g#Arm_R {
        animation-name: idleArmR;
        animation-delay: 0.5s;
      }
      g#UnterarmL {
        animation-name: idleUArmL;
        animation-delay: 0.5s;
      }
      g#UnterarmR {
        animation-name: idleUArmR;
        animation-delay: 0.33s;
      }
      .shadow {
        @extend %easeInOut-Inf;
        transform-origin: 149.11px 250px;
        animation-duration: 2s;
        animation-name: shadow;
      }
    }
  }
  &.eyes-glow {
    svg {
      .eye-group {
        .glow {
          filter: url(#eye-glow);
          @extend %easeInOut-Inf;
          animation-duration: 1s;
          animation-name: eyeGlow;
        }
      }
    }
  }

  // @include mobile {
  //   width: 175px;
  // }
  // @include tablet {
  //   width: 280px;
  // }

  &.twirl {
    .apng {
      top: 0;
      // transition: top 1480ms cubic-bezier(1,.01,.39,1.52);
      &.animated {
        transition: top 1000ms linear;
      }
      &.linger {
        //top: 20vh;
        //opacity: 0;
      }
    }
  }
}

#main.tutorial {
  .assistant {
    // position: absolute;
    // top: 0;
    @include tablet {
      width: 210px;
    }
    &.twirl,
    &.idle,
    &.rollin {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

@keyframes idleRobot {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 10px);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes idleArmL {
  0% {
    transform: rotate(40deg);
  }
  50% {
    transform: rotate(30deg);
  }
  100% {
    transform: rotate(40deg);
  }
}
@keyframes idleArmR {
  0% {
    transform: rotate(-45deg);
  }
  50% {
    transform: rotate(-35deg);
  }
  100% {
    transform: rotate(-45deg);
  }
}
@keyframes idleUArmL {
  0% {
    transform: rotate(-57deg);
  }
  50% {
    transform: rotate(-33deg);
  }
  100% {
    transform: rotate(-57deg);
  }
}
@keyframes idleUArmR {
  0% {
    transform: rotate(64deg);
  }
  50% {
    transform: rotate(34deg);
  }
  100% {
    transform: rotate(64deg);
  }
}
@keyframes flameTip {
  0% {
    stop-color: #fcee21;
  }
  50% {
    stop-color: #ffcc5e;
  }
  100% {
    stop-color: #fcee21;
  }
}
@keyframes flameBase {
  0% {
    stop-color: #ff931e;
  }
  50% {
    stop-color: #ffcc5e;
  }
  100% {
    stop-color: #ff931e;
  }
}
@keyframes shadow {
  0% {
    transform: scale(0.7);
    opacity: 0.5;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.7);
    opacity: 0.5;
  }
}
@keyframes eyeGlow {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
