@font-face {
  font-family: 'Barlow';
  src: local('Barlow Regular'), local('Barlow-Regular'),
         url('/fonts/Barlow/Barlow-Regular.woff2') format('woff2'),
         url('/fonts/Barlow/Barlow-Regular.woff')  format('woff'),
         url('/fonts/Barlow/Barlow-Regular.ttf')   format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: local('Barlow Italic'), local('Barlow-Italic'),
         url('/fonts/Barlow/Barlow-Italic.woff2') format('woff2'),
         url('/fonts/Barlow/Barlow-Italic.woff')  format('woff'),
         url('/fonts/Barlow/Barlow-Italic.ttf')   format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Barlow';
  src: local('Barlow Medium'), local('Barlow-Medium'),
      url('/fonts/Barlow/Barlow-Medium.woff2') format('woff2'),
      url('/fonts/Barlow/Barlow-Medium.woff') format('woff'),
      url('/fonts/Barlow/Barlow-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: local('Barlow Medium Italic'), local('Barlow-MediumItalic'),
      url('/fonts/Barlow/Barlow-MediumItalic.woff2') format('woff2'),
      url('/fonts/Barlow/Barlow-MediumItalic.woff') format('woff'),
      url('/fonts/Barlow/Barlow-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Barlow';
  src: local('Barlow SemiBold'), local('Barlow-SemiBold'),
      url('/fonts/Barlow/Barlow-SemiBold.woff2') format('woff2'),
      url('/fonts/Barlow/Barlow-SemiBold.woff') format('woff'),
      url('/fonts/Barlow/Barlow-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: local('Barlow SemiBold Italic'), local('Barlow-SemiBoldItalic'),
      url('/fonts/Barlow/Barlow-SemiBoldItalic.woff2') format('woff2'),
      url('/fonts/Barlow/Barlow-SemiBoldItalic.woff') format('woff'),
      url('/fonts/Barlow/Barlow-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

$fnt_weight_regular: 400;
$fnt_weight_medium: 500;
$fnt_weight_semibold: 600;

$fnt_size_root: 16px;
$fnt_size_root-mobile: 14px;

$fnt_size_s: 0.75rem;
$fnt_size_m: 1rem;
$fnt_size_l: 1.1rem;
$fnt_size_xl: 1.25rem;
$fnt_size_xxl: 1.6rem;
// $fnt_size_mobile_xxl: 23px;
// $fnt_size_m-mobile: 14px;

$fnt_size_crazy: 2rem;
$fnt_size_lunatic: 3rem; //🤭

$fnt_family_default: 'Barlow', sans-serif;

// KC21 Symbols Font

@font-face {
  font-family: 'KC21';
  src:  url('/fonts/KC21/kc21.eot?g9x7l3');
  src:  url('/fonts/KC21/kc21.eot?g9x7l3#iefix') format('embedded-opentype'),
    url('/fonts/KC21/kc21.ttf?g9x7l3') format('truetype'),
    url('/fonts/KC21/kc21.woff?g9x7l3') format('woff'),
    url('/fonts/KC21/kc21.svg?g9x7l3#kc21') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'kc21' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-error:before {
  content: "\e900";
}
.icon-error_outline:before {
  content: "\e901";
}
.icon-fast_forward:before {
  content: "\e92b";
}
.icon-pause:before {
  content: "\e902";
}
.icon-play_arrow:before {
  content: "\e903";
}
.icon-call:before {
  content: "\e904";
}
.icon-clear_all:before {
  content: "\e905";
}
.icon-chat_bubble_outline:before {
  content: "\e906";
}
.icon-mail_outline:before {
  content: "\e907";
}
.icon-lightbulb:before {
  content: "\e928";
}
.icon-add_circle_outline:before {
  content: "\e92e";
}
.icon-control_point:before {
  content: "\e92e";
}
.icon-clear:before {
  content: "\e908";
}
.icon-close:before {
  content: "\e908";
}
.icon-forward:before {
  content: "\e909";
}
.icon-save:before {
  content: "\e933";
}
.icon-save_alt:before {
  content: "\e931";
}
.icon-access_time:before {
  content: "\e90a";
}
.icon-query_builder:before {
  content: "\e90a";
}
.icon-schedule:before {
  content: "\e90a";
}
.icon-attach_file:before {
  content: "\e92f";
}
.icon-cloud_download:before {
  content: "\e930";
}
.icon-keyboard_arrow_left:before {
  content: "\e90b";
}
.icon-keyboard_arrow_right:before {
  content: "\e90c";
}
.icon-dehaze:before {
  content: "\e90d";
}
.icon-remove_red_eye:before {
  content: "\e926";
}
.icon-visibility:before {
  content: "\e926";
}
.icon-directions_walk:before {
  content: "\e92c";
}
.icon-local_print_shop:before {
  content: "\e90e";
}
.icon-print:before {
  content: "\e90e";
}
.icon-pin_drop:before {
  content: "\e90f";
}
.icon-arrow_forward:before {
  content: "\e910";
}
.icon-check:before {
  content: "\e911";
}
.icon-menu:before {
  content: "\e912";
}
.icon-keyboard_control:before {
  content: "\e913";
}
.icon-more_vert:before {
  content: "\e914";
}
.icon-arrow_left:before {
  content: "\e915";
}
.icon-arrow_right:before {
  content: "\e916";
}
.icon-arrow_back_ios:before {
  content: "\e917";
}
.icon-arrow_forward_ios:before {
  content: "\e918";
}
.icon-priority_high:before {
  content: "\e919";
}
.icon-people_outline:before {
  content: "\e934";
}
.icon-person:before {
  content: "\e921";
}
.icon-person_outline:before {
  content: "\e922";
}
.icon-perm_identity:before {
  content: "\e922";
}
.icon-star:before {
  content: "\e937";
}
.icon-star_outline:before {
  content: "\e938";
}
.icon-exit_to_app:before {
  content: "\e932";
}
.icon-help:before {
  content: "\e91a";
}
.icon-home:before {
  content: "\e923";
}
.icon-hourglass_empty:before {
  content: "\e91b";
}
.icon-hourglass_full:before {
  content: "\e91c";
}
.icon-info:before {
  content: "\e91d";
}
.icon-info_outline:before {
  content: "\e91e";
}
.icon-lock_outline:before {
  content: "\e935";
}
.icon-settings:before {
  content: "\e91f";
}
.icon-help_outline:before {
  content: "\e920";
}
.icon-fingerprint:before {
  content: "\e936";
}
.icon-lightbulb_outline:before {
  content: "\e929";
}
.icon-touch_app:before {
  content: "\e92a";
}
.icon-contact_support:before {
  content: "\e924";
}
.icon-home_filled:before {
  content: "\e925";
}
.icon-logout:before {
  content: "\e92d";
}
.icon-bolt:before {
  content: "\e927";
}
