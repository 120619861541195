$base-color: #fff;
$outline-color: #909090;

$color-font: #000;
$color-font-highlight: #00ffae;
$color-font-highlight-light: #b2ffe7;

$color-blue: #0070B2;
$color-orange: #f97013;
$color-black-30: rgba(black, .3);
$color-orange-30: rgba($color-orange, .3);
$color-blue-30: rgba($color-blue, .3);
$color-white-transparent-background: rgba(255,255,255, .9);
$color-error: #ff0000;

$padding-section: 20px;
$padding-30: 30px;
$padding-15: 15px;
$main-pad-dt: 40px;
$main-pad-tb: 30px;
$main-pad-mb: 15px;
