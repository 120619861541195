$hamburger-padding-x           : 0;
$hamburger-padding-y           : 0;
$hamburger-layer-width         : 20px;
$hamburger-layer-height        : 2px;
$hamburger-layer-spacing       : 4px;
$hamburger-layer-border-radius : 0;
$hamburger-hover-opacity       : 1;
$hamburger-types               : (squeeze);

$hamburger-layer-color         : #000 !default;
$hamburger-active-layer-color  : $hamburger-layer-color !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;

$hamburger-hover-use-filter   : false !default;
$hamburger-hover-filter       : opacity(50%) !default;
$hamburger-active-hover-filter: $hamburger-hover-filter !default;

@import './../../node_modules/hamburgers/_sass/hamburgers/base';
@import './../../node_modules/hamburgers/_sass/hamburgers/types/squeeze';

.burger-menu {
  position: relative;
  margin-left: auto;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  .hamburger {
    border-radius: 50%;
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.5);
    height: 50px;
    width: 50px;
    @include mobile {
      box-shadow: none;
      border-radius: 0;
      // TODO: Buttons sollten 50x50px sein / Elemente des footers sollten individuelles padding bekommen
      width: 25px;
      height: 25px;
    }
  }
}
