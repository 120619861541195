input::-ms-clear,
input::-ms-reveal {
  display: none;
}

.ll-formgroup {
  .ll-formelement {
    margin-bottom: $padding-section/2;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.ll-formelement {
  padding: 2px;
  position: relative;
  width: 100%;
  > input,
  > select,
  &.ll-formelement-checkbox > label {
    border-radius: 2px;
    text-align: center;
    border: 0;
    background: $base-color;
    width: 100%;
  }
  > input,
  > select {
    font-size: 16px;
  }
  > select {
    padding: 0 8px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat, repeat;
    background-position: right 10px top 50%, 0 0;
    background-size: 0.65em auto, 100%;
    &::-ms-expand {
      display: none;
    }
  }
  &.ll-formelement-checkbox {
    > span {
      display: none;
    }
    > label {
      display: flex;
      padding: 0;
      align-items: flex-start;
      justify-content: center;
      text-align: left;
      margin-top: 4px;
      > input {
        flex: none;
        margin-right: 8px;
        margin-top: 4px;
      }
    }
  }
  &.ll-formelement-input > .iconHolder {
    position: absolute;
    height: 100%;
    width: $padding-section * 1.5;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    top: 0;
    background: none;
    border: 0;
    padding: 0;
    &.valid {
      color: $color-blue;
    }
    &.invalid {
      color: $color-orange;
    }
    &.on {
      color: $outline-color;
    }
    &.off {
      color: $color-orange;
    }
  }
  > span {
    &:before,
    &:after {
      position: absolute;
      background: transparent;
      border: 2px solid $outline-color;
      z-index: -1;
      content: "";
      width: 10px;
      height: 10px;
      transition: width 125ms ease-in, height 125ms ease-in;
    }
    &.top {
      &:before {
        border-top-left-radius: 2px;
        left: 0;
        top: 0;
      }
      &:after {
        border-top-right-radius: 2px;
        right: 0;
        top: 0;
      }
    }
    &.bottom {
      &:before {
        border-bottom-left-radius: 2px;
        bottom: 0;
        left: 0;
      }
      &:after {
        border-bottom-right-radius: 2px;
        bottom: 0;
        right: 0;
      }
    }
  }
  &.focus {
    > span {
      &:before,
      &:after {
        transition: width 250ms ease-out, height 250ms ease-out;
        width: 55%;
        height: 55%;
      }
    }
  }
}

$primary-color: $color-blue;
@import "loaders.css/src/animations/line-scale-pulse-out-rapid.scss";
