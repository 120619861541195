.cookie-overlay {
  position: fixed;
  display: flex;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background: rgba(black, .3);
  opacity: 1;
  transition: top .5s ease-in-out,
              opacity .7s ease-in-out;
  &.hide {
    top: calc(100vh - 94px);
    opacity: 0;
    transition: top .5s ease-in-out,
                opacity 0 ease-out;
    @include mobile {
      top: calc(100vh - 59px);
    }
  }
  .panel {
    position: relative;
    display: block;
    background: $base-color;
    border-radius: 20px;
    width: 300px;
    padding: 15px;
    .heading {
      font-size: 20px;
      font-weight: $fnt_weight_medium;
      color: $color-blue;
      margin-bottom: 1rem;
    }
    .description, .link {
      font-size: 18px;
      margin-bottom: 8px;
    }
    .link {
      color: $color-blue;
      cursor: pointer;
      font-style: italic;
    }
    button.btn {
      @include defButton;
      cursor: pointer;
      width: 100%;
      padding: 15px;
      margin-top: 1rem;
      font-size: 18px;
      border: 1px solid $color-orange;
      border-radius: .75rem;
    }
  }
}
