.SplashScreen {
  text-align: center;
  background: $base-color;
}

.SplashScreen-logo {
  width: 40vmin;
  pointer-events: none;
}

.SplashScreen-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  p.subtitle {
    margin-top: 0;
  }
}
