// NOTE: Magic numbers.. aber sollte ohne JS nicht anders gehen
$main-max-default: 200px; // 94px (footerheight) + 87px (headerheight) 181
$main-max-mb: 129px; // 59px (footerheight) + 70px (headerheight)

.overlay {
  position: fixed;
  display: block;
  background: $base-color;
  max-height: 1px;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
  top: 0;
  left: 0;
  width: 100%;
  header.pageHeader {
    button.overlayCloser {
      @include defButton;
      padding: 0 $padding-section 0 0;
      margin: 0 0 1rem;
      cursor: pointer;
      @include mobile {
        padding-right: 0;
      }
      span {
        font-size: $fnt_size_xxl;
      }
    }
  }
  #main {
    max-height: calc(100vh - #{$main-max-default});
    // bottom: 94px;
    top: 0;
    overflow-y: scroll;
    padding: 0 $main-pad-mb 0;

    p {
      font-size: $fnt_size_root-mobile;
      line-height: 20px;
      margin-bottom: 30px;
    }

    .intro {
      font-size: 16px;
      line-height: 20px;
      font-weight: $fnt_weight_medium;
    }

    h1,
    h2,
    h3 {
      font-weight: $fnt_weight_medium;
      margin-bottom: 0.75rem;
    }

    h1 {
      font-size: 23px;
      line-height: 30px;
      color: $color-orange;
    }
    h2 {
      font-size: 18px;
      line-height: 25px;
      color: $color-blue;
    }
    h3 {
      font-size: 16px;
      line-height: 20px;
      color: $color-font;
    }

    a:link {
      text-decoration: none;
      color: $color-blue;
      font-style: italic;
      &:hover {
        text-decoration: underline;
      }
    }

    ul:not(.slider-list) {
      list-style: none;
      margin-left: 0;
      padding-left: 0;
      margin-bottom: 2rem;
      li {
        position: relative;
        display: block;
        padding-left: calc(1rem + 15px);
        // text-indent: -1em;
        margin-bottom: 15px;
        &:before {
          content: "\e903";
          font-family: 'kc21';
          color: $color-orange;
          // padding-right: 15px;
          position: absolute;
          display: block;
          left: 0;
        }
      }
    }


    @include tablet {
      padding: 0 $main-pad-dt $main-pad-dt;
      p {
        font-size: $fnt_size_root;
        line-height: 20px;
      }
      .intro {
        font-size: 18px;
        line-height: 25px;
      }
      h1 {
        font-size: 25px;
        line-height: 30px;
      }
      h2 {
        font-size: 20px;
        line-height: 25px;
      }
      h3 {
        font-size: 18px;
        line-height: 25px;
      }
    }
    @include desktop {
      &.tutorial {
        padding: 0 $main-pad-dt #{$main-pad-dt + 25px};
      }
    }
  }
  &.is-active {
    height: calc(100vh - 94px);
    max-height: calc(100vh - 94px);
    @include mobile {
      height: calc(100vh - 59px);
      max-height: calc(100vh - 59px);
      #main {
        max-height: calc(100vh - #{$main-max-mb});
      }
    }
  }

  header.pageHeader {
    .container {
      @include mobile {
        flex-direction: row;
        align-items: center;
      }
    }
  }
}

.small-overlay {
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: $color-white-transparent-background;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &.not-clickable {
    pointer-events: none;
  }
}
