header.pageHeader {
  padding: $padding-section;
  @include mobile {
    padding: $main-pad-mb;
  }
  background: $base-color;

  .container {
    position: relative;
    display: flex;
    @include mobile {
      flex-direction: column;
    }
    .headingInstructions {
      position: relative;
      display: block;
      flex: 1;
      .pageHeadline, .pageSecHeadline, .description {
        padding-left: $padding-section;
        @include mobile { padding-left: 0; }
      }
      .pageHeadline, .pageSecHeadline {
        margin: 0 0 1rem;
      }
      .pageHeadline {
        font-size: $fnt_size_xxl;
        color: $color-orange;
      }
      .pageSecHeadline {
        font-size: $fnt_size_xl;
        color: $color-blue;
      }

      .description {
        font-size: $fnt_size_l;
        font-weight: $fnt_weight_medium;
      }

      .instructions {
        position: relative;
        display: inline-block;
        padding: $padding-section;
        @include mobile {
          padding: $main-pad-mb;
          padding-left: 10px;
        }
        background: $base-color;
        border-radius: 1em;
        max-width: 100%;
        > ul {
          position: relative;
          margin: 0;
          list-style: none;
          padding-left: $padding-section;
          li {
            position: relative;
            line-height: 1.4;
          }
          li::before {
            content: '○';
            position: absolute;
            left: -$padding-section;
            color: $color-orange;
          }
          li.what::before {
            content: '\e91e';
            font-family: 'KC21';
          }
          li.how::before {
            content: '\e92a';
            font-family: 'KC21';
          }
          li.time::before {
            content: '\e90a';
            font-family: 'KC21';
          }
          li.hint::before {
            content: '\e92e';
            font-family: 'KC21';
          }
        }
      }

    }
    .infoImage {
      position: relative;
      display: block;
      padding-right: $padding-section;
      padding-bottom: $padding-section;
      align-self: flex-end;
      @include mobile {
        padding-right: 0;
        align-self: flex-start;
      }
      .media {
        width: 320px; // NOTE: Magic number alert!
        height: auto;
      }
    }
  }

  &.intro {
    background: $color-blue;
    .container .headingInstructions .pageHeadline {
      color: $base-color;
    }
  }
  &.tutorial {
    @include mobile {
      .container .headingInstructions .pageHeadline {
        margin-bottom: 0;
      }
    }
  }
}
.fragebogen_skala {
  display: flex;
  flex-wrap: wrap;
  max-width: 310px;
  width: 100%;
  @include from($tablet) {
    align-items: flex-end;
    padding-right: $padding-section;
    padding-top: $padding-section;
  }
  @include from($desktop) {
    flex-wrap: nowrap;
    max-width: 440px; // NOTE: skala + text left/right in one row
  }
  .text {
    font-size: 11px;
    padding-bottom: $padding-section;
    @include mobile {
      padding-top: $padding-15;
      padding-bottom: $padding-15;
    }
    text-align: center;
    width: 50px;
    &.left {
      margin-right: auto;
      order: 2;
      @include from($desktop) {
        margin-right: $padding-15;
        order: 1;
      }
    }
    &.right {
      margin-left: auto;
      order: 3;
      @include from($desktop) {
        margin-left: $padding-15;
      }
    }
  }
  .skala {
    .cls-1 { fill: $color-orange; }
    .cls-2 { fill: rgba(0,0,0, .3); }
    order: 1;
    max-width: 310px;
    width: 100%;
    @include from($desktop) {
      order: 2;
      svg {
        padding-bottom: $padding-section;
      }
    }
  }
}
