@mixin failFlag {
  $col-bg: lighten($color-error, 40%);
  $col-text: $color-error;
  position: relative;
  background: $col-bg;
  border: 1px solid $color-error;
  border-radius: 6px;
  color: $col-text;
  &:after,
  &:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-color: rgba(255, 0, 0, 0);
    border-bottom-color: $col-bg;
    border-width: 8px;
    margin-left: -8px;
  }
  &:before {
    border-color: rgba(255, 0, 0, 0);
    border-bottom-color: $color-error;
    border-width: 9px;
    margin-left: -9px;
  }
}
