.svg-yWTa6b8lQ{
  /*
  Used In:
  SF_3_vor_1.svg, elements.2.elements.0.elements.0.attributes.fill
  SF_3_vor_1.svg, elements.2.elements.1.elements.0.attributes.fill
  SF_3_vor_4.svg, elements.2.elements.0.attributes.fill
  SF_3_vor_beispiel.svg, elements.2.elements.0.elements.0.attributes.fill
  SF_3_vor_beispiel.svg, elements.2.elements.1.attributes.fill
  gm_2_8_1.svg, elements.2.elements.5.attributes.fill
  gm_2_8_3.svg, elements.2.elements.4.attributes.fill
  k_1_vor_beispiel.svg, elements.2.elements.0.elements.0.attributes.fill
  k_2_vor.svg, elements.2.elements.2.attributes.fill
  */
  fill: #666666;
}

.svg-u-5GJkJw34{
  /*
  Used In:
  SF_3_vor_1.svg, elements.2.elements.0.elements.1.attributes.fill
  SF_3_vor_1.svg, elements.2.elements.1.elements.1.attributes.fill
  gm_2_10_1.svg, elements.2.elements.0.attributes.fill
  gm_2_10_1.svg, elements.2.elements.1.attributes.fill
  gm_2_10_1.svg, elements.2.elements.2.attributes.fill
  gm_2_10_1.svg, elements.2.elements.3.attributes.fill
  gm_2_10_2.svg, elements.2.elements.0.elements.0.attributes.fill
  gm_2_10_2.svg, elements.2.elements.0.elements.1.attributes.fill
  gm_2_10_2.svg, elements.2.elements.0.elements.3.attributes.fill
  gm_2_10_3.svg, elements.2.elements.1.attributes.fill
  gm_2_10_3.svg, elements.2.elements.2.attributes.fill
  gm_2_10_3.svg, elements.2.elements.3.attributes.fill
  gm_2_10_3.svg, elements.2.elements.4.elements.0.attributes.fill
  gm_2_10_4.svg, elements.2.elements.0.attributes.fill
  gm_2_10_4.svg, elements.2.elements.1.attributes.fill
  gm_2_10_4.svg, elements.2.elements.2.attributes.fill
  gm_2_10_4.svg, elements.2.elements.3.attributes.fill
  gm_2_10_4.svg, elements.2.elements.4.attributes.fill
  gm_2_10_gesamt.svg, elements.2.elements.0.elements.0.attributes.fill
  gm_2_10_gesamt.svg, elements.2.elements.0.elements.1.attributes.fill
  gm_2_10_gesamt.svg, elements.2.elements.0.elements.4.attributes.fill
  gm_2_10_gesamt.svg, elements.2.elements.0.elements.5.attributes.fill
  gm_2_10_vor.svg, elements.2.elements.0.elements.0.attributes.fill
  gm_2_10_vor.svg, elements.2.elements.0.elements.1.attributes.fill
  gm_2_10_vor.svg, elements.2.elements.0.elements.3.attributes.fill
  gm_2_11_1.svg, elements.2.elements.6.attributes.fill
  gm_2_11_2.svg, elements.2.elements.0.elements.3.attributes.fill
  gm_2_11_3.svg, elements.2.elements.0.elements.9.attributes.fill
  gm_2_11_4.svg, elements.2.elements.6.attributes.fill
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.12.attributes.fill
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.13.attributes.fill
  gm_2_11_vor.svg, elements.2.elements.0.elements.8.attributes.fill
  gm_2_12_1.svg, elements.2.elements.0.attributes.fill
  gm_2_12_1.svg, elements.2.elements.1.attributes.fill
  gm_2_12_1.svg, elements.2.elements.2.attributes.fill
  gm_2_12_1.svg, elements.2.elements.4.attributes.fill
  gm_2_12_2.svg, elements.2.elements.0.attributes.fill
  gm_2_12_2.svg, elements.2.elements.1.attributes.fill
  gm_2_12_2.svg, elements.2.elements.2.attributes.fill
  gm_2_12_2.svg, elements.2.elements.3.attributes.fill
  gm_2_12_3.svg, elements.2.elements.0.attributes.fill
  gm_2_12_3.svg, elements.2.elements.1.attributes.fill
  gm_2_12_3.svg, elements.2.elements.2.attributes.fill
  gm_2_12_3.svg, elements.2.elements.3.attributes.fill
  gm_2_12_4.svg, elements.2.elements.0.attributes.fill
  gm_2_12_4.svg, elements.2.elements.1.attributes.fill
  gm_2_12_4.svg, elements.2.elements.2.attributes.fill
  gm_2_12_4.svg, elements.2.elements.3.attributes.fill
  gm_2_12_gesamt.svg, elements.2.elements.0.attributes.fill
  gm_2_12_gesamt.svg, elements.2.elements.1.attributes.fill
  gm_2_12_gesamt.svg, elements.2.elements.2.attributes.fill
  gm_2_12_gesamt.svg, elements.2.elements.3.attributes.fill
  gm_2_12_gesamt.svg, elements.2.elements.4.attributes.fill
  gm_2_12_gesamt.svg, elements.2.elements.5.attributes.fill
  gm_2_12_vor.svg, elements.2.elements.0.attributes.fill
  gm_2_12_vor.svg, elements.2.elements.1.attributes.fill
  gm_2_12_vor.svg, elements.2.elements.2.attributes.fill
  gm_2_12_vor.svg, elements.2.elements.3.attributes.fill
  gm_2_1_1.svg, elements.2.elements.1.elements.0.attributes.fill
  gm_2_1_2.svg, elements.2.elements.0.attributes.fill
  gm_2_1_2.svg, elements.2.elements.1.elements.0.attributes.fill
  gm_2_1_3.svg, elements.2.elements.0.elements.0.attributes.fill
  gm_2_1_3.svg, elements.2.elements.2.attributes.fill
  gm_2_1_4.svg, elements.2.elements.0.attributes.fill
  gm_2_1_gesamt.svg, elements.2.elements.1.elements.0.attributes.fill
  gm_2_1_gesamt.svg, elements.2.elements.5.attributes.fill
  gm_2_1_gesamt.svg, elements.2.elements.6.attributes.fill
  gm_2_1_vor.svg, elements.2.elements.3.attributes.fill
  gm_2_2_1.svg, elements.2.elements.0.elements.1.attributes.fill
  gm_2_2_2.svg, elements.2.elements.3.attributes.fill
  gm_2_2_3.svg, elements.2.elements.0.elements.3.attributes.fill
  gm_2_2_4.svg, elements.2.elements.0.elements.3.attributes.fill
  gm_2_2_gesamt.svg, elements.2.elements.0.elements.5.attributes.fill
  gm_2_2_gesamt.svg, elements.2.elements.0.elements.6.attributes.fill
  gm_2_2_vor.svg, elements.2.elements.3.attributes.fill
  gm_2_3_1.svg, elements.2.elements.0.elements.1.attributes.fill
  gm_2_3_1.svg, elements.2.elements.0.elements.2.attributes.fill
  gm_2_3_2.svg, elements.2.elements.0.attributes.fill
  gm_2_3_3.svg, elements.2.elements.0.elements.2.attributes.fill
  gm_2_3_4.svg, elements.2.elements.0.elements.0.attributes.fill
  gm_2_3_4.svg, elements.2.elements.0.elements.2.attributes.fill
  gm_2_3_gesamt.svg, elements.2.elements.0.elements.1.attributes.fill
  gm_2_3_gesamt.svg, elements.2.elements.0.elements.2.attributes.fill
  gm_2_3_gesamt.svg, elements.2.elements.0.elements.3.attributes.fill
  gm_2_3_gesamt.svg, elements.2.elements.0.elements.4.attributes.fill
  gm_2_3_vor.svg, elements.2.elements.0.elements.1.attributes.fill
  gm_2_3_vor.svg, elements.2.elements.0.elements.2.attributes.fill
  gm_2_4_1.svg, elements.2.elements.1.attributes.fill
  gm_2_4_2.svg, elements.2.elements.0.attributes.fill
  gm_2_4_3.svg, elements.2.elements.0.attributes.fill
  gm_2_4_3.svg, elements.2.elements.2.attributes.fill
  gm_2_4_4.svg, elements.2.elements.3.attributes.fill
  gm_2_4_gesamt.svg, elements.2.elements.1.attributes.fill
  gm_2_5_1.svg, elements.2.elements.0.attributes.fill
  gm_2_5_1.svg, elements.2.elements.1.attributes.fill
  gm_2_5_2.svg, elements.2.elements.0.attributes.fill
  gm_2_5_2.svg, elements.2.elements.2.attributes.fill
  gm_2_5_3.svg, elements.2.elements.0.elements.0.attributes.fill
  gm_2_5_3.svg, elements.2.elements.0.elements.3.attributes.fill
  gm_2_5_4.svg, elements.2.elements.0.attributes.fill
  gm_2_5_4.svg, elements.2.elements.1.attributes.fill
  gm_2_5_gesamt.svg, elements.2.elements.0.attributes.fill
  gm_2_5_gesamt.svg, elements.2.elements.2.attributes.fill
  gm_2_5_gesamt.svg, elements.2.elements.3.attributes.fill
  gm_2_5_gesamt.svg, elements.2.elements.7.attributes.fill
  gm_2_5_gesamt.svg, elements.2.elements.8.attributes.fill
  gm_2_5_vor.svg, elements.2.elements.0.attributes.fill
  gm_2_5_vor.svg, elements.2.elements.2.attributes.fill
  gm_2_5_vor.svg, elements.2.elements.3.attributes.fill
  gm_2_5_vor.svg, elements.2.elements.5.attributes.fill
  gm_2_6_1.svg, elements.2.elements.0.attributes.fill
  gm_2_6_2.svg, elements.2.elements.0.attributes.fill
  gm_2_6_2.svg, elements.2.elements.1.elements.0.attributes.fill
  gm_2_6_3.svg, elements.2.elements.0.attributes.fill
  gm_2_6_3.svg, elements.2.elements.1.elements.0.attributes.fill
  gm_2_6_4.svg, elements.2.elements.0.attributes.fill
  gm_2_6_gesamt.svg, elements.2.elements.0.attributes.fill
  gm_2_6_gesamt.svg, elements.2.elements.1.attributes.fill
  gm_2_6_vor.svg, elements.2.elements.0.attributes.fill
  gm_2_7_1.svg, elements.2.elements.0.attributes.fill
  gm_2_7_1.svg, elements.2.elements.1.attributes.fill
  gm_2_7_1.svg, elements.2.elements.2.attributes.fill
  gm_2_7_2.svg, elements.2.elements.0.attributes.fill
  gm_2_7_2.svg, elements.2.elements.1.attributes.fill
  gm_2_7_2.svg, elements.2.elements.2.attributes.fill
  gm_2_7_3.svg, elements.2.elements.0.elements.0.attributes.fill
  gm_2_7_4.svg, elements.2.elements.0.attributes.fill
  gm_2_7_4.svg, elements.2.elements.5.attributes.fill
  gm_2_7_4.svg, elements.2.elements.6.attributes.fill
  gm_2_7_gesamt.svg, elements.2.elements.0.attributes.fill
  gm_2_7_gesamt.svg, elements.2.elements.1.attributes.fill
  gm_2_7_gesamt.svg, elements.2.elements.2.attributes.fill
  gm_2_7_gesamt.svg, elements.2.elements.3.attributes.fill
  gm_2_7_vor.svg, elements.2.elements.0.attributes.fill
  gm_2_7_vor.svg, elements.2.elements.1.attributes.fill
  gm_2_7_vor.svg, elements.2.elements.2.attributes.fill
  gm_2_8_1.svg, elements.2.elements.0.attributes.fill
  gm_2_8_1.svg, elements.2.elements.1.attributes.fill
  gm_2_8_1.svg, elements.2.elements.6.attributes.fill
  gm_2_8_2.svg, elements.2.elements.0.elements.0.attributes.fill
  gm_2_8_2.svg, elements.2.elements.0.elements.1.attributes.fill
  gm_2_8_2.svg, elements.2.elements.0.elements.3.attributes.fill
  gm_2_8_3.svg, elements.2.elements.0.attributes.fill
  gm_2_8_3.svg, elements.2.elements.1.attributes.fill
  gm_2_8_3.svg, elements.2.elements.2.attributes.fill
  gm_2_8_3.svg, elements.2.elements.5.attributes.fill
  gm_2_8_4.svg, elements.2.elements.0.elements.0.attributes.fill
  gm_2_8_4.svg, elements.2.elements.0.elements.1.attributes.fill
  gm_2_8_4.svg, elements.2.elements.0.elements.4.attributes.fill
  gm_2_8_gesamt.svg, elements.2.elements.0.elements.0.attributes.fill
  gm_2_8_gesamt.svg, elements.2.elements.0.elements.1.attributes.fill
  gm_2_8_gesamt.svg, elements.2.elements.0.elements.2.attributes.fill
  gm_2_8_gesamt.svg, elements.2.elements.0.elements.6.attributes.fill
  gm_2_8_gesamt.svg, elements.2.elements.0.elements.7.attributes.fill
  gm_2_8_vor.svg, elements.2.elements.0.elements.0.attributes.fill
  gm_2_8_vor.svg, elements.2.elements.0.elements.1.attributes.fill
  gm_2_8_vor.svg, elements.2.elements.0.elements.2.attributes.fill
  gm_2_8_vor.svg, elements.2.elements.0.elements.4.attributes.fill
  gm_2_9_1.svg, elements.2.elements.0.attributes.fill
  gm_2_9_1.svg, elements.2.elements.1.attributes.fill
  gm_2_9_1.svg, elements.2.elements.2.elements.0.attributes.fill
  gm_2_9_1.svg, elements.2.elements.4.attributes.fill
  gm_2_9_2.svg, elements.2.elements.0.attributes.fill
  gm_2_9_2.svg, elements.2.elements.1.attributes.fill
  gm_2_9_2.svg, elements.2.elements.2.attributes.fill
  gm_2_9_3.svg, elements.2.elements.0.attributes.fill
  gm_2_9_3.svg, elements.2.elements.1.attributes.fill
  gm_2_9_3.svg, elements.2.elements.2.attributes.fill
  gm_2_9_4.svg, elements.2.elements.0.attributes.fill
  gm_2_9_4.svg, elements.2.elements.1.attributes.fill
  gm_2_9_4.svg, elements.2.elements.2.attributes.fill
  gm_2_9_gesamt.svg, elements.2.elements.0.attributes.fill
  gm_2_9_gesamt.svg, elements.2.elements.1.attributes.fill
  gm_2_9_gesamt.svg, elements.2.elements.3.elements.0.attributes.fill
  gm_2_9_gesamt.svg, elements.2.elements.4.elements.0.attributes.fill
  gm_2_9_gesamt.svg, elements.2.elements.6.attributes.fill
  gm_2_9_gesamt.svg, elements.2.elements.7.attributes.fill
  gm_2_9_vor.svg, elements.2.elements.0.attributes.fill
  gm_2_9_vor.svg, elements.2.elements.1.attributes.fill
  gm_2_9_vor.svg, elements.2.elements.3.elements.0.attributes.fill
  gm_2_9_vor.svg, elements.2.elements.4.attributes.fill
  ptv_4_1.svg, elements.2.elements.9.attributes.fill
  ptv_4_2.svg, elements.2.elements.2.attributes.fill
  ptv_4_3.svg, elements.2.elements.9.attributes.fill
  rd_1_1_vor.svg, elements.2.elements.0.attributes.fill
  rd_1_2_vor.svg, elements.2.elements.7.attributes.fill
  rd_1_beispiel.svg, elements.2.elements.0.elements.4.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.3.elements.0.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.4.elements.0.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.5.elements.1.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.6.elements.0.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.7.elements.1.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.9.elements.0.elements.0.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.9.elements.1.elements.0.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.9.elements.2.elements.1.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.11.elements.1.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.13.elements.0.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.14.elements.1.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.4.elements.0.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.5.elements.0.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.6.elements.1.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.11.elements.1.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.12.elements.1.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.0.elements.0.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.1.elements.1.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.2.elements.1.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.4.elements.0.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.5.elements.1.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.6.elements.1.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.9.elements.0.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.11.elements.1.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.12.elements.1.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.0.elements.0.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.1.elements.1.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.3.elements.1.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.6.elements.1.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.9.elements.1.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.2.elements.0.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.3.elements.0.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.4.elements.1.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.5.elements.1.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.6.elements.1.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.9.elements.1.attributes.fill
  rd_2_intro.svg, elements.2.elements.2.elements.0.attributes.fill
  rd_2_intro.svg, elements.2.elements.3.elements.0.attributes.fill
  rd_2_intro.svg, elements.2.elements.4.elements.1.attributes.fill
  rd_2_intro.svg, elements.2.elements.6.elements.1.attributes.fill
  rd_2_intro.svg, elements.2.elements.7.elements.0.attributes.fill
  rd_2_intro.svg, elements.2.elements.8.elements.0.attributes.fill
  rd_2_intro.svg, elements.2.elements.9.elements.1.attributes.fill
  rd_2_intro.svg, elements.2.elements.16.elements.0.attributes.fill
  rd_2_intro.svg, elements.2.elements.17.elements.0.attributes.fill
  rd_2_intro.svg, elements.2.elements.18.elements.1.attributes.fill
  */
  fill: #999999;
}

.svg-YuPqkZVyCI{
  /*
  Used In:
  SF_3_vor_2.svg, elements.2.elements.1.elements.0.attributes.fill
  SF_3_vor_3.svg, elements.2.elements.0.attributes.fill
  SF_3_vor_3.svg, elements.2.elements.1.attributes.fill
  SF_3_vor_3.svg, elements.2.elements.2.attributes.fill
  SF_3_vor_3.svg, elements.2.elements.3.attributes.fill
  SF_3_vor_3.svg, elements.2.elements.4.attributes.fill
  SF_3_vor_3.svg, elements.2.elements.5.attributes.fill
  SF_3_vor_3.svg, elements.2.elements.6.attributes.fill
  SF_3_vor_3.svg, elements.2.elements.7.attributes.fill
  SF_3_vor_3.svg, elements.2.elements.8.attributes.fill
  SF_3_vor_3.svg, elements.2.elements.9.attributes.fill
  SF_3_vor_3.svg, elements.2.elements.10.attributes.fill
  SF_3_vor_3.svg, elements.2.elements.11.attributes.fill
  SF_3_vor_3.svg, elements.2.elements.12.attributes.fill
  SF_3_vor_3.svg, elements.2.elements.13.attributes.fill
  SF_3_vor_3.svg, elements.2.elements.14.attributes.fill
  SF_3_vor_3.svg, elements.2.elements.15.attributes.fill
  SF_3_vor_3.svg, elements.2.elements.16.attributes.fill
  SF_3_vor_3.svg, elements.2.elements.17.attributes.fill
  SF_3_vor_3.svg, elements.2.elements.18.attributes.fill
  SF_3_vor_3.svg, elements.2.elements.19.attributes.fill
  gm_2_10_3.svg, elements.2.elements.0.attributes.fill
  gm_2_11_1.svg, elements.2.elements.9.attributes.fill
  gm_2_12_2.svg, elements.2.elements.4.attributes.fill
  gm_2_1_1.svg, elements.2.elements.2.elements.0.attributes.fill
  gm_2_1_4.svg, elements.2.elements.1.elements.0.attributes.fill
  gm_2_1_4.svg, elements.2.elements.2.elements.0.attributes.fill
  gm_2_1_4.svg, elements.2.elements.3.elements.0.attributes.fill
  gm_2_1_4.svg, elements.2.elements.4.elements.0.attributes.fill
  gm_2_1_4.svg, elements.2.elements.5.elements.0.attributes.fill
  gm_2_1_4.svg, elements.2.elements.6.elements.0.attributes.fill
  gm_2_5_3.svg, elements.2.elements.0.elements.2.attributes.fill
  gm_2_5_4.svg, elements.2.elements.2.attributes.fill
  gm_2_5_gesamt.svg, elements.2.elements.1.attributes.fill
  gm_2_5_gesamt.svg, elements.2.elements.5.attributes.fill
  gm_2_5_vor.svg, elements.2.elements.1.attributes.fill
  gm_2_6_1.svg, elements.2.elements.2.elements.0.attributes.fill
  gm_2_6_2.svg, elements.2.elements.2.elements.0.attributes.fill
  gm_2_6_4.svg, elements.2.elements.3.elements.0.attributes.fill
  gm_2_6_gesamt.svg, elements.2.elements.3.elements.0.elements.0.attributes.fill
  gm_2_6_gesamt.svg, elements.2.elements.6.elements.0.attributes.fill
  gm_2_6_vor.svg, elements.2.elements.1.elements.0.elements.0.attributes.fill
  gm_2_7_1.svg, elements.2.elements.3.elements.0.attributes.fill
  gm_2_7_1.svg, elements.2.elements.4.elements.0.attributes.fill
  gm_2_7_2.svg, elements.2.elements.3.elements.0.attributes.fill
  gm_2_7_3.svg, elements.2.elements.0.elements.2.elements.0.attributes.fill
  gm_2_7_4.svg, elements.2.elements.1.elements.0.attributes.fill
  gm_2_7_4.svg, elements.2.elements.2.elements.0.attributes.fill
  gm_2_7_4.svg, elements.2.elements.3.elements.0.attributes.fill
  gm_2_7_4.svg, elements.2.elements.4.elements.0.attributes.fill
  gm_2_7_4.svg, elements.2.elements.7.elements.0.attributes.fill
  gm_2_7_gesamt.svg, elements.2.elements.4.elements.0.attributes.fill
  gm_2_7_gesamt.svg, elements.2.elements.5.elements.0.attributes.fill
  gm_2_7_vor.svg, elements.2.elements.3.elements.0.attributes.fill
  gm_2_8_1.svg, elements.2.elements.3.attributes.fill
  gm_2_8_1.svg, elements.2.elements.4.attributes.fill
  gm_2_8_3.svg, elements.2.elements.3.attributes.fill
  ptv_2_1.svg, elements.2.elements.0.attributes.fill
  ptv_2_2.svg, elements.2.elements.1.attributes.fill
  ptv_2_3.svg, elements.2.elements.0.attributes.fill
  ptv_2_4.svg, elements.2.elements.1.attributes.fill
  ptv_4_1.svg, elements.2.elements.2.attributes.fill
  ptv_4_2.svg, elements.2.elements.9.attributes.fill
  ptv_4_3.svg, elements.2.elements.2.attributes.fill
  ptv_4_4.svg, elements.2.elements.6.attributes.fill
  rd_1_1_1.svg, elements.2.elements.0.attributes.fill
  rd_1_1_1.svg, elements.2.elements.1.attributes.fill
  rd_1_1_2.svg, elements.2.elements.0.attributes.fill
  rd_1_1_2.svg, elements.2.elements.1.attributes.fill
  rd_1_1_3.svg, elements.2.elements.0.attributes.fill
  rd_1_1_3.svg, elements.2.elements.1.attributes.fill
  rd_1_1_vor.svg, elements.2.elements.1.attributes.fill
  rd_1_2_1.svg, elements.2.elements.0.attributes.fill
  rd_1_2_1.svg, elements.2.elements.1.attributes.fill
  rd_1_2_1.svg, elements.2.elements.2.attributes.fill
  rd_1_2_1.svg, elements.2.elements.3.attributes.fill
  rd_1_2_1.svg, elements.2.elements.4.attributes.fill
  rd_1_2_1.svg, elements.2.elements.5.attributes.fill
  rd_1_2_1.svg, elements.2.elements.6.attributes.fill
  rd_1_2_1.svg, elements.2.elements.7.attributes.fill
  rd_1_2_1.svg, elements.2.elements.8.attributes.fill
  rd_1_2_1.svg, elements.2.elements.9.attributes.fill
  rd_1_2_1.svg, elements.2.elements.10.attributes.fill
  rd_1_2_1.svg, elements.2.elements.11.attributes.fill
  rd_1_2_1.svg, elements.2.elements.12.attributes.fill
  rd_1_2_1.svg, elements.2.elements.13.attributes.fill
  rd_1_2_2.svg, elements.2.elements.0.attributes.fill
  rd_1_2_2.svg, elements.2.elements.1.attributes.fill
  rd_1_2_2.svg, elements.2.elements.2.attributes.fill
  rd_1_2_2.svg, elements.2.elements.3.attributes.fill
  rd_1_2_2.svg, elements.2.elements.4.attributes.fill
  rd_1_2_2.svg, elements.2.elements.5.attributes.fill
  rd_1_2_2.svg, elements.2.elements.6.attributes.fill
  rd_1_2_2.svg, elements.2.elements.7.attributes.fill
  rd_1_2_2.svg, elements.2.elements.8.attributes.fill
  rd_1_2_2.svg, elements.2.elements.9.attributes.fill
  rd_1_2_2.svg, elements.2.elements.10.attributes.fill
  rd_1_2_2.svg, elements.2.elements.11.attributes.fill
  rd_1_2_2.svg, elements.2.elements.12.attributes.fill
  rd_1_2_2.svg, elements.2.elements.13.attributes.fill
  rd_1_2_3.svg, elements.2.elements.0.attributes.fill
  rd_1_2_3.svg, elements.2.elements.1.attributes.fill
  rd_1_2_3.svg, elements.2.elements.2.attributes.fill
  rd_1_2_3.svg, elements.2.elements.3.attributes.fill
  rd_1_2_3.svg, elements.2.elements.4.attributes.fill
  rd_1_2_3.svg, elements.2.elements.5.attributes.fill
  rd_1_2_3.svg, elements.2.elements.6.attributes.fill
  rd_1_2_3.svg, elements.2.elements.7.attributes.fill
  rd_1_2_3.svg, elements.2.elements.8.attributes.fill
  rd_1_2_3.svg, elements.2.elements.9.attributes.fill
  rd_1_2_3.svg, elements.2.elements.10.attributes.fill
  rd_1_2_3.svg, elements.2.elements.11.attributes.fill
  rd_1_2_3.svg, elements.2.elements.12.attributes.fill
  rd_1_2_3.svg, elements.2.elements.13.attributes.fill
  rd_1_2_3.svg, elements.2.elements.14.attributes.fill
  rd_1_2_3.svg, elements.2.elements.15.attributes.fill
  rd_1_2_vor.svg, elements.2.elements.3.attributes.fill
  rd_1_3_1.svg, elements.2.elements.0.attributes.fill
  rd_1_3_1.svg, elements.2.elements.1.attributes.fill
  rd_1_3_1.svg, elements.2.elements.2.attributes.fill
  rd_1_3_1.svg, elements.2.elements.3.attributes.fill
  rd_1_3_2.svg, elements.2.elements.0.attributes.fill
  rd_1_3_2.svg, elements.2.elements.1.attributes.fill
  rd_1_3_2.svg, elements.2.elements.2.attributes.fill
  rd_1_3_2.svg, elements.2.elements.3.attributes.fill
  rd_1_3_2.svg, elements.2.elements.4.attributes.fill
  rd_1_3_2.svg, elements.2.elements.5.attributes.fill
  rd_1_3_3.svg, elements.2.elements.0.attributes.fill
  rd_1_3_3.svg, elements.2.elements.1.attributes.fill
  rd_1_3_3.svg, elements.2.elements.2.attributes.fill
  rd_1_3_3.svg, elements.2.elements.3.attributes.fill
  rd_1_3_3.svg, elements.2.elements.4.attributes.fill
  rd_1_3_3.svg, elements.2.elements.5.attributes.fill
  rd_1_4_1.svg, elements.2.elements.0.attributes.fill
  rd_1_4_1.svg, elements.2.elements.1.attributes.fill
  rd_1_4_1.svg, elements.2.elements.2.attributes.fill
  rd_1_4_1.svg, elements.2.elements.3.attributes.fill
  rd_1_4_1.svg, elements.2.elements.4.attributes.fill
  rd_1_4_1.svg, elements.2.elements.5.attributes.fill
  rd_1_4_1.svg, elements.2.elements.6.attributes.fill
  rd_1_4_2.svg, elements.2.elements.0.attributes.fill
  rd_1_4_2.svg, elements.2.elements.1.attributes.fill
  rd_1_4_2.svg, elements.2.elements.2.attributes.fill
  rd_1_4_2.svg, elements.2.elements.3.attributes.fill
  rd_1_4_2.svg, elements.2.elements.4.attributes.fill
  rd_1_4_3.svg, elements.2.elements.0.attributes.fill
  rd_1_4_3.svg, elements.2.elements.1.attributes.fill
  rd_1_4_3.svg, elements.2.elements.2.attributes.fill
  rd_1_4_3.svg, elements.2.elements.3.attributes.fill
  rd_1_4_3.svg, elements.2.elements.4.attributes.fill
  rd_1_4_3.svg, elements.2.elements.5.attributes.fill
  rd_1_4_3.svg, elements.2.elements.6.attributes.fill
  rd_1_4_3.svg, elements.2.elements.7.attributes.fill
  rd_1_beispiel.svg, elements.2.elements.0.elements.1.attributes.fill
  rd_1_beispiel.svg, elements.2.elements.0.elements.2.attributes.fill
  rd_1_beispiel_1.svg, elements.2.elements.0.attributes.fill
  rd_1_beispiel_1.svg, elements.2.elements.1.attributes.fill
  rd_1_beispiel_1.svg, elements.2.elements.2.attributes.fill
  rd_1_beispiel_1.svg, elements.2.elements.3.attributes.fill
  rd_1_beispiel_1.svg, elements.2.elements.4.attributes.fill
  rd_1_beispiel_2.svg, elements.2.elements.0.attributes.fill
  rd_1_beispiel_2.svg, elements.2.elements.1.attributes.fill
  rd_1_beispiel_2.svg, elements.2.elements.2.attributes.fill
  rd_1_beispiel_2.svg, elements.2.elements.3.attributes.fill
  rd_1_beispiel_2.svg, elements.2.elements.4.attributes.fill
  rd_1_beispiel_2.svg, elements.2.elements.5.attributes.fill
  rd_1_beispiel_2.svg, elements.2.elements.6.attributes.fill
  rd_1_beispiel_3.svg, elements.2.elements.0.attributes.fill
  rd_1_beispiel_3.svg, elements.2.elements.1.attributes.fill
  rd_1_beispiel_3.svg, elements.2.elements.2.attributes.fill
  rd_1_beispiel_3.svg, elements.2.elements.3.attributes.fill
  rd_1_beispiel_3.svg, elements.2.elements.4.attributes.fill
  rd_1_beispiel_3.svg, elements.2.elements.5.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.0.elements.0.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.2.elements.0.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.5.elements.0.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.7.elements.0.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.8.elements.0.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.9.elements.2.elements.0.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.11.elements.0.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.12.elements.0.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.14.elements.0.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.1.elements.0.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.3.elements.0.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.6.elements.0.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.7.elements.0.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.8.elements.0.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.9.elements.0.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.10.elements.0.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.11.elements.0.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.12.elements.0.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.1.elements.0.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.2.elements.0.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.3.elements.0.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.5.elements.0.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.6.elements.0.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.8.elements.0.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.11.elements.0.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.12.elements.0.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.1.elements.0.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.2.elements.0.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.3.elements.0.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.4.elements.0.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.5.elements.0.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.6.elements.0.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.7.elements.0.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.8.elements.0.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.9.elements.0.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.0.elements.0.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.1.elements.0.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.4.elements.0.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.5.elements.0.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.6.elements.0.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.7.elements.0.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.8.elements.0.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.9.elements.0.attributes.fill
  rd_2_intro.svg, elements.2.elements.0.elements.0.attributes.fill
  rd_2_intro.svg, elements.2.elements.1.elements.0.attributes.fill
  rd_2_intro.svg, elements.2.elements.4.elements.0.attributes.fill
  rd_2_intro.svg, elements.2.elements.5.elements.0.attributes.fill
  rd_2_intro.svg, elements.2.elements.6.elements.0.attributes.fill
  rd_2_intro.svg, elements.2.elements.9.elements.0.attributes.fill
  rd_2_intro.svg, elements.2.elements.12.elements.0.attributes.fill
  rd_2_intro.svg, elements.2.elements.13.elements.0.attributes.fill
  rd_2_intro.svg, elements.2.elements.15.elements.0.attributes.fill
  rd_2_intro.svg, elements.2.elements.18.elements.0.attributes.fill
  */
  fill: #CCCCCC;
}

.svg-ra2uO26z1U{
  /*
  Used In:
  SF_3_vor_2.svg, elements.2.elements.1.elements.1.attributes.fill
  */
  fill: #E6E6E6;
}

.svg-aMbtnmwJwh{
  /*
  Used In:
  SF_3_vor_3.svg, elements.2.elements.20.attributes.fill
  SF_3_vor_4.svg, elements.2.elements.2.attributes.fill
  SF_3_vor_beispiel.svg, elements.2.elements.2.attributes.fill
  SF_3_vor_beispiel.svg, elements.2.elements.3.attributes.fill
  SF_3_vor_beispiel.svg, elements.2.elements.4.attributes.fill
  SF_3_vor_beispiel.svg, elements.2.elements.5.attributes.fill
  gm_2_11_1.svg, elements.2.elements.0.attributes.fill
  gm_2_11_1.svg, elements.2.elements.2.attributes.fill
  gm_2_11_1.svg, elements.2.elements.3.attributes.fill
  gm_2_11_1.svg, elements.2.elements.4.attributes.fill
  gm_2_11_1.svg, elements.2.elements.5.attributes.fill
  gm_2_11_2.svg, elements.2.elements.0.elements.2.attributes.fill
  gm_2_11_2.svg, elements.2.elements.1.elements.0.attributes.fill
  gm_2_11_2.svg, elements.2.elements.1.elements.1.attributes.fill
  gm_2_11_3.svg, elements.2.elements.0.elements.1.attributes.fill
  gm_2_11_3.svg, elements.2.elements.0.elements.2.attributes.fill
  gm_2_11_3.svg, elements.2.elements.0.elements.3.attributes.fill
  gm_2_11_3.svg, elements.2.elements.0.elements.8.attributes.fill
  gm_2_11_3.svg, elements.2.elements.1.elements.0.attributes.fill
  gm_2_11_3.svg, elements.2.elements.1.elements.1.attributes.fill
  gm_2_11_4.svg, elements.2.elements.0.attributes.fill
  gm_2_11_4.svg, elements.2.elements.2.attributes.fill
  gm_2_11_4.svg, elements.2.elements.3.attributes.fill
  gm_2_11_4.svg, elements.2.elements.4.attributes.fill
  gm_2_11_4.svg, elements.2.elements.5.attributes.fill
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.0.attributes.fill
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.3.attributes.fill
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.4.attributes.fill
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.5.attributes.fill
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.10.attributes.fill
  gm_2_11_gesamt.svg, elements.2.elements.1.elements.0.attributes.fill
  gm_2_11_gesamt.svg, elements.2.elements.1.elements.1.attributes.fill
  gm_2_11_vor.svg, elements.2.elements.0.elements.0.attributes.fill
  gm_2_11_vor.svg, elements.2.elements.0.elements.3.attributes.fill
  gm_2_11_vor.svg, elements.2.elements.0.elements.4.attributes.fill
  gm_2_11_vor.svg, elements.2.elements.0.elements.5.attributes.fill
  gm_2_11_vor.svg, elements.2.elements.0.elements.6.attributes.fill
  gm_2_11_vor.svg, elements.2.elements.1.elements.0.attributes.fill
  gm_2_11_vor.svg, elements.2.elements.1.elements.1.attributes.fill
  gm_2_1_1.svg, elements.2.elements.0.attributes.fill
  gm_2_4_1.svg, elements.2.elements.0.attributes.fill
  gm_2_4_3.svg, elements.2.elements.1.attributes.fill
  gm_2_4_4.svg, elements.2.elements.0.attributes.fill
  gm_2_4_gesamt.svg, elements.2.elements.0.attributes.fill
  gm_2_8_2.svg, elements.2.elements.0.elements.5.attributes.fill
  k_1_vor_beispiel.svg, elements.2.elements.1.attributes.fill
  k_2_vor.svg, elements.2.elements.0.attributes.fill
  k_2_vor.svg, elements.2.elements.1.attributes.fill
  ptv_1_1.svg, elements.2.elements.0.attributes.fill
  ptv_1_1.svg, elements.2.elements.2.attributes.fill
  ptv_1_1.svg, elements.2.elements.7.attributes.fill
  ptv_1_2.svg, elements.2.elements.0.attributes.fill
  ptv_1_2.svg, elements.2.elements.2.attributes.fill
  ptv_1_2.svg, elements.2.elements.6.attributes.fill
  ptv_1_3.svg, elements.2.elements.1.attributes.fill
  ptv_1_3.svg, elements.2.elements.2.attributes.fill
  ptv_1_3.svg, elements.2.elements.7.attributes.fill
  ptv_2_1.svg, elements.2.elements.1.attributes.fill
  ptv_2_2.svg, elements.2.elements.0.elements.0.attributes.fill
  ptv_2_2.svg, elements.2.elements.0.elements.1.attributes.fill
  ptv_2_2.svg, elements.2.elements.0.elements.2.attributes.fill
  ptv_2_2.svg, elements.2.elements.0.elements.3.attributes.fill
  ptv_2_2.svg, elements.2.elements.2.attributes.fill
  ptv_2_3.svg, elements.2.elements.1.attributes.fill
  ptv_2_4.svg, elements.2.elements.0.attributes.fill
  ptv_2_4.svg, elements.2.elements.2.attributes.fill
  ptv_3.svg, elements.2.elements.0.attributes.fill
  ptv_3.svg, elements.2.elements.1.attributes.fill
  ptv_3.svg, elements.2.elements.2.attributes.fill
  ptv_3.svg, elements.2.elements.6.elements.2.attributes.fill
  ptv_3.svg, elements.2.elements.7.elements.2.attributes.fill
  ptv_4_1.svg, elements.2.elements.4.attributes.fill
  ptv_4_1.svg, elements.2.elements.5.attributes.fill
  ptv_4_1.svg, elements.2.elements.6.attributes.fill
  ptv_4_1.svg, elements.2.elements.7.attributes.fill
  ptv_4_1.svg, elements.2.elements.11.attributes.fill
  ptv_4_1.svg, elements.2.elements.12.attributes.fill
  ptv_4_1.svg, elements.2.elements.13.attributes.fill
  ptv_4_1.svg, elements.2.elements.14.attributes.fill
  ptv_4_1.svg, elements.2.elements.16.elements.0.attributes.fill
  ptv_4_2.svg, elements.2.elements.4.attributes.fill
  ptv_4_2.svg, elements.2.elements.5.attributes.fill
  ptv_4_2.svg, elements.2.elements.6.attributes.fill
  ptv_4_2.svg, elements.2.elements.7.attributes.fill
  ptv_4_2.svg, elements.2.elements.11.attributes.fill
  ptv_4_2.svg, elements.2.elements.12.attributes.fill
  ptv_4_2.svg, elements.2.elements.13.attributes.fill
  ptv_4_2.svg, elements.2.elements.14.attributes.fill
  ptv_4_2.svg, elements.2.elements.16.elements.0.attributes.fill
  ptv_4_3.svg, elements.2.elements.4.attributes.fill
  ptv_4_3.svg, elements.2.elements.5.attributes.fill
  ptv_4_3.svg, elements.2.elements.6.attributes.fill
  ptv_4_3.svg, elements.2.elements.7.attributes.fill
  ptv_4_3.svg, elements.2.elements.11.attributes.fill
  ptv_4_3.svg, elements.2.elements.12.attributes.fill
  ptv_4_3.svg, elements.2.elements.13.attributes.fill
  ptv_4_3.svg, elements.2.elements.14.attributes.fill
  ptv_4_3.svg, elements.2.elements.16.elements.0.attributes.fill
  ptv_4_4.svg, elements.2.elements.2.attributes.fill
  ptv_4_4.svg, elements.2.elements.3.attributes.fill
  ptv_4_4.svg, elements.2.elements.4.attributes.fill
  ptv_4_4.svg, elements.2.elements.8.attributes.fill
  ptv_4_4.svg, elements.2.elements.9.attributes.fill
  ptv_4_4.svg, elements.2.elements.10.attributes.fill
  ptv_4_4.svg, elements.2.elements.12.elements.0.attributes.fill
  ptv_5.svg, elements.2.elements.0.elements.1.attributes.fill
  ptv_5.svg, elements.2.elements.0.elements.2.attributes.fill
  ptv_5.svg, elements.2.elements.0.elements.3.attributes.fill
  ptv_5.svg, elements.2.elements.0.elements.4.attributes.fill
  ptv_5.svg, elements.2.elements.0.elements.5.attributes.fill
  ptv_5.svg, elements.2.elements.0.elements.6.attributes.fill
  ptv_5.svg, elements.2.elements.0.elements.7.attributes.fill
  ptv_5.svg, elements.2.elements.0.elements.8.attributes.fill
  ptv_5.svg, elements.2.elements.0.elements.9.attributes.fill
  ptv_5.svg, elements.2.elements.0.elements.10.attributes.fill
  ptv_5.svg, elements.2.elements.1.elements.1.attributes.fill
  ptv_5.svg, elements.2.elements.1.elements.2.attributes.fill
  ptv_5.svg, elements.2.elements.1.elements.3.attributes.fill
  ptv_5.svg, elements.2.elements.1.elements.4.attributes.fill
  ptv_5.svg, elements.2.elements.1.elements.5.attributes.fill
  ptv_5.svg, elements.2.elements.1.elements.6.attributes.fill
  ptv_5.svg, elements.2.elements.1.elements.7.attributes.fill
  ptv_5.svg, elements.2.elements.1.elements.8.attributes.fill
  ptv_5.svg, elements.2.elements.1.elements.9.attributes.fill
  ptv_5.svg, elements.2.elements.1.elements.10.attributes.fill
  ptv_5.svg, elements.2.elements.2.elements.1.attributes.fill
  ptv_5.svg, elements.2.elements.2.elements.2.attributes.fill
  ptv_5.svg, elements.2.elements.2.elements.3.attributes.fill
  ptv_5.svg, elements.2.elements.2.elements.4.attributes.fill
  ptv_5.svg, elements.2.elements.2.elements.5.attributes.fill
  ptv_5.svg, elements.2.elements.2.elements.6.attributes.fill
  ptv_5.svg, elements.2.elements.2.elements.7.attributes.fill
  ptv_5.svg, elements.2.elements.2.elements.8.attributes.fill
  ptv_5.svg, elements.2.elements.2.elements.9.attributes.fill
  ptv_5.svg, elements.2.elements.2.elements.10.attributes.fill
  ptv_5.svg, elements.2.elements.3.attributes.fill
  ptv_5.svg, elements.2.elements.5.attributes.fill
  ptv_5.svg, elements.2.elements.7.attributes.fill
  rd_1_1_vor.svg, elements.2.elements.2.attributes.fill
  rd_1_2_vor.svg, elements.2.elements.6.attributes.fill
  rd_1_2_vor.svg, elements.2.elements.8.attributes.fill
  rd_1_2_vor.svg, elements.2.elements.9.attributes.fill
  rd_1_3_1.svg, elements.2.elements.4.attributes.fill
  rd_1_3_1.svg, elements.2.elements.5.attributes.fill
  rd_1_3_2.svg, elements.2.elements.6.attributes.fill
  rd_1_3_2.svg, elements.2.elements.7.attributes.fill
  rd_1_3_3.svg, elements.2.elements.6.attributes.fill
  rd_1_3_3.svg, elements.2.elements.7.attributes.fill
  rd_1_3_vor.svg, elements.2.elements.3.attributes.fill
  rd_1_3_vor.svg, elements.2.elements.4.attributes.fill
  rd_1_3_vor.svg, elements.2.elements.5.attributes.fill
  rd_1_3_vor.svg, elements.2.elements.6.attributes.fill
  rd_1_4_1.svg, elements.2.elements.7.attributes.fill
  rd_1_4_2.svg, elements.2.elements.5.attributes.fill
  rd_1_4_3.svg, elements.2.elements.8.attributes.fill
  rd_1_4_vor.svg, elements.2.elements.1.elements.0.attributes.fill
  rd_1_4_vor.svg, elements.2.elements.1.elements.1.attributes.fill
  rd_1_4_vor.svg, elements.2.elements.1.elements.2.attributes.fill
  rd_1_4_vor.svg, elements.2.elements.1.elements.3.attributes.fill
  rd_1_4_vor.svg, elements.2.elements.1.elements.4.attributes.fill
  rd_1_beispiel.svg, elements.2.elements.0.elements.0.attributes.fill
  rd_1_beispiel.svg, elements.2.elements.0.elements.3.attributes.fill
  rd_1_beispiel.svg, elements.2.elements.0.elements.6.attributes.fill
  rd_1_beispiel.svg, elements.2.elements.0.elements.7.attributes.fill
  rd_1_beispiel.svg, elements.2.elements.0.elements.8.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.0.elements.1.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.0.elements.2.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.0.elements.3.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.2.elements.2.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.2.elements.3.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.3.elements.1.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.3.elements.2.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.4.elements.1.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.4.elements.2.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.5.elements.2.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.5.elements.3.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.5.elements.4.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.6.elements.1.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.6.elements.2.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.6.elements.3.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.7.elements.3.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.7.elements.4.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.7.elements.5.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.8.elements.1.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.8.elements.2.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.9.elements.0.elements.1.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.9.elements.0.elements.2.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.9.elements.1.elements.1.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.9.elements.1.elements.2.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.9.elements.1.elements.3.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.9.elements.2.elements.3.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.9.elements.2.elements.4.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.9.elements.2.elements.5.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.10.elements.1.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.10.elements.2.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.10.elements.3.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.11.elements.3.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.11.elements.4.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.11.elements.5.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.12.elements.2.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.12.elements.3.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.12.elements.4.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.13.elements.2.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.13.elements.3.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.13.elements.4.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.14.elements.3.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.14.elements.4.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.14.elements.5.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.0.elements.1.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.0.elements.2.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.1.elements.1.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.1.elements.2.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.2.elements.1.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.2.elements.2.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.3.elements.1.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.3.elements.2.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.4.elements.1.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.4.elements.2.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.5.elements.2.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.5.elements.3.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.6.elements.2.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.6.elements.3.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.6.elements.4.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.7.elements.2.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.7.elements.3.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.7.elements.4.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.8.elements.2.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.8.elements.3.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.8.elements.4.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.9.elements.2.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.9.elements.3.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.9.elements.4.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.10.elements.2.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.10.elements.3.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.10.elements.4.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.11.elements.3.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.11.elements.4.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.11.elements.5.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.12.elements.3.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.12.elements.4.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.12.elements.5.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.0.elements.1.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.1.elements.2.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.1.elements.3.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.1.elements.4.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.2.elements.2.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.2.elements.3.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.2.elements.4.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.3.elements.2.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.3.elements.3.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.4.elements.1.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.4.elements.2.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.5.elements.2.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.5.elements.3.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.6.elements.2.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.6.elements.3.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.7.elements.1.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.7.elements.2.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.8.elements.2.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.8.elements.3.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.8.elements.4.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.9.elements.2.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.9.elements.3.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.10.elements.1.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.10.elements.2.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.10.elements.3.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.11.elements.3.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.11.elements.4.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.11.elements.5.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.12.elements.3.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.12.elements.4.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.12.elements.5.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.0.elements.2.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.0.elements.3.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.0.elements.4.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.1.elements.3.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.1.elements.4.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.1.elements.5.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.2.elements.1.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.2.elements.2.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.3.elements.2.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.3.elements.3.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.3.elements.4.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.4.elements.1.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.4.elements.2.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.5.elements.1.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.5.elements.2.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.6.elements.2.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.6.elements.3.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.6.elements.4.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.7.elements.2.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.7.elements.3.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.7.elements.4.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.8.elements.2.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.8.elements.3.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.8.elements.4.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.9.elements.3.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.9.elements.4.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.9.elements.5.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.0.elements.2.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.0.elements.3.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.0.elements.4.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.1.elements.2.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.1.elements.3.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.1.elements.4.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.2.elements.1.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.2.elements.2.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.3.elements.2.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.3.elements.3.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.4.elements.3.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.4.elements.4.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.4.elements.5.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.5.elements.2.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.5.elements.3.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.6.elements.2.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.6.elements.3.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.6.elements.4.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.7.elements.2.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.7.elements.3.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.7.elements.4.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.8.elements.2.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.8.elements.3.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.8.elements.4.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.9.elements.3.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.9.elements.4.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.9.elements.5.attributes.fill
  rd_2_intro.svg, elements.2.elements.0.elements.1.attributes.fill
  rd_2_intro.svg, elements.2.elements.0.elements.2.attributes.fill
  rd_2_intro.svg, elements.2.elements.1.elements.1.attributes.fill
  rd_2_intro.svg, elements.2.elements.1.elements.2.attributes.fill
  rd_2_intro.svg, elements.2.elements.2.elements.1.attributes.fill
  rd_2_intro.svg, elements.2.elements.2.elements.2.attributes.fill
  rd_2_intro.svg, elements.2.elements.2.elements.3.attributes.fill
  rd_2_intro.svg, elements.2.elements.3.elements.1.attributes.fill
  rd_2_intro.svg, elements.2.elements.3.elements.2.attributes.fill
  rd_2_intro.svg, elements.2.elements.3.elements.3.attributes.fill
  rd_2_intro.svg, elements.2.elements.4.elements.2.attributes.fill
  rd_2_intro.svg, elements.2.elements.4.elements.3.attributes.fill
  rd_2_intro.svg, elements.2.elements.5.elements.1.attributes.fill
  rd_2_intro.svg, elements.2.elements.5.elements.2.attributes.fill
  rd_2_intro.svg, elements.2.elements.6.elements.2.attributes.fill
  rd_2_intro.svg, elements.2.elements.6.elements.3.attributes.fill
  rd_2_intro.svg, elements.2.elements.7.elements.1.attributes.fill
  rd_2_intro.svg, elements.2.elements.8.elements.1.attributes.fill
  rd_2_intro.svg, elements.2.elements.9.elements.2.attributes.fill
  rd_2_intro.svg, elements.2.elements.9.elements.3.attributes.fill
  rd_2_intro.svg, elements.2.elements.9.elements.4.attributes.fill
  rd_2_intro.svg, elements.2.elements.10.elements.1.attributes.fill
  rd_2_intro.svg, elements.2.elements.10.elements.2.attributes.fill
  rd_2_intro.svg, elements.2.elements.11.elements.1.attributes.fill
  rd_2_intro.svg, elements.2.elements.11.elements.2.attributes.fill
  rd_2_intro.svg, elements.2.elements.12.elements.2.attributes.fill
  rd_2_intro.svg, elements.2.elements.12.elements.3.attributes.fill
  rd_2_intro.svg, elements.2.elements.12.elements.4.attributes.fill
  rd_2_intro.svg, elements.2.elements.13.elements.2.attributes.fill
  rd_2_intro.svg, elements.2.elements.13.elements.3.attributes.fill
  rd_2_intro.svg, elements.2.elements.14.elements.1.attributes.fill
  rd_2_intro.svg, elements.2.elements.14.elements.2.attributes.fill
  rd_2_intro.svg, elements.2.elements.15.elements.2.attributes.fill
  rd_2_intro.svg, elements.2.elements.15.elements.3.attributes.fill
  rd_2_intro.svg, elements.2.elements.16.elements.2.attributes.fill
  rd_2_intro.svg, elements.2.elements.16.elements.3.attributes.fill
  rd_2_intro.svg, elements.2.elements.17.elements.2.attributes.fill
  rd_2_intro.svg, elements.2.elements.17.elements.3.attributes.fill
  rd_2_intro.svg, elements.2.elements.18.elements.3.attributes.fill
  rd_2_intro.svg, elements.2.elements.18.elements.4.attributes.fill
  rd_2_intro.svg, elements.2.elements.18.elements.5.attributes.fill
  */
  fill: none;
}

.svg-mUXQVCyC3p{
  /*
  Used In:
  SF_3_vor_3.svg, elements.2.elements.0.attributes.stroke
  SF_3_vor_3.svg, elements.2.elements.1.attributes.stroke
  SF_3_vor_3.svg, elements.2.elements.2.attributes.stroke
  SF_3_vor_3.svg, elements.2.elements.3.attributes.stroke
  SF_3_vor_3.svg, elements.2.elements.4.attributes.stroke
  SF_3_vor_3.svg, elements.2.elements.5.attributes.stroke
  SF_3_vor_3.svg, elements.2.elements.6.attributes.stroke
  SF_3_vor_3.svg, elements.2.elements.7.attributes.stroke
  SF_3_vor_3.svg, elements.2.elements.8.attributes.stroke
  SF_3_vor_3.svg, elements.2.elements.9.attributes.stroke
  SF_3_vor_3.svg, elements.2.elements.10.attributes.stroke
  SF_3_vor_3.svg, elements.2.elements.11.attributes.stroke
  SF_3_vor_3.svg, elements.2.elements.12.attributes.stroke
  SF_3_vor_3.svg, elements.2.elements.13.attributes.stroke
  SF_3_vor_3.svg, elements.2.elements.14.attributes.stroke
  SF_3_vor_3.svg, elements.2.elements.15.attributes.stroke
  SF_3_vor_3.svg, elements.2.elements.16.attributes.stroke
  SF_3_vor_3.svg, elements.2.elements.17.attributes.stroke
  SF_3_vor_3.svg, elements.2.elements.18.attributes.stroke
  SF_3_vor_3.svg, elements.2.elements.19.attributes.stroke
  SF_3_vor_3.svg, elements.2.elements.20.attributes.stroke
  SF_3_vor_beispiel.svg, elements.2.elements.2.attributes.stroke
  SF_3_vor_beispiel.svg, elements.2.elements.3.attributes.stroke
  SF_3_vor_beispiel.svg, elements.2.elements.4.attributes.stroke
  SF_3_vor_beispiel.svg, elements.2.elements.5.attributes.stroke
  k_1_vor_beispiel.svg, elements.2.elements.1.attributes.stroke
  k_2_vor.svg, elements.2.elements.0.attributes.stroke
  k_2_vor.svg, elements.2.elements.1.attributes.stroke
  rd_1_1_vor.svg, elements.2.elements.2.attributes.stroke
  rd_1_2_1.svg, elements.2.elements.0.attributes.stroke
  rd_1_2_1.svg, elements.2.elements.1.attributes.stroke
  rd_1_2_1.svg, elements.2.elements.2.attributes.stroke
  rd_1_2_1.svg, elements.2.elements.3.attributes.stroke
  rd_1_2_1.svg, elements.2.elements.4.attributes.stroke
  rd_1_2_1.svg, elements.2.elements.5.attributes.stroke
  rd_1_2_1.svg, elements.2.elements.6.attributes.stroke
  rd_1_2_1.svg, elements.2.elements.7.attributes.stroke
  rd_1_2_1.svg, elements.2.elements.8.attributes.stroke
  rd_1_2_1.svg, elements.2.elements.9.attributes.stroke
  rd_1_2_1.svg, elements.2.elements.10.attributes.stroke
  rd_1_2_1.svg, elements.2.elements.11.attributes.stroke
  rd_1_2_1.svg, elements.2.elements.12.attributes.stroke
  rd_1_2_1.svg, elements.2.elements.13.attributes.stroke
  rd_1_2_2.svg, elements.2.elements.0.attributes.stroke
  rd_1_2_2.svg, elements.2.elements.1.attributes.stroke
  rd_1_2_2.svg, elements.2.elements.2.attributes.stroke
  rd_1_2_2.svg, elements.2.elements.3.attributes.stroke
  rd_1_2_2.svg, elements.2.elements.4.attributes.stroke
  rd_1_2_2.svg, elements.2.elements.5.attributes.stroke
  rd_1_2_2.svg, elements.2.elements.6.attributes.stroke
  rd_1_2_2.svg, elements.2.elements.7.attributes.stroke
  rd_1_2_2.svg, elements.2.elements.8.attributes.stroke
  rd_1_2_2.svg, elements.2.elements.9.attributes.stroke
  rd_1_2_2.svg, elements.2.elements.10.attributes.stroke
  rd_1_2_2.svg, elements.2.elements.11.attributes.stroke
  rd_1_2_2.svg, elements.2.elements.12.attributes.stroke
  rd_1_2_2.svg, elements.2.elements.13.attributes.stroke
  rd_1_2_3.svg, elements.2.elements.0.attributes.stroke
  rd_1_2_3.svg, elements.2.elements.1.attributes.stroke
  rd_1_2_3.svg, elements.2.elements.2.attributes.stroke
  rd_1_2_3.svg, elements.2.elements.3.attributes.stroke
  rd_1_2_3.svg, elements.2.elements.4.attributes.stroke
  rd_1_2_3.svg, elements.2.elements.5.attributes.stroke
  rd_1_2_3.svg, elements.2.elements.6.attributes.stroke
  rd_1_2_3.svg, elements.2.elements.7.attributes.stroke
  rd_1_2_3.svg, elements.2.elements.8.attributes.stroke
  rd_1_2_3.svg, elements.2.elements.9.attributes.stroke
  rd_1_2_3.svg, elements.2.elements.10.attributes.stroke
  rd_1_2_3.svg, elements.2.elements.11.attributes.stroke
  rd_1_2_3.svg, elements.2.elements.12.attributes.stroke
  rd_1_2_3.svg, elements.2.elements.13.attributes.stroke
  rd_1_2_3.svg, elements.2.elements.14.attributes.stroke
  rd_1_2_3.svg, elements.2.elements.15.attributes.stroke
  rd_1_3_1.svg, elements.2.elements.0.attributes.stroke
  rd_1_3_1.svg, elements.2.elements.1.attributes.stroke
  rd_1_3_1.svg, elements.2.elements.2.attributes.stroke
  rd_1_3_1.svg, elements.2.elements.3.attributes.stroke
  rd_1_3_1.svg, elements.2.elements.4.attributes.stroke
  rd_1_3_1.svg, elements.2.elements.5.attributes.stroke
  rd_1_3_2.svg, elements.2.elements.0.attributes.stroke
  rd_1_3_2.svg, elements.2.elements.1.attributes.stroke
  rd_1_3_2.svg, elements.2.elements.2.attributes.stroke
  rd_1_3_2.svg, elements.2.elements.3.attributes.stroke
  rd_1_3_2.svg, elements.2.elements.4.attributes.stroke
  rd_1_3_2.svg, elements.2.elements.5.attributes.stroke
  rd_1_3_2.svg, elements.2.elements.6.attributes.stroke
  rd_1_3_2.svg, elements.2.elements.7.attributes.stroke
  rd_1_3_3.svg, elements.2.elements.0.attributes.stroke
  rd_1_3_3.svg, elements.2.elements.1.attributes.stroke
  rd_1_3_3.svg, elements.2.elements.2.attributes.stroke
  rd_1_3_3.svg, elements.2.elements.3.attributes.stroke
  rd_1_3_3.svg, elements.2.elements.4.attributes.stroke
  rd_1_3_3.svg, elements.2.elements.5.attributes.stroke
  rd_1_3_3.svg, elements.2.elements.6.attributes.stroke
  rd_1_3_3.svg, elements.2.elements.7.attributes.stroke
  rd_1_4_1.svg, elements.2.elements.0.attributes.stroke
  rd_1_4_1.svg, elements.2.elements.1.attributes.stroke
  rd_1_4_1.svg, elements.2.elements.2.attributes.stroke
  rd_1_4_1.svg, elements.2.elements.3.attributes.stroke
  rd_1_4_1.svg, elements.2.elements.4.attributes.stroke
  rd_1_4_1.svg, elements.2.elements.6.attributes.stroke
  rd_1_4_1.svg, elements.2.elements.7.attributes.stroke
  */
  stroke: #666666;
}

.svg-HDmGkRt7jP{
  /*
  Used In:
  SF_3_vor_3.svg, elements.2.elements.0.attributes.stroke-width
  SF_3_vor_3.svg, elements.2.elements.1.attributes.stroke-width
  SF_3_vor_3.svg, elements.2.elements.2.attributes.stroke-width
  SF_3_vor_3.svg, elements.2.elements.3.attributes.stroke-width
  SF_3_vor_3.svg, elements.2.elements.4.attributes.stroke-width
  SF_3_vor_3.svg, elements.2.elements.5.attributes.stroke-width
  SF_3_vor_3.svg, elements.2.elements.6.attributes.stroke-width
  SF_3_vor_3.svg, elements.2.elements.7.attributes.stroke-width
  SF_3_vor_3.svg, elements.2.elements.8.attributes.stroke-width
  SF_3_vor_3.svg, elements.2.elements.9.attributes.stroke-width
  SF_3_vor_3.svg, elements.2.elements.10.attributes.stroke-width
  SF_3_vor_3.svg, elements.2.elements.11.attributes.stroke-width
  SF_3_vor_3.svg, elements.2.elements.12.attributes.stroke-width
  SF_3_vor_3.svg, elements.2.elements.13.attributes.stroke-width
  SF_3_vor_3.svg, elements.2.elements.14.attributes.stroke-width
  SF_3_vor_3.svg, elements.2.elements.15.attributes.stroke-width
  SF_3_vor_3.svg, elements.2.elements.16.attributes.stroke-width
  SF_3_vor_3.svg, elements.2.elements.17.attributes.stroke-width
  SF_3_vor_3.svg, elements.2.elements.18.attributes.stroke-width
  SF_3_vor_3.svg, elements.2.elements.19.attributes.stroke-width
  SF_3_vor_3.svg, elements.2.elements.20.attributes.stroke-width
  SF_3_vor_beispiel.svg, elements.2.elements.2.attributes.stroke-width
  SF_3_vor_beispiel.svg, elements.2.elements.3.attributes.stroke-width
  SF_3_vor_beispiel.svg, elements.2.elements.4.attributes.stroke-width
  SF_3_vor_beispiel.svg, elements.2.elements.5.attributes.stroke-width
  k_1_vor_beispiel.svg, elements.2.elements.1.attributes.stroke-width
  k_2_vor.svg, elements.2.elements.0.attributes.stroke-width
  k_2_vor.svg, elements.2.elements.1.attributes.stroke-width
  ptv_1_1.svg, elements.2.elements.0.attributes.stroke-width
  ptv_1_1.svg, elements.2.elements.2.attributes.stroke-width
  ptv_1_2.svg, elements.2.elements.0.attributes.stroke-width
  ptv_1_2.svg, elements.2.elements.2.attributes.stroke-width
  ptv_1_3.svg, elements.2.elements.1.attributes.stroke-width
  ptv_1_3.svg, elements.2.elements.2.attributes.stroke-width
  ptv_2_4.svg, elements.2.elements.0.attributes.stroke-width
  ptv_3.svg, elements.2.elements.0.attributes.stroke-width
  ptv_3.svg, elements.2.elements.1.attributes.stroke-width
  ptv_3.svg, elements.2.elements.2.attributes.stroke-width
  ptv_4_1.svg, elements.2.elements.4.attributes.stroke-width
  ptv_4_1.svg, elements.2.elements.5.attributes.stroke-width
  ptv_4_1.svg, elements.2.elements.6.attributes.stroke-width
  ptv_4_1.svg, elements.2.elements.11.attributes.stroke-width
  ptv_4_1.svg, elements.2.elements.12.attributes.stroke-width
  ptv_4_1.svg, elements.2.elements.13.attributes.stroke-width
  ptv_4_2.svg, elements.2.elements.4.attributes.stroke-width
  ptv_4_2.svg, elements.2.elements.5.attributes.stroke-width
  ptv_4_2.svg, elements.2.elements.6.attributes.stroke-width
  ptv_4_2.svg, elements.2.elements.11.attributes.stroke-width
  ptv_4_2.svg, elements.2.elements.12.attributes.stroke-width
  ptv_4_2.svg, elements.2.elements.13.attributes.stroke-width
  ptv_4_3.svg, elements.2.elements.4.attributes.stroke-width
  ptv_4_3.svg, elements.2.elements.5.attributes.stroke-width
  ptv_4_3.svg, elements.2.elements.6.attributes.stroke-width
  ptv_4_3.svg, elements.2.elements.11.attributes.stroke-width
  ptv_4_3.svg, elements.2.elements.12.attributes.stroke-width
  ptv_4_3.svg, elements.2.elements.13.attributes.stroke-width
  ptv_4_4.svg, elements.2.elements.2.attributes.stroke-width
  ptv_4_4.svg, elements.2.elements.3.attributes.stroke-width
  ptv_4_4.svg, elements.2.elements.8.attributes.stroke-width
  ptv_4_4.svg, elements.2.elements.9.attributes.stroke-width
  ptv_5.svg, elements.2.elements.0.elements.1.attributes.stroke-width
  ptv_5.svg, elements.2.elements.1.elements.1.attributes.stroke-width
  ptv_5.svg, elements.2.elements.2.elements.1.attributes.stroke-width
  rd_1_1_1.svg, elements.2.elements.0.attributes.stroke-width
  rd_1_1_1.svg, elements.2.elements.1.attributes.stroke-width
  rd_1_1_2.svg, elements.2.elements.0.attributes.stroke-width
  rd_1_1_2.svg, elements.2.elements.1.attributes.stroke-width
  rd_1_1_3.svg, elements.2.elements.0.attributes.stroke-width
  rd_1_1_3.svg, elements.2.elements.1.attributes.stroke-width
  rd_1_1_vor.svg, elements.2.elements.2.attributes.stroke-width
  rd_1_2_1.svg, elements.2.elements.1.attributes.stroke-width
  rd_1_2_1.svg, elements.2.elements.3.attributes.stroke-width
  rd_1_2_1.svg, elements.2.elements.5.attributes.stroke-width
  rd_1_2_1.svg, elements.2.elements.7.attributes.stroke-width
  rd_1_2_1.svg, elements.2.elements.9.attributes.stroke-width
  rd_1_2_1.svg, elements.2.elements.11.attributes.stroke-width
  rd_1_2_1.svg, elements.2.elements.13.attributes.stroke-width
  rd_1_2_2.svg, elements.2.elements.1.attributes.stroke-width
  rd_1_2_2.svg, elements.2.elements.3.attributes.stroke-width
  rd_1_2_2.svg, elements.2.elements.5.attributes.stroke-width
  rd_1_2_2.svg, elements.2.elements.7.attributes.stroke-width
  rd_1_2_2.svg, elements.2.elements.9.attributes.stroke-width
  rd_1_2_2.svg, elements.2.elements.11.attributes.stroke-width
  rd_1_2_2.svg, elements.2.elements.13.attributes.stroke-width
  rd_1_2_3.svg, elements.2.elements.1.attributes.stroke-width
  rd_1_2_3.svg, elements.2.elements.3.attributes.stroke-width
  rd_1_2_3.svg, elements.2.elements.5.attributes.stroke-width
  rd_1_2_3.svg, elements.2.elements.7.attributes.stroke-width
  rd_1_2_3.svg, elements.2.elements.9.attributes.stroke-width
  rd_1_2_3.svg, elements.2.elements.11.attributes.stroke-width
  rd_1_2_3.svg, elements.2.elements.13.attributes.stroke-width
  rd_1_2_3.svg, elements.2.elements.15.attributes.stroke-width
  rd_1_2_vor.svg, elements.2.elements.6.attributes.stroke-width
  rd_1_2_vor.svg, elements.2.elements.8.attributes.stroke-width
  rd_1_2_vor.svg, elements.2.elements.9.attributes.stroke-width
  rd_1_3_1.svg, elements.2.elements.1.attributes.stroke-width
  rd_1_3_1.svg, elements.2.elements.3.attributes.stroke-width
  rd_1_3_1.svg, elements.2.elements.4.attributes.stroke-width
  rd_1_3_1.svg, elements.2.elements.5.attributes.stroke-width
  rd_1_3_2.svg, elements.2.elements.1.attributes.stroke-width
  rd_1_3_2.svg, elements.2.elements.3.attributes.stroke-width
  rd_1_3_2.svg, elements.2.elements.5.attributes.stroke-width
  rd_1_3_2.svg, elements.2.elements.6.attributes.stroke-width
  rd_1_3_2.svg, elements.2.elements.7.attributes.stroke-width
  rd_1_3_3.svg, elements.2.elements.1.attributes.stroke-width
  rd_1_3_3.svg, elements.2.elements.3.attributes.stroke-width
  rd_1_3_3.svg, elements.2.elements.5.attributes.stroke-width
  rd_1_3_3.svg, elements.2.elements.6.attributes.stroke-width
  rd_1_3_3.svg, elements.2.elements.7.attributes.stroke-width
  rd_1_3_vor.svg, elements.2.elements.3.attributes.stroke-width
  rd_1_3_vor.svg, elements.2.elements.4.attributes.stroke-width
  rd_1_3_vor.svg, elements.2.elements.5.attributes.stroke-width
  rd_1_3_vor.svg, elements.2.elements.6.attributes.stroke-width
  rd_1_4_1.svg, elements.2.elements.0.attributes.stroke-width
  rd_1_4_1.svg, elements.2.elements.1.attributes.stroke-width
  rd_1_4_1.svg, elements.2.elements.2.attributes.stroke-width
  rd_1_4_1.svg, elements.2.elements.3.attributes.stroke-width
  rd_1_4_1.svg, elements.2.elements.4.attributes.stroke-width
  rd_1_4_1.svg, elements.2.elements.6.attributes.stroke-width
  rd_1_4_1.svg, elements.2.elements.7.attributes.stroke-width
  rd_1_4_2.svg, elements.2.elements.0.attributes.stroke-width
  rd_1_4_2.svg, elements.2.elements.2.attributes.stroke-width
  rd_1_4_2.svg, elements.2.elements.3.attributes.stroke-width
  rd_1_4_2.svg, elements.2.elements.4.attributes.stroke-width
  rd_1_4_2.svg, elements.2.elements.5.attributes.stroke-width
  rd_1_4_3.svg, elements.2.elements.1.attributes.stroke-width
  rd_1_4_3.svg, elements.2.elements.2.attributes.stroke-width
  rd_1_4_3.svg, elements.2.elements.3.attributes.stroke-width
  rd_1_4_3.svg, elements.2.elements.4.attributes.stroke-width
  rd_1_4_3.svg, elements.2.elements.6.attributes.stroke-width
  rd_1_4_3.svg, elements.2.elements.7.attributes.stroke-width
  rd_1_4_3.svg, elements.2.elements.8.attributes.stroke-width
  rd_1_4_vor.svg, elements.2.elements.1.elements.0.attributes.stroke-width
  rd_1_4_vor.svg, elements.2.elements.1.elements.1.attributes.stroke-width
  rd_1_4_vor.svg, elements.2.elements.1.elements.2.attributes.stroke-width
  rd_1_4_vor.svg, elements.2.elements.1.elements.3.attributes.stroke-width
  rd_1_4_vor.svg, elements.2.elements.1.elements.4.attributes.stroke-width
  rd_1_beispiel.svg, elements.2.elements.0.elements.0.attributes.stroke-width
  rd_1_beispiel.svg, elements.2.elements.0.elements.3.attributes.stroke-width
  rd_1_beispiel.svg, elements.2.elements.0.elements.6.attributes.stroke-width
  rd_1_beispiel.svg, elements.2.elements.0.elements.7.attributes.stroke-width
  rd_1_beispiel.svg, elements.2.elements.0.elements.8.attributes.stroke-width
  rd_1_beispiel_1.svg, elements.2.elements.0.attributes.stroke-width
  rd_1_beispiel_1.svg, elements.2.elements.1.attributes.stroke-width
  rd_1_beispiel_1.svg, elements.2.elements.2.attributes.stroke-width
  rd_1_beispiel_1.svg, elements.2.elements.3.attributes.stroke-width
  rd_1_beispiel_1.svg, elements.2.elements.4.attributes.stroke-width
  rd_1_beispiel_2.svg, elements.2.elements.0.attributes.stroke-width
  rd_1_beispiel_2.svg, elements.2.elements.1.attributes.stroke-width
  rd_1_beispiel_2.svg, elements.2.elements.2.attributes.stroke-width
  rd_1_beispiel_2.svg, elements.2.elements.3.attributes.stroke-width
  rd_1_beispiel_2.svg, elements.2.elements.4.attributes.stroke-width
  rd_1_beispiel_2.svg, elements.2.elements.5.attributes.stroke-width
  rd_1_beispiel_2.svg, elements.2.elements.6.attributes.stroke-width
  rd_1_beispiel_3.svg, elements.2.elements.0.attributes.stroke-width
  rd_1_beispiel_3.svg, elements.2.elements.1.attributes.stroke-width
  rd_1_beispiel_3.svg, elements.2.elements.2.attributes.stroke-width
  rd_1_beispiel_3.svg, elements.2.elements.3.attributes.stroke-width
  rd_1_beispiel_3.svg, elements.2.elements.4.attributes.stroke-width
  rd_1_beispiel_3.svg, elements.2.elements.5.attributes.stroke-width
  rd_2_1_vor.svg, elements.2.elements.0.elements.1.attributes.stroke-width
  rd_2_1_vor.svg, elements.2.elements.0.elements.2.attributes.stroke-width
  rd_2_1_vor.svg, elements.2.elements.0.elements.3.attributes.stroke-width
  rd_2_1_vor.svg, elements.2.elements.2.elements.2.attributes.stroke-width
  rd_2_1_vor.svg, elements.2.elements.2.elements.3.attributes.stroke-width
  rd_2_1_vor.svg, elements.2.elements.3.elements.1.attributes.stroke-width
  rd_2_1_vor.svg, elements.2.elements.3.elements.2.attributes.stroke-width
  rd_2_1_vor.svg, elements.2.elements.4.elements.1.attributes.stroke-width
  rd_2_1_vor.svg, elements.2.elements.4.elements.2.attributes.stroke-width
  rd_2_1_vor.svg, elements.2.elements.5.elements.2.attributes.stroke-width
  rd_2_1_vor.svg, elements.2.elements.5.elements.3.attributes.stroke-width
  rd_2_1_vor.svg, elements.2.elements.5.elements.4.attributes.stroke-width
  rd_2_1_vor.svg, elements.2.elements.6.elements.1.attributes.stroke-width
  rd_2_1_vor.svg, elements.2.elements.6.elements.2.attributes.stroke-width
  rd_2_1_vor.svg, elements.2.elements.6.elements.3.attributes.stroke-width
  rd_2_1_vor.svg, elements.2.elements.7.elements.3.attributes.stroke-width
  rd_2_1_vor.svg, elements.2.elements.7.elements.4.attributes.stroke-width
  rd_2_1_vor.svg, elements.2.elements.7.elements.5.attributes.stroke-width
  rd_2_1_vor.svg, elements.2.elements.8.elements.1.attributes.stroke-width
  rd_2_1_vor.svg, elements.2.elements.8.elements.2.attributes.stroke-width
  rd_2_1_vor.svg, elements.2.elements.9.elements.0.elements.1.attributes.stroke-width
  rd_2_1_vor.svg, elements.2.elements.9.elements.0.elements.2.attributes.stroke-width
  rd_2_1_vor.svg, elements.2.elements.9.elements.1.elements.1.attributes.stroke-width
  rd_2_1_vor.svg, elements.2.elements.9.elements.1.elements.2.attributes.stroke-width
  rd_2_1_vor.svg, elements.2.elements.9.elements.1.elements.3.attributes.stroke-width
  rd_2_1_vor.svg, elements.2.elements.9.elements.2.elements.3.attributes.stroke-width
  rd_2_1_vor.svg, elements.2.elements.9.elements.2.elements.4.attributes.stroke-width
  rd_2_1_vor.svg, elements.2.elements.9.elements.2.elements.5.attributes.stroke-width
  rd_2_1_vor.svg, elements.2.elements.10.elements.1.attributes.stroke-width
  rd_2_1_vor.svg, elements.2.elements.10.elements.2.attributes.stroke-width
  rd_2_1_vor.svg, elements.2.elements.10.elements.3.attributes.stroke-width
  rd_2_1_vor.svg, elements.2.elements.11.elements.3.attributes.stroke-width
  rd_2_1_vor.svg, elements.2.elements.11.elements.4.attributes.stroke-width
  rd_2_1_vor.svg, elements.2.elements.11.elements.5.attributes.stroke-width
  rd_2_1_vor.svg, elements.2.elements.12.elements.2.attributes.stroke-width
  rd_2_1_vor.svg, elements.2.elements.12.elements.3.attributes.stroke-width
  rd_2_1_vor.svg, elements.2.elements.12.elements.4.attributes.stroke-width
  rd_2_1_vor.svg, elements.2.elements.13.elements.2.attributes.stroke-width
  rd_2_1_vor.svg, elements.2.elements.13.elements.3.attributes.stroke-width
  rd_2_1_vor.svg, elements.2.elements.13.elements.4.attributes.stroke-width
  rd_2_1_vor.svg, elements.2.elements.14.elements.3.attributes.stroke-width
  rd_2_1_vor.svg, elements.2.elements.14.elements.4.attributes.stroke-width
  rd_2_1_vor.svg, elements.2.elements.14.elements.5.attributes.stroke-width
  rd_2_2_vor.svg, elements.2.elements.0.elements.1.attributes.stroke-width
  rd_2_2_vor.svg, elements.2.elements.0.elements.2.attributes.stroke-width
  rd_2_2_vor.svg, elements.2.elements.1.elements.1.attributes.stroke-width
  rd_2_2_vor.svg, elements.2.elements.1.elements.2.attributes.stroke-width
  rd_2_2_vor.svg, elements.2.elements.2.elements.1.attributes.stroke-width
  rd_2_2_vor.svg, elements.2.elements.2.elements.2.attributes.stroke-width
  rd_2_2_vor.svg, elements.2.elements.3.elements.1.attributes.stroke-width
  rd_2_2_vor.svg, elements.2.elements.3.elements.2.attributes.stroke-width
  rd_2_2_vor.svg, elements.2.elements.4.elements.1.attributes.stroke-width
  rd_2_2_vor.svg, elements.2.elements.4.elements.2.attributes.stroke-width
  rd_2_2_vor.svg, elements.2.elements.5.elements.2.attributes.stroke-width
  rd_2_2_vor.svg, elements.2.elements.5.elements.3.attributes.stroke-width
  rd_2_2_vor.svg, elements.2.elements.6.elements.2.attributes.stroke-width
  rd_2_2_vor.svg, elements.2.elements.6.elements.3.attributes.stroke-width
  rd_2_2_vor.svg, elements.2.elements.6.elements.4.attributes.stroke-width
  rd_2_2_vor.svg, elements.2.elements.7.elements.2.attributes.stroke-width
  rd_2_2_vor.svg, elements.2.elements.7.elements.3.attributes.stroke-width
  rd_2_2_vor.svg, elements.2.elements.7.elements.4.attributes.stroke-width
  rd_2_2_vor.svg, elements.2.elements.8.elements.2.attributes.stroke-width
  rd_2_2_vor.svg, elements.2.elements.8.elements.3.attributes.stroke-width
  rd_2_2_vor.svg, elements.2.elements.8.elements.4.attributes.stroke-width
  rd_2_2_vor.svg, elements.2.elements.9.elements.2.attributes.stroke-width
  rd_2_2_vor.svg, elements.2.elements.9.elements.3.attributes.stroke-width
  rd_2_2_vor.svg, elements.2.elements.9.elements.4.attributes.stroke-width
  rd_2_2_vor.svg, elements.2.elements.10.elements.2.attributes.stroke-width
  rd_2_2_vor.svg, elements.2.elements.10.elements.3.attributes.stroke-width
  rd_2_2_vor.svg, elements.2.elements.10.elements.4.attributes.stroke-width
  rd_2_2_vor.svg, elements.2.elements.11.elements.3.attributes.stroke-width
  rd_2_2_vor.svg, elements.2.elements.11.elements.4.attributes.stroke-width
  rd_2_2_vor.svg, elements.2.elements.11.elements.5.attributes.stroke-width
  rd_2_2_vor.svg, elements.2.elements.12.elements.3.attributes.stroke-width
  rd_2_2_vor.svg, elements.2.elements.12.elements.4.attributes.stroke-width
  rd_2_2_vor.svg, elements.2.elements.12.elements.5.attributes.stroke-width
  rd_2_3_vor.svg, elements.2.elements.0.elements.1.attributes.stroke-width
  rd_2_3_vor.svg, elements.2.elements.1.elements.2.attributes.stroke-width
  rd_2_3_vor.svg, elements.2.elements.1.elements.3.attributes.stroke-width
  rd_2_3_vor.svg, elements.2.elements.1.elements.4.attributes.stroke-width
  rd_2_3_vor.svg, elements.2.elements.2.elements.2.attributes.stroke-width
  rd_2_3_vor.svg, elements.2.elements.2.elements.3.attributes.stroke-width
  rd_2_3_vor.svg, elements.2.elements.2.elements.4.attributes.stroke-width
  rd_2_3_vor.svg, elements.2.elements.3.elements.2.attributes.stroke-width
  rd_2_3_vor.svg, elements.2.elements.3.elements.3.attributes.stroke-width
  rd_2_3_vor.svg, elements.2.elements.4.elements.1.attributes.stroke-width
  rd_2_3_vor.svg, elements.2.elements.4.elements.2.attributes.stroke-width
  rd_2_3_vor.svg, elements.2.elements.5.elements.2.attributes.stroke-width
  rd_2_3_vor.svg, elements.2.elements.5.elements.3.attributes.stroke-width
  rd_2_3_vor.svg, elements.2.elements.6.elements.2.attributes.stroke-width
  rd_2_3_vor.svg, elements.2.elements.6.elements.3.attributes.stroke-width
  rd_2_3_vor.svg, elements.2.elements.7.elements.1.attributes.stroke-width
  rd_2_3_vor.svg, elements.2.elements.7.elements.2.attributes.stroke-width
  rd_2_3_vor.svg, elements.2.elements.8.elements.2.attributes.stroke-width
  rd_2_3_vor.svg, elements.2.elements.8.elements.3.attributes.stroke-width
  rd_2_3_vor.svg, elements.2.elements.8.elements.4.attributes.stroke-width
  rd_2_3_vor.svg, elements.2.elements.9.elements.2.attributes.stroke-width
  rd_2_3_vor.svg, elements.2.elements.9.elements.3.attributes.stroke-width
  rd_2_3_vor.svg, elements.2.elements.10.elements.1.attributes.stroke-width
  rd_2_3_vor.svg, elements.2.elements.10.elements.2.attributes.stroke-width
  rd_2_3_vor.svg, elements.2.elements.10.elements.3.attributes.stroke-width
  rd_2_3_vor.svg, elements.2.elements.11.elements.3.attributes.stroke-width
  rd_2_3_vor.svg, elements.2.elements.11.elements.4.attributes.stroke-width
  rd_2_3_vor.svg, elements.2.elements.11.elements.5.attributes.stroke-width
  rd_2_3_vor.svg, elements.2.elements.12.elements.3.attributes.stroke-width
  rd_2_3_vor.svg, elements.2.elements.12.elements.4.attributes.stroke-width
  rd_2_3_vor.svg, elements.2.elements.12.elements.5.attributes.stroke-width
  rd_2_4_vor.svg, elements.2.elements.0.elements.2.attributes.stroke-width
  rd_2_4_vor.svg, elements.2.elements.0.elements.3.attributes.stroke-width
  rd_2_4_vor.svg, elements.2.elements.0.elements.4.attributes.stroke-width
  rd_2_4_vor.svg, elements.2.elements.1.elements.3.attributes.stroke-width
  rd_2_4_vor.svg, elements.2.elements.1.elements.4.attributes.stroke-width
  rd_2_4_vor.svg, elements.2.elements.1.elements.5.attributes.stroke-width
  rd_2_4_vor.svg, elements.2.elements.2.elements.1.attributes.stroke-width
  rd_2_4_vor.svg, elements.2.elements.2.elements.2.attributes.stroke-width
  rd_2_4_vor.svg, elements.2.elements.3.elements.2.attributes.stroke-width
  rd_2_4_vor.svg, elements.2.elements.3.elements.3.attributes.stroke-width
  rd_2_4_vor.svg, elements.2.elements.3.elements.4.attributes.stroke-width
  rd_2_4_vor.svg, elements.2.elements.4.elements.1.attributes.stroke-width
  rd_2_4_vor.svg, elements.2.elements.4.elements.2.attributes.stroke-width
  rd_2_4_vor.svg, elements.2.elements.5.elements.1.attributes.stroke-width
  rd_2_4_vor.svg, elements.2.elements.5.elements.2.attributes.stroke-width
  rd_2_4_vor.svg, elements.2.elements.6.elements.2.attributes.stroke-width
  rd_2_4_vor.svg, elements.2.elements.6.elements.3.attributes.stroke-width
  rd_2_4_vor.svg, elements.2.elements.6.elements.4.attributes.stroke-width
  rd_2_4_vor.svg, elements.2.elements.7.elements.2.attributes.stroke-width
  rd_2_4_vor.svg, elements.2.elements.7.elements.3.attributes.stroke-width
  rd_2_4_vor.svg, elements.2.elements.7.elements.4.attributes.stroke-width
  rd_2_4_vor.svg, elements.2.elements.8.elements.2.attributes.stroke-width
  rd_2_4_vor.svg, elements.2.elements.8.elements.3.attributes.stroke-width
  rd_2_4_vor.svg, elements.2.elements.8.elements.4.attributes.stroke-width
  rd_2_4_vor.svg, elements.2.elements.9.elements.3.attributes.stroke-width
  rd_2_4_vor.svg, elements.2.elements.9.elements.4.attributes.stroke-width
  rd_2_4_vor.svg, elements.2.elements.9.elements.5.attributes.stroke-width
  rd_2_5_vor.svg, elements.2.elements.0.elements.2.attributes.stroke-width
  rd_2_5_vor.svg, elements.2.elements.0.elements.3.attributes.stroke-width
  rd_2_5_vor.svg, elements.2.elements.0.elements.4.attributes.stroke-width
  rd_2_5_vor.svg, elements.2.elements.1.elements.2.attributes.stroke-width
  rd_2_5_vor.svg, elements.2.elements.1.elements.3.attributes.stroke-width
  rd_2_5_vor.svg, elements.2.elements.1.elements.4.attributes.stroke-width
  rd_2_5_vor.svg, elements.2.elements.2.elements.1.attributes.stroke-width
  rd_2_5_vor.svg, elements.2.elements.2.elements.2.attributes.stroke-width
  rd_2_5_vor.svg, elements.2.elements.3.elements.2.attributes.stroke-width
  rd_2_5_vor.svg, elements.2.elements.3.elements.3.attributes.stroke-width
  rd_2_5_vor.svg, elements.2.elements.4.elements.3.attributes.stroke-width
  rd_2_5_vor.svg, elements.2.elements.4.elements.4.attributes.stroke-width
  rd_2_5_vor.svg, elements.2.elements.4.elements.5.attributes.stroke-width
  rd_2_5_vor.svg, elements.2.elements.5.elements.2.attributes.stroke-width
  rd_2_5_vor.svg, elements.2.elements.5.elements.3.attributes.stroke-width
  rd_2_5_vor.svg, elements.2.elements.6.elements.2.attributes.stroke-width
  rd_2_5_vor.svg, elements.2.elements.6.elements.3.attributes.stroke-width
  rd_2_5_vor.svg, elements.2.elements.6.elements.4.attributes.stroke-width
  rd_2_5_vor.svg, elements.2.elements.7.elements.2.attributes.stroke-width
  rd_2_5_vor.svg, elements.2.elements.7.elements.3.attributes.stroke-width
  rd_2_5_vor.svg, elements.2.elements.7.elements.4.attributes.stroke-width
  rd_2_5_vor.svg, elements.2.elements.8.elements.2.attributes.stroke-width
  rd_2_5_vor.svg, elements.2.elements.8.elements.3.attributes.stroke-width
  rd_2_5_vor.svg, elements.2.elements.8.elements.4.attributes.stroke-width
  rd_2_5_vor.svg, elements.2.elements.9.elements.3.attributes.stroke-width
  rd_2_5_vor.svg, elements.2.elements.9.elements.4.attributes.stroke-width
  rd_2_5_vor.svg, elements.2.elements.9.elements.5.attributes.stroke-width
  rd_2_intro.svg, elements.2.elements.0.elements.1.attributes.stroke-width
  rd_2_intro.svg, elements.2.elements.0.elements.2.attributes.stroke-width
  rd_2_intro.svg, elements.2.elements.1.elements.1.attributes.stroke-width
  rd_2_intro.svg, elements.2.elements.1.elements.2.attributes.stroke-width
  rd_2_intro.svg, elements.2.elements.2.elements.1.attributes.stroke-width
  rd_2_intro.svg, elements.2.elements.2.elements.2.attributes.stroke-width
  rd_2_intro.svg, elements.2.elements.2.elements.3.attributes.stroke-width
  rd_2_intro.svg, elements.2.elements.3.elements.1.attributes.stroke-width
  rd_2_intro.svg, elements.2.elements.3.elements.2.attributes.stroke-width
  rd_2_intro.svg, elements.2.elements.3.elements.3.attributes.stroke-width
  rd_2_intro.svg, elements.2.elements.4.elements.2.attributes.stroke-width
  rd_2_intro.svg, elements.2.elements.4.elements.3.attributes.stroke-width
  rd_2_intro.svg, elements.2.elements.5.elements.1.attributes.stroke-width
  rd_2_intro.svg, elements.2.elements.5.elements.2.attributes.stroke-width
  rd_2_intro.svg, elements.2.elements.6.elements.2.attributes.stroke-width
  rd_2_intro.svg, elements.2.elements.6.elements.3.attributes.stroke-width
  rd_2_intro.svg, elements.2.elements.7.elements.1.attributes.stroke-width
  rd_2_intro.svg, elements.2.elements.8.elements.1.attributes.stroke-width
  rd_2_intro.svg, elements.2.elements.9.elements.2.attributes.stroke-width
  rd_2_intro.svg, elements.2.elements.9.elements.3.attributes.stroke-width
  rd_2_intro.svg, elements.2.elements.9.elements.4.attributes.stroke-width
  rd_2_intro.svg, elements.2.elements.10.elements.1.attributes.stroke-width
  rd_2_intro.svg, elements.2.elements.10.elements.2.attributes.stroke-width
  rd_2_intro.svg, elements.2.elements.11.elements.1.attributes.stroke-width
  rd_2_intro.svg, elements.2.elements.11.elements.2.attributes.stroke-width
  rd_2_intro.svg, elements.2.elements.12.elements.2.attributes.stroke-width
  rd_2_intro.svg, elements.2.elements.12.elements.3.attributes.stroke-width
  rd_2_intro.svg, elements.2.elements.12.elements.4.attributes.stroke-width
  rd_2_intro.svg, elements.2.elements.13.elements.2.attributes.stroke-width
  rd_2_intro.svg, elements.2.elements.13.elements.3.attributes.stroke-width
  rd_2_intro.svg, elements.2.elements.14.elements.1.attributes.stroke-width
  rd_2_intro.svg, elements.2.elements.14.elements.2.attributes.stroke-width
  rd_2_intro.svg, elements.2.elements.15.elements.2.attributes.stroke-width
  rd_2_intro.svg, elements.2.elements.15.elements.3.attributes.stroke-width
  rd_2_intro.svg, elements.2.elements.16.elements.2.attributes.stroke-width
  rd_2_intro.svg, elements.2.elements.16.elements.3.attributes.stroke-width
  rd_2_intro.svg, elements.2.elements.17.elements.2.attributes.stroke-width
  rd_2_intro.svg, elements.2.elements.17.elements.3.attributes.stroke-width
  rd_2_intro.svg, elements.2.elements.18.elements.3.attributes.stroke-width
  rd_2_intro.svg, elements.2.elements.18.elements.4.attributes.stroke-width
  rd_2_intro.svg, elements.2.elements.18.elements.5.attributes.stroke-width
  */
  stroke-width: 4;
}

.svg-B1nUYKFsHg{
  /*
  Used In:
  SF_3_vor_3.svg, elements.2.elements.0.attributes.stroke-miterlimit
  SF_3_vor_3.svg, elements.2.elements.1.attributes.stroke-miterlimit
  SF_3_vor_3.svg, elements.2.elements.2.attributes.stroke-miterlimit
  SF_3_vor_3.svg, elements.2.elements.3.attributes.stroke-miterlimit
  SF_3_vor_3.svg, elements.2.elements.4.attributes.stroke-miterlimit
  SF_3_vor_3.svg, elements.2.elements.5.attributes.stroke-miterlimit
  SF_3_vor_3.svg, elements.2.elements.6.attributes.stroke-miterlimit
  SF_3_vor_3.svg, elements.2.elements.7.attributes.stroke-miterlimit
  SF_3_vor_3.svg, elements.2.elements.8.attributes.stroke-miterlimit
  SF_3_vor_3.svg, elements.2.elements.9.attributes.stroke-miterlimit
  SF_3_vor_3.svg, elements.2.elements.10.attributes.stroke-miterlimit
  SF_3_vor_3.svg, elements.2.elements.11.attributes.stroke-miterlimit
  SF_3_vor_3.svg, elements.2.elements.12.attributes.stroke-miterlimit
  SF_3_vor_3.svg, elements.2.elements.13.attributes.stroke-miterlimit
  SF_3_vor_3.svg, elements.2.elements.14.attributes.stroke-miterlimit
  SF_3_vor_3.svg, elements.2.elements.15.attributes.stroke-miterlimit
  SF_3_vor_3.svg, elements.2.elements.16.attributes.stroke-miterlimit
  SF_3_vor_3.svg, elements.2.elements.17.attributes.stroke-miterlimit
  SF_3_vor_3.svg, elements.2.elements.18.attributes.stroke-miterlimit
  SF_3_vor_3.svg, elements.2.elements.19.attributes.stroke-miterlimit
  SF_3_vor_3.svg, elements.2.elements.20.attributes.stroke-miterlimit
  SF_3_vor_4.svg, elements.2.elements.2.attributes.stroke-miterlimit
  SF_3_vor_beispiel.svg, elements.2.elements.2.attributes.stroke-miterlimit
  SF_3_vor_beispiel.svg, elements.2.elements.3.attributes.stroke-miterlimit
  SF_3_vor_beispiel.svg, elements.2.elements.4.attributes.stroke-miterlimit
  SF_3_vor_beispiel.svg, elements.2.elements.5.attributes.stroke-miterlimit
  gm_2_10_1.svg, elements.2.elements.0.attributes.stroke-miterlimit
  gm_2_10_1.svg, elements.2.elements.1.attributes.stroke-miterlimit
  gm_2_10_1.svg, elements.2.elements.2.attributes.stroke-miterlimit
  gm_2_10_1.svg, elements.2.elements.3.attributes.stroke-miterlimit
  gm_2_10_2.svg, elements.2.elements.0.elements.0.attributes.stroke-miterlimit
  gm_2_10_2.svg, elements.2.elements.0.elements.1.attributes.stroke-miterlimit
  gm_2_10_2.svg, elements.2.elements.0.elements.2.attributes.stroke-miterlimit
  gm_2_10_2.svg, elements.2.elements.0.elements.3.attributes.stroke-miterlimit
  gm_2_10_3.svg, elements.2.elements.0.attributes.stroke-miterlimit
  gm_2_10_3.svg, elements.2.elements.1.attributes.stroke-miterlimit
  gm_2_10_3.svg, elements.2.elements.2.attributes.stroke-miterlimit
  gm_2_10_3.svg, elements.2.elements.3.attributes.stroke-miterlimit
  gm_2_10_4.svg, elements.2.elements.0.attributes.stroke-miterlimit
  gm_2_10_4.svg, elements.2.elements.1.attributes.stroke-miterlimit
  gm_2_10_4.svg, elements.2.elements.2.attributes.stroke-miterlimit
  gm_2_10_4.svg, elements.2.elements.3.attributes.stroke-miterlimit
  gm_2_10_4.svg, elements.2.elements.4.attributes.stroke-miterlimit
  gm_2_10_gesamt.svg, elements.2.elements.0.elements.0.attributes.stroke-miterlimit
  gm_2_10_gesamt.svg, elements.2.elements.0.elements.1.attributes.stroke-miterlimit
  gm_2_10_gesamt.svg, elements.2.elements.0.elements.2.attributes.stroke-miterlimit
  gm_2_10_gesamt.svg, elements.2.elements.0.elements.3.attributes.stroke-miterlimit
  gm_2_10_gesamt.svg, elements.2.elements.0.elements.4.attributes.stroke-miterlimit
  gm_2_10_gesamt.svg, elements.2.elements.0.elements.5.attributes.stroke-miterlimit
  gm_2_10_vor.svg, elements.2.elements.0.elements.0.attributes.stroke-miterlimit
  gm_2_10_vor.svg, elements.2.elements.0.elements.1.attributes.stroke-miterlimit
  gm_2_10_vor.svg, elements.2.elements.0.elements.2.attributes.stroke-miterlimit
  gm_2_10_vor.svg, elements.2.elements.0.elements.3.attributes.stroke-miterlimit
  gm_2_11_1.svg, elements.2.elements.2.attributes.stroke-miterlimit
  gm_2_11_1.svg, elements.2.elements.3.attributes.stroke-miterlimit
  gm_2_11_1.svg, elements.2.elements.4.attributes.stroke-miterlimit
  gm_2_11_1.svg, elements.2.elements.5.attributes.stroke-miterlimit
  gm_2_11_1.svg, elements.2.elements.6.attributes.stroke-miterlimit
  gm_2_11_1.svg, elements.2.elements.7.attributes.stroke-miterlimit
  gm_2_11_1.svg, elements.2.elements.8.attributes.stroke-miterlimit
  gm_2_11_1.svg, elements.2.elements.9.attributes.stroke-miterlimit
  gm_2_11_2.svg, elements.2.elements.0.elements.0.attributes.stroke-miterlimit
  gm_2_11_2.svg, elements.2.elements.0.elements.1.attributes.stroke-miterlimit
  gm_2_11_2.svg, elements.2.elements.0.elements.2.attributes.stroke-miterlimit
  gm_2_11_2.svg, elements.2.elements.0.elements.3.attributes.stroke-miterlimit
  gm_2_11_2.svg, elements.2.elements.0.elements.4.attributes.stroke-miterlimit
  gm_2_11_2.svg, elements.2.elements.1.elements.0.attributes.stroke-miterlimit
  gm_2_11_2.svg, elements.2.elements.1.elements.1.attributes.stroke-miterlimit
  gm_2_11_3.svg, elements.2.elements.0.elements.1.attributes.stroke-miterlimit
  gm_2_11_3.svg, elements.2.elements.0.elements.2.attributes.stroke-miterlimit
  gm_2_11_3.svg, elements.2.elements.0.elements.3.attributes.stroke-miterlimit
  gm_2_11_3.svg, elements.2.elements.0.elements.4.attributes.stroke-miterlimit
  gm_2_11_3.svg, elements.2.elements.0.elements.5.attributes.stroke-miterlimit
  gm_2_11_3.svg, elements.2.elements.0.elements.6.attributes.stroke-miterlimit
  gm_2_11_3.svg, elements.2.elements.0.elements.7.attributes.stroke-miterlimit
  gm_2_11_3.svg, elements.2.elements.0.elements.8.attributes.stroke-miterlimit
  gm_2_11_3.svg, elements.2.elements.0.elements.9.attributes.stroke-miterlimit
  gm_2_11_3.svg, elements.2.elements.1.elements.0.attributes.stroke-miterlimit
  gm_2_11_3.svg, elements.2.elements.1.elements.1.attributes.stroke-miterlimit
  gm_2_11_4.svg, elements.2.elements.2.attributes.stroke-miterlimit
  gm_2_11_4.svg, elements.2.elements.3.attributes.stroke-miterlimit
  gm_2_11_4.svg, elements.2.elements.4.attributes.stroke-miterlimit
  gm_2_11_4.svg, elements.2.elements.5.attributes.stroke-miterlimit
  gm_2_11_4.svg, elements.2.elements.6.attributes.stroke-miterlimit
  gm_2_11_4.svg, elements.2.elements.7.attributes.stroke-miterlimit
  gm_2_11_4.svg, elements.2.elements.8.attributes.stroke-miterlimit
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.1.attributes.stroke-miterlimit
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.2.attributes.stroke-miterlimit
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.3.attributes.stroke-miterlimit
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.4.attributes.stroke-miterlimit
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.5.attributes.stroke-miterlimit
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.6.attributes.stroke-miterlimit
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.7.attributes.stroke-miterlimit
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.8.attributes.stroke-miterlimit
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.9.attributes.stroke-miterlimit
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.10.attributes.stroke-miterlimit
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.12.attributes.stroke-miterlimit
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.13.attributes.stroke-miterlimit
  gm_2_11_gesamt.svg, elements.2.elements.1.elements.0.attributes.stroke-miterlimit
  gm_2_11_gesamt.svg, elements.2.elements.1.elements.1.attributes.stroke-miterlimit
  gm_2_11_vor.svg, elements.2.elements.0.elements.1.attributes.stroke-miterlimit
  gm_2_11_vor.svg, elements.2.elements.0.elements.2.attributes.stroke-miterlimit
  gm_2_11_vor.svg, elements.2.elements.0.elements.3.attributes.stroke-miterlimit
  gm_2_11_vor.svg, elements.2.elements.0.elements.4.attributes.stroke-miterlimit
  gm_2_11_vor.svg, elements.2.elements.0.elements.5.attributes.stroke-miterlimit
  gm_2_11_vor.svg, elements.2.elements.0.elements.6.attributes.stroke-miterlimit
  gm_2_11_vor.svg, elements.2.elements.0.elements.8.attributes.stroke-miterlimit
  gm_2_11_vor.svg, elements.2.elements.1.elements.0.attributes.stroke-miterlimit
  gm_2_11_vor.svg, elements.2.elements.1.elements.1.attributes.stroke-miterlimit
  gm_2_12_1.svg, elements.2.elements.0.attributes.stroke-miterlimit
  gm_2_12_1.svg, elements.2.elements.1.attributes.stroke-miterlimit
  gm_2_12_1.svg, elements.2.elements.2.attributes.stroke-miterlimit
  gm_2_12_1.svg, elements.2.elements.3.attributes.stroke-miterlimit
  gm_2_12_1.svg, elements.2.elements.4.attributes.stroke-miterlimit
  gm_2_12_2.svg, elements.2.elements.0.attributes.stroke-miterlimit
  gm_2_12_2.svg, elements.2.elements.1.attributes.stroke-miterlimit
  gm_2_12_2.svg, elements.2.elements.2.attributes.stroke-miterlimit
  gm_2_12_2.svg, elements.2.elements.3.attributes.stroke-miterlimit
  gm_2_12_2.svg, elements.2.elements.4.attributes.stroke-miterlimit
  gm_2_12_2.svg, elements.2.elements.5.attributes.stroke-miterlimit
  gm_2_12_3.svg, elements.2.elements.0.attributes.stroke-miterlimit
  gm_2_12_3.svg, elements.2.elements.1.attributes.stroke-miterlimit
  gm_2_12_3.svg, elements.2.elements.2.attributes.stroke-miterlimit
  gm_2_12_3.svg, elements.2.elements.3.attributes.stroke-miterlimit
  gm_2_12_3.svg, elements.2.elements.4.attributes.stroke-miterlimit
  gm_2_12_4.svg, elements.2.elements.0.attributes.stroke-miterlimit
  gm_2_12_4.svg, elements.2.elements.1.attributes.stroke-miterlimit
  gm_2_12_4.svg, elements.2.elements.2.attributes.stroke-miterlimit
  gm_2_12_4.svg, elements.2.elements.3.attributes.stroke-miterlimit
  gm_2_12_gesamt.svg, elements.2.elements.0.attributes.stroke-miterlimit
  gm_2_12_gesamt.svg, elements.2.elements.1.attributes.stroke-miterlimit
  gm_2_12_gesamt.svg, elements.2.elements.2.attributes.stroke-miterlimit
  gm_2_12_gesamt.svg, elements.2.elements.3.attributes.stroke-miterlimit
  gm_2_12_gesamt.svg, elements.2.elements.4.attributes.stroke-miterlimit
  gm_2_12_gesamt.svg, elements.2.elements.5.attributes.stroke-miterlimit
  gm_2_12_vor.svg, elements.2.elements.0.attributes.stroke-miterlimit
  gm_2_12_vor.svg, elements.2.elements.1.attributes.stroke-miterlimit
  gm_2_12_vor.svg, elements.2.elements.2.attributes.stroke-miterlimit
  gm_2_12_vor.svg, elements.2.elements.3.attributes.stroke-miterlimit
  gm_2_1_1.svg, elements.2.elements.0.attributes.stroke-miterlimit
  gm_2_1_2.svg, elements.2.elements.0.attributes.stroke-miterlimit
  gm_2_1_3.svg, elements.2.elements.2.attributes.stroke-miterlimit
  gm_2_1_4.svg, elements.2.elements.0.attributes.stroke-miterlimit
  gm_2_1_gesamt.svg, elements.2.elements.5.attributes.stroke-miterlimit
  gm_2_1_gesamt.svg, elements.2.elements.6.attributes.stroke-miterlimit
  gm_2_1_vor.svg, elements.2.elements.3.attributes.stroke-miterlimit
  gm_2_2_1.svg, elements.2.elements.0.elements.1.attributes.stroke-miterlimit
  gm_2_2_1.svg, elements.2.elements.0.elements.2.attributes.stroke-miterlimit
  gm_2_2_1.svg, elements.2.elements.0.elements.3.attributes.stroke-miterlimit
  gm_2_2_2.svg, elements.2.elements.0.attributes.stroke-miterlimit
  gm_2_2_2.svg, elements.2.elements.1.attributes.stroke-miterlimit
  gm_2_2_2.svg, elements.2.elements.3.attributes.stroke-miterlimit
  gm_2_2_2.svg, elements.2.elements.4.attributes.stroke-miterlimit
  gm_2_2_3.svg, elements.2.elements.0.elements.0.attributes.stroke-miterlimit
  gm_2_2_3.svg, elements.2.elements.0.elements.1.attributes.stroke-miterlimit
  gm_2_2_3.svg, elements.2.elements.0.elements.3.attributes.stroke-miterlimit
  gm_2_2_4.svg, elements.2.elements.0.elements.0.attributes.stroke-miterlimit
  gm_2_2_4.svg, elements.2.elements.0.elements.1.attributes.stroke-miterlimit
  gm_2_2_4.svg, elements.2.elements.0.elements.3.attributes.stroke-miterlimit
  gm_2_2_gesamt.svg, elements.2.elements.0.elements.1.attributes.stroke-miterlimit
  gm_2_2_gesamt.svg, elements.2.elements.0.elements.2.attributes.stroke-miterlimit
  gm_2_2_gesamt.svg, elements.2.elements.0.elements.5.attributes.stroke-miterlimit
  gm_2_2_gesamt.svg, elements.2.elements.0.elements.6.attributes.stroke-miterlimit
  gm_2_2_vor.svg, elements.2.elements.3.attributes.stroke-miterlimit
  gm_2_3_1.svg, elements.2.elements.0.elements.0.attributes.stroke-miterlimit
  gm_2_3_1.svg, elements.2.elements.0.elements.1.attributes.stroke-miterlimit
  gm_2_3_1.svg, elements.2.elements.0.elements.2.attributes.stroke-miterlimit
  gm_2_3_2.svg, elements.2.elements.0.attributes.stroke-miterlimit
  gm_2_3_2.svg, elements.2.elements.1.attributes.stroke-miterlimit
  gm_2_3_2.svg, elements.2.elements.2.attributes.stroke-miterlimit
  gm_2_3_3.svg, elements.2.elements.0.elements.0.attributes.stroke-miterlimit
  gm_2_3_3.svg, elements.2.elements.0.elements.1.attributes.stroke-miterlimit
  gm_2_3_3.svg, elements.2.elements.0.elements.2.attributes.stroke-miterlimit
  gm_2_3_4.svg, elements.2.elements.0.elements.0.attributes.stroke-miterlimit
  gm_2_3_4.svg, elements.2.elements.0.elements.1.attributes.stroke-miterlimit
  gm_2_3_4.svg, elements.2.elements.0.elements.2.attributes.stroke-miterlimit
  gm_2_3_gesamt.svg, elements.2.elements.0.elements.0.attributes.stroke-miterlimit
  gm_2_3_gesamt.svg, elements.2.elements.0.elements.1.attributes.stroke-miterlimit
  gm_2_3_gesamt.svg, elements.2.elements.0.elements.2.attributes.stroke-miterlimit
  gm_2_3_gesamt.svg, elements.2.elements.0.elements.3.attributes.stroke-miterlimit
  gm_2_3_gesamt.svg, elements.2.elements.0.elements.4.attributes.stroke-miterlimit
  gm_2_3_gesamt.svg, elements.2.elements.0.elements.5.attributes.stroke-miterlimit
  gm_2_3_vor.svg, elements.2.elements.0.elements.0.attributes.stroke-miterlimit
  gm_2_3_vor.svg, elements.2.elements.0.elements.1.attributes.stroke-miterlimit
  gm_2_3_vor.svg, elements.2.elements.0.elements.2.attributes.stroke-miterlimit
  gm_2_4_1.svg, elements.2.elements.1.attributes.stroke-miterlimit
  gm_2_4_1.svg, elements.2.elements.2.attributes.stroke-miterlimit
  gm_2_4_2.svg, elements.2.elements.0.attributes.stroke-miterlimit
  gm_2_4_2.svg, elements.2.elements.1.attributes.stroke-miterlimit
  gm_2_4_3.svg, elements.2.elements.0.attributes.stroke-miterlimit
  gm_2_4_3.svg, elements.2.elements.2.attributes.stroke-miterlimit
  gm_2_4_3.svg, elements.2.elements.3.attributes.stroke-miterlimit
  gm_2_4_3.svg, elements.2.elements.4.attributes.stroke-miterlimit
  gm_2_4_4.svg, elements.2.elements.1.attributes.stroke-miterlimit
  gm_2_4_4.svg, elements.2.elements.2.attributes.stroke-miterlimit
  gm_2_4_4.svg, elements.2.elements.3.attributes.stroke-miterlimit
  gm_2_4_gesamt.svg, elements.2.elements.0.attributes.stroke-miterlimit
  gm_2_4_gesamt.svg, elements.2.elements.1.attributes.stroke-miterlimit
  gm_2_4_gesamt.svg, elements.2.elements.2.attributes.stroke-miterlimit
  gm_2_4_vor.svg, elements.2.elements.0.elements.0.attributes.stroke-miterlimit
  gm_2_5_1.svg, elements.2.elements.0.attributes.stroke-miterlimit
  gm_2_5_1.svg, elements.2.elements.1.attributes.stroke-miterlimit
  gm_2_5_1.svg, elements.2.elements.2.attributes.stroke-miterlimit
  gm_2_5_1.svg, elements.2.elements.3.attributes.stroke-miterlimit
  gm_2_5_2.svg, elements.2.elements.0.attributes.stroke-miterlimit
  gm_2_5_2.svg, elements.2.elements.1.attributes.stroke-miterlimit
  gm_2_5_2.svg, elements.2.elements.2.attributes.stroke-miterlimit
  gm_2_5_2.svg, elements.2.elements.3.attributes.stroke-miterlimit
  gm_2_5_2.svg, elements.2.elements.4.attributes.stroke-miterlimit
  gm_2_5_3.svg, elements.2.elements.0.elements.0.attributes.stroke-miterlimit
  gm_2_5_3.svg, elements.2.elements.0.elements.1.attributes.stroke-miterlimit
  gm_2_5_3.svg, elements.2.elements.0.elements.2.attributes.stroke-miterlimit
  gm_2_5_3.svg, elements.2.elements.0.elements.3.attributes.stroke-miterlimit
  gm_2_5_4.svg, elements.2.elements.0.attributes.stroke-miterlimit
  gm_2_5_4.svg, elements.2.elements.1.attributes.stroke-miterlimit
  gm_2_5_4.svg, elements.2.elements.2.attributes.stroke-miterlimit
  gm_2_5_4.svg, elements.2.elements.3.attributes.stroke-miterlimit
  gm_2_5_4.svg, elements.2.elements.4.attributes.stroke-miterlimit
  gm_2_5_gesamt.svg, elements.2.elements.0.attributes.stroke-miterlimit
  gm_2_5_gesamt.svg, elements.2.elements.1.attributes.stroke-miterlimit
  gm_2_5_gesamt.svg, elements.2.elements.2.attributes.stroke-miterlimit
  gm_2_5_gesamt.svg, elements.2.elements.3.attributes.stroke-miterlimit
  gm_2_5_gesamt.svg, elements.2.elements.4.attributes.stroke-miterlimit
  gm_2_5_gesamt.svg, elements.2.elements.5.attributes.stroke-miterlimit
  gm_2_5_gesamt.svg, elements.2.elements.7.attributes.stroke-miterlimit
  gm_2_5_gesamt.svg, elements.2.elements.8.attributes.stroke-miterlimit
  gm_2_5_vor.svg, elements.2.elements.0.attributes.stroke-miterlimit
  gm_2_5_vor.svg, elements.2.elements.1.attributes.stroke-miterlimit
  gm_2_5_vor.svg, elements.2.elements.2.attributes.stroke-miterlimit
  gm_2_5_vor.svg, elements.2.elements.3.attributes.stroke-miterlimit
  gm_2_5_vor.svg, elements.2.elements.5.attributes.stroke-miterlimit
  gm_2_6_1.svg, elements.2.elements.0.attributes.stroke-miterlimit
  gm_2_6_2.svg, elements.2.elements.0.attributes.stroke-miterlimit
  gm_2_6_3.svg, elements.2.elements.0.attributes.stroke-miterlimit
  gm_2_6_4.svg, elements.2.elements.0.attributes.stroke-miterlimit
  gm_2_6_gesamt.svg, elements.2.elements.0.attributes.stroke-miterlimit
  gm_2_6_gesamt.svg, elements.2.elements.1.attributes.stroke-miterlimit
  gm_2_6_vor.svg, elements.2.elements.0.attributes.stroke-miterlimit
  gm_2_7_1.svg, elements.2.elements.0.attributes.stroke-miterlimit
  gm_2_7_1.svg, elements.2.elements.1.attributes.stroke-miterlimit
  gm_2_7_1.svg, elements.2.elements.2.attributes.stroke-miterlimit
  gm_2_7_2.svg, elements.2.elements.0.attributes.stroke-miterlimit
  gm_2_7_2.svg, elements.2.elements.1.attributes.stroke-miterlimit
  gm_2_7_2.svg, elements.2.elements.2.attributes.stroke-miterlimit
  gm_2_7_3.svg, elements.2.elements.0.elements.0.attributes.stroke-miterlimit
  gm_2_7_4.svg, elements.2.elements.0.attributes.stroke-miterlimit
  gm_2_7_4.svg, elements.2.elements.5.attributes.stroke-miterlimit
  gm_2_7_4.svg, elements.2.elements.6.attributes.stroke-miterlimit
  gm_2_7_gesamt.svg, elements.2.elements.0.attributes.stroke-miterlimit
  gm_2_7_gesamt.svg, elements.2.elements.1.attributes.stroke-miterlimit
  gm_2_7_gesamt.svg, elements.2.elements.2.attributes.stroke-miterlimit
  gm_2_7_gesamt.svg, elements.2.elements.3.attributes.stroke-miterlimit
  gm_2_7_vor.svg, elements.2.elements.0.attributes.stroke-miterlimit
  gm_2_7_vor.svg, elements.2.elements.1.attributes.stroke-miterlimit
  gm_2_7_vor.svg, elements.2.elements.2.attributes.stroke-miterlimit
  gm_2_8_1.svg, elements.2.elements.0.attributes.stroke-miterlimit
  gm_2_8_1.svg, elements.2.elements.1.attributes.stroke-miterlimit
  gm_2_8_1.svg, elements.2.elements.2.attributes.stroke-miterlimit
  gm_2_8_1.svg, elements.2.elements.3.attributes.stroke-miterlimit
  gm_2_8_1.svg, elements.2.elements.4.attributes.stroke-miterlimit
  gm_2_8_1.svg, elements.2.elements.5.attributes.stroke-miterlimit
  gm_2_8_1.svg, elements.2.elements.6.attributes.stroke-miterlimit
  gm_2_8_2.svg, elements.2.elements.0.elements.0.attributes.stroke-miterlimit
  gm_2_8_2.svg, elements.2.elements.0.elements.1.attributes.stroke-miterlimit
  gm_2_8_2.svg, elements.2.elements.0.elements.2.attributes.stroke-miterlimit
  gm_2_8_2.svg, elements.2.elements.0.elements.3.attributes.stroke-miterlimit
  gm_2_8_2.svg, elements.2.elements.0.elements.4.attributes.stroke-miterlimit
  gm_2_8_2.svg, elements.2.elements.0.elements.5.attributes.stroke-miterlimit
  gm_2_8_3.svg, elements.2.elements.0.attributes.stroke-miterlimit
  gm_2_8_3.svg, elements.2.elements.1.attributes.stroke-miterlimit
  gm_2_8_3.svg, elements.2.elements.2.attributes.stroke-miterlimit
  gm_2_8_3.svg, elements.2.elements.3.attributes.stroke-miterlimit
  gm_2_8_3.svg, elements.2.elements.4.attributes.stroke-miterlimit
  gm_2_8_3.svg, elements.2.elements.5.attributes.stroke-miterlimit
  gm_2_8_4.svg, elements.2.elements.0.elements.0.attributes.stroke-miterlimit
  gm_2_8_4.svg, elements.2.elements.0.elements.1.attributes.stroke-miterlimit
  gm_2_8_4.svg, elements.2.elements.0.elements.2.attributes.stroke-miterlimit
  gm_2_8_4.svg, elements.2.elements.0.elements.3.attributes.stroke-miterlimit
  gm_2_8_4.svg, elements.2.elements.0.elements.4.attributes.stroke-miterlimit
  gm_2_8_gesamt.svg, elements.2.elements.0.elements.0.attributes.stroke-miterlimit
  gm_2_8_gesamt.svg, elements.2.elements.0.elements.1.attributes.stroke-miterlimit
  gm_2_8_gesamt.svg, elements.2.elements.0.elements.2.attributes.stroke-miterlimit
  gm_2_8_gesamt.svg, elements.2.elements.0.elements.3.attributes.stroke-miterlimit
  gm_2_8_gesamt.svg, elements.2.elements.0.elements.4.attributes.stroke-miterlimit
  gm_2_8_gesamt.svg, elements.2.elements.0.elements.5.attributes.stroke-miterlimit
  gm_2_8_gesamt.svg, elements.2.elements.0.elements.6.attributes.stroke-miterlimit
  gm_2_8_gesamt.svg, elements.2.elements.0.elements.7.attributes.stroke-miterlimit
  gm_2_8_vor.svg, elements.2.elements.0.elements.0.attributes.stroke-miterlimit
  gm_2_8_vor.svg, elements.2.elements.0.elements.1.attributes.stroke-miterlimit
  gm_2_8_vor.svg, elements.2.elements.0.elements.2.attributes.stroke-miterlimit
  gm_2_8_vor.svg, elements.2.elements.0.elements.3.attributes.stroke-miterlimit
  gm_2_8_vor.svg, elements.2.elements.0.elements.4.attributes.stroke-miterlimit
  gm_2_9_1.svg, elements.2.elements.0.attributes.stroke-miterlimit
  gm_2_9_1.svg, elements.2.elements.1.attributes.stroke-miterlimit
  gm_2_9_1.svg, elements.2.elements.4.attributes.stroke-miterlimit
  gm_2_9_2.svg, elements.2.elements.0.attributes.stroke-miterlimit
  gm_2_9_2.svg, elements.2.elements.1.attributes.stroke-miterlimit
  gm_2_9_2.svg, elements.2.elements.2.attributes.stroke-miterlimit
  gm_2_9_3.svg, elements.2.elements.0.attributes.stroke-miterlimit
  gm_2_9_3.svg, elements.2.elements.1.attributes.stroke-miterlimit
  gm_2_9_3.svg, elements.2.elements.2.attributes.stroke-miterlimit
  gm_2_9_4.svg, elements.2.elements.0.attributes.stroke-miterlimit
  gm_2_9_4.svg, elements.2.elements.1.attributes.stroke-miterlimit
  gm_2_9_4.svg, elements.2.elements.2.attributes.stroke-miterlimit
  gm_2_9_gesamt.svg, elements.2.elements.0.attributes.stroke-miterlimit
  gm_2_9_gesamt.svg, elements.2.elements.1.attributes.stroke-miterlimit
  gm_2_9_gesamt.svg, elements.2.elements.6.attributes.stroke-miterlimit
  gm_2_9_gesamt.svg, elements.2.elements.7.attributes.stroke-miterlimit
  gm_2_9_vor.svg, elements.2.elements.0.attributes.stroke-miterlimit
  gm_2_9_vor.svg, elements.2.elements.1.attributes.stroke-miterlimit
  gm_2_9_vor.svg, elements.2.elements.4.attributes.stroke-miterlimit
  k_1_vor_beispiel.svg, elements.2.elements.1.attributes.stroke-miterlimit
  k_2_vor.svg, elements.2.elements.0.attributes.stroke-miterlimit
  k_2_vor.svg, elements.2.elements.1.attributes.stroke-miterlimit
  ptv_2_4.svg, elements.2.elements.0.attributes.stroke-miterlimit
  ptv_3.svg, elements.2.elements.6.elements.2.attributes.stroke-miterlimit
  ptv_3.svg, elements.2.elements.7.elements.2.attributes.stroke-miterlimit
  rd_1_2_1.svg, elements.2.elements.0.attributes.stroke-miterlimit
  rd_1_2_1.svg, elements.2.elements.1.attributes.stroke-miterlimit
  rd_1_2_1.svg, elements.2.elements.2.attributes.stroke-miterlimit
  rd_1_2_1.svg, elements.2.elements.3.attributes.stroke-miterlimit
  rd_1_2_1.svg, elements.2.elements.4.attributes.stroke-miterlimit
  rd_1_2_1.svg, elements.2.elements.5.attributes.stroke-miterlimit
  rd_1_2_1.svg, elements.2.elements.6.attributes.stroke-miterlimit
  rd_1_2_1.svg, elements.2.elements.7.attributes.stroke-miterlimit
  rd_1_2_1.svg, elements.2.elements.8.attributes.stroke-miterlimit
  rd_1_2_1.svg, elements.2.elements.9.attributes.stroke-miterlimit
  rd_1_2_1.svg, elements.2.elements.10.attributes.stroke-miterlimit
  rd_1_2_1.svg, elements.2.elements.11.attributes.stroke-miterlimit
  rd_1_2_1.svg, elements.2.elements.12.attributes.stroke-miterlimit
  rd_1_2_1.svg, elements.2.elements.13.attributes.stroke-miterlimit
  rd_1_2_2.svg, elements.2.elements.0.attributes.stroke-miterlimit
  rd_1_2_2.svg, elements.2.elements.1.attributes.stroke-miterlimit
  rd_1_2_2.svg, elements.2.elements.2.attributes.stroke-miterlimit
  rd_1_2_2.svg, elements.2.elements.3.attributes.stroke-miterlimit
  rd_1_2_2.svg, elements.2.elements.4.attributes.stroke-miterlimit
  rd_1_2_2.svg, elements.2.elements.5.attributes.stroke-miterlimit
  rd_1_2_2.svg, elements.2.elements.6.attributes.stroke-miterlimit
  rd_1_2_2.svg, elements.2.elements.7.attributes.stroke-miterlimit
  rd_1_2_2.svg, elements.2.elements.8.attributes.stroke-miterlimit
  rd_1_2_2.svg, elements.2.elements.9.attributes.stroke-miterlimit
  rd_1_2_2.svg, elements.2.elements.10.attributes.stroke-miterlimit
  rd_1_2_2.svg, elements.2.elements.11.attributes.stroke-miterlimit
  rd_1_2_2.svg, elements.2.elements.12.attributes.stroke-miterlimit
  rd_1_2_2.svg, elements.2.elements.13.attributes.stroke-miterlimit
  rd_1_2_3.svg, elements.2.elements.0.attributes.stroke-miterlimit
  rd_1_2_3.svg, elements.2.elements.1.attributes.stroke-miterlimit
  rd_1_2_3.svg, elements.2.elements.2.attributes.stroke-miterlimit
  rd_1_2_3.svg, elements.2.elements.3.attributes.stroke-miterlimit
  rd_1_2_3.svg, elements.2.elements.4.attributes.stroke-miterlimit
  rd_1_2_3.svg, elements.2.elements.5.attributes.stroke-miterlimit
  rd_1_2_3.svg, elements.2.elements.6.attributes.stroke-miterlimit
  rd_1_2_3.svg, elements.2.elements.7.attributes.stroke-miterlimit
  rd_1_2_3.svg, elements.2.elements.8.attributes.stroke-miterlimit
  rd_1_2_3.svg, elements.2.elements.9.attributes.stroke-miterlimit
  rd_1_2_3.svg, elements.2.elements.10.attributes.stroke-miterlimit
  rd_1_2_3.svg, elements.2.elements.11.attributes.stroke-miterlimit
  rd_1_2_3.svg, elements.2.elements.12.attributes.stroke-miterlimit
  rd_1_2_3.svg, elements.2.elements.13.attributes.stroke-miterlimit
  rd_1_2_3.svg, elements.2.elements.14.attributes.stroke-miterlimit
  rd_1_2_3.svg, elements.2.elements.15.attributes.stroke-miterlimit
  rd_1_2_vor.svg, elements.2.elements.6.attributes.stroke-miterlimit
  rd_1_2_vor.svg, elements.2.elements.8.attributes.stroke-miterlimit
  rd_1_3_1.svg, elements.2.elements.0.attributes.stroke-miterlimit
  rd_1_3_1.svg, elements.2.elements.2.attributes.stroke-miterlimit
  rd_1_3_2.svg, elements.2.elements.0.attributes.stroke-miterlimit
  rd_1_3_2.svg, elements.2.elements.2.attributes.stroke-miterlimit
  rd_1_3_2.svg, elements.2.elements.4.attributes.stroke-miterlimit
  rd_1_3_3.svg, elements.2.elements.0.attributes.stroke-miterlimit
  rd_1_3_3.svg, elements.2.elements.2.attributes.stroke-miterlimit
  rd_1_3_3.svg, elements.2.elements.4.attributes.stroke-miterlimit
  rd_1_beispiel.svg, elements.2.elements.0.elements.0.attributes.stroke-miterlimit
  rd_1_beispiel.svg, elements.2.elements.0.elements.3.attributes.stroke-miterlimit
  rd_1_beispiel.svg, elements.2.elements.0.elements.6.attributes.stroke-miterlimit
  rd_1_beispiel.svg, elements.2.elements.0.elements.7.attributes.stroke-miterlimit
  rd_1_beispiel.svg, elements.2.elements.0.elements.8.attributes.stroke-miterlimit
  rd_2_1_vor.svg, elements.2.elements.0.elements.1.attributes.stroke-miterlimit
  rd_2_1_vor.svg, elements.2.elements.0.elements.2.attributes.stroke-miterlimit
  rd_2_1_vor.svg, elements.2.elements.0.elements.3.attributes.stroke-miterlimit
  rd_2_1_vor.svg, elements.2.elements.2.elements.2.attributes.stroke-miterlimit
  rd_2_1_vor.svg, elements.2.elements.2.elements.3.attributes.stroke-miterlimit
  rd_2_1_vor.svg, elements.2.elements.3.elements.1.attributes.stroke-miterlimit
  rd_2_1_vor.svg, elements.2.elements.3.elements.2.attributes.stroke-miterlimit
  rd_2_1_vor.svg, elements.2.elements.4.elements.1.attributes.stroke-miterlimit
  rd_2_1_vor.svg, elements.2.elements.4.elements.2.attributes.stroke-miterlimit
  rd_2_1_vor.svg, elements.2.elements.5.elements.2.attributes.stroke-miterlimit
  rd_2_1_vor.svg, elements.2.elements.5.elements.3.attributes.stroke-miterlimit
  rd_2_1_vor.svg, elements.2.elements.5.elements.4.attributes.stroke-miterlimit
  rd_2_1_vor.svg, elements.2.elements.6.elements.1.attributes.stroke-miterlimit
  rd_2_1_vor.svg, elements.2.elements.6.elements.2.attributes.stroke-miterlimit
  rd_2_1_vor.svg, elements.2.elements.6.elements.3.attributes.stroke-miterlimit
  rd_2_1_vor.svg, elements.2.elements.7.elements.3.attributes.stroke-miterlimit
  rd_2_1_vor.svg, elements.2.elements.7.elements.4.attributes.stroke-miterlimit
  rd_2_1_vor.svg, elements.2.elements.7.elements.5.attributes.stroke-miterlimit
  rd_2_1_vor.svg, elements.2.elements.8.elements.1.attributes.stroke-miterlimit
  rd_2_1_vor.svg, elements.2.elements.8.elements.2.attributes.stroke-miterlimit
  rd_2_1_vor.svg, elements.2.elements.9.elements.0.elements.1.attributes.stroke-miterlimit
  rd_2_1_vor.svg, elements.2.elements.9.elements.0.elements.2.attributes.stroke-miterlimit
  rd_2_1_vor.svg, elements.2.elements.9.elements.1.elements.1.attributes.stroke-miterlimit
  rd_2_1_vor.svg, elements.2.elements.9.elements.1.elements.2.attributes.stroke-miterlimit
  rd_2_1_vor.svg, elements.2.elements.9.elements.1.elements.3.attributes.stroke-miterlimit
  rd_2_1_vor.svg, elements.2.elements.9.elements.2.elements.3.attributes.stroke-miterlimit
  rd_2_1_vor.svg, elements.2.elements.9.elements.2.elements.4.attributes.stroke-miterlimit
  rd_2_1_vor.svg, elements.2.elements.9.elements.2.elements.5.attributes.stroke-miterlimit
  rd_2_1_vor.svg, elements.2.elements.10.elements.1.attributes.stroke-miterlimit
  rd_2_1_vor.svg, elements.2.elements.10.elements.2.attributes.stroke-miterlimit
  rd_2_1_vor.svg, elements.2.elements.10.elements.3.attributes.stroke-miterlimit
  rd_2_1_vor.svg, elements.2.elements.11.elements.3.attributes.stroke-miterlimit
  rd_2_1_vor.svg, elements.2.elements.11.elements.4.attributes.stroke-miterlimit
  rd_2_1_vor.svg, elements.2.elements.11.elements.5.attributes.stroke-miterlimit
  rd_2_1_vor.svg, elements.2.elements.12.elements.2.attributes.stroke-miterlimit
  rd_2_1_vor.svg, elements.2.elements.12.elements.3.attributes.stroke-miterlimit
  rd_2_1_vor.svg, elements.2.elements.12.elements.4.attributes.stroke-miterlimit
  rd_2_1_vor.svg, elements.2.elements.13.elements.2.attributes.stroke-miterlimit
  rd_2_1_vor.svg, elements.2.elements.13.elements.3.attributes.stroke-miterlimit
  rd_2_1_vor.svg, elements.2.elements.13.elements.4.attributes.stroke-miterlimit
  rd_2_1_vor.svg, elements.2.elements.14.elements.3.attributes.stroke-miterlimit
  rd_2_1_vor.svg, elements.2.elements.14.elements.4.attributes.stroke-miterlimit
  rd_2_1_vor.svg, elements.2.elements.14.elements.5.attributes.stroke-miterlimit
  rd_2_2_vor.svg, elements.2.elements.0.elements.1.attributes.stroke-miterlimit
  rd_2_2_vor.svg, elements.2.elements.0.elements.2.attributes.stroke-miterlimit
  rd_2_2_vor.svg, elements.2.elements.1.elements.1.attributes.stroke-miterlimit
  rd_2_2_vor.svg, elements.2.elements.1.elements.2.attributes.stroke-miterlimit
  rd_2_2_vor.svg, elements.2.elements.2.elements.1.attributes.stroke-miterlimit
  rd_2_2_vor.svg, elements.2.elements.2.elements.2.attributes.stroke-miterlimit
  rd_2_2_vor.svg, elements.2.elements.3.elements.1.attributes.stroke-miterlimit
  rd_2_2_vor.svg, elements.2.elements.3.elements.2.attributes.stroke-miterlimit
  rd_2_2_vor.svg, elements.2.elements.4.elements.1.attributes.stroke-miterlimit
  rd_2_2_vor.svg, elements.2.elements.4.elements.2.attributes.stroke-miterlimit
  rd_2_2_vor.svg, elements.2.elements.5.elements.2.attributes.stroke-miterlimit
  rd_2_2_vor.svg, elements.2.elements.5.elements.3.attributes.stroke-miterlimit
  rd_2_2_vor.svg, elements.2.elements.6.elements.2.attributes.stroke-miterlimit
  rd_2_2_vor.svg, elements.2.elements.6.elements.3.attributes.stroke-miterlimit
  rd_2_2_vor.svg, elements.2.elements.6.elements.4.attributes.stroke-miterlimit
  rd_2_2_vor.svg, elements.2.elements.7.elements.2.attributes.stroke-miterlimit
  rd_2_2_vor.svg, elements.2.elements.7.elements.3.attributes.stroke-miterlimit
  rd_2_2_vor.svg, elements.2.elements.7.elements.4.attributes.stroke-miterlimit
  rd_2_2_vor.svg, elements.2.elements.8.elements.2.attributes.stroke-miterlimit
  rd_2_2_vor.svg, elements.2.elements.8.elements.3.attributes.stroke-miterlimit
  rd_2_2_vor.svg, elements.2.elements.8.elements.4.attributes.stroke-miterlimit
  rd_2_2_vor.svg, elements.2.elements.9.elements.2.attributes.stroke-miterlimit
  rd_2_2_vor.svg, elements.2.elements.9.elements.3.attributes.stroke-miterlimit
  rd_2_2_vor.svg, elements.2.elements.9.elements.4.attributes.stroke-miterlimit
  rd_2_2_vor.svg, elements.2.elements.10.elements.2.attributes.stroke-miterlimit
  rd_2_2_vor.svg, elements.2.elements.10.elements.3.attributes.stroke-miterlimit
  rd_2_2_vor.svg, elements.2.elements.10.elements.4.attributes.stroke-miterlimit
  rd_2_2_vor.svg, elements.2.elements.11.elements.3.attributes.stroke-miterlimit
  rd_2_2_vor.svg, elements.2.elements.11.elements.4.attributes.stroke-miterlimit
  rd_2_2_vor.svg, elements.2.elements.11.elements.5.attributes.stroke-miterlimit
  rd_2_2_vor.svg, elements.2.elements.12.elements.3.attributes.stroke-miterlimit
  rd_2_2_vor.svg, elements.2.elements.12.elements.4.attributes.stroke-miterlimit
  rd_2_2_vor.svg, elements.2.elements.12.elements.5.attributes.stroke-miterlimit
  rd_2_3_vor.svg, elements.2.elements.0.elements.1.attributes.stroke-miterlimit
  rd_2_3_vor.svg, elements.2.elements.1.elements.2.attributes.stroke-miterlimit
  rd_2_3_vor.svg, elements.2.elements.1.elements.3.attributes.stroke-miterlimit
  rd_2_3_vor.svg, elements.2.elements.1.elements.4.attributes.stroke-miterlimit
  rd_2_3_vor.svg, elements.2.elements.2.elements.2.attributes.stroke-miterlimit
  rd_2_3_vor.svg, elements.2.elements.2.elements.3.attributes.stroke-miterlimit
  rd_2_3_vor.svg, elements.2.elements.2.elements.4.attributes.stroke-miterlimit
  rd_2_3_vor.svg, elements.2.elements.3.elements.2.attributes.stroke-miterlimit
  rd_2_3_vor.svg, elements.2.elements.3.elements.3.attributes.stroke-miterlimit
  rd_2_3_vor.svg, elements.2.elements.4.elements.1.attributes.stroke-miterlimit
  rd_2_3_vor.svg, elements.2.elements.4.elements.2.attributes.stroke-miterlimit
  rd_2_3_vor.svg, elements.2.elements.5.elements.2.attributes.stroke-miterlimit
  rd_2_3_vor.svg, elements.2.elements.5.elements.3.attributes.stroke-miterlimit
  rd_2_3_vor.svg, elements.2.elements.6.elements.2.attributes.stroke-miterlimit
  rd_2_3_vor.svg, elements.2.elements.6.elements.3.attributes.stroke-miterlimit
  rd_2_3_vor.svg, elements.2.elements.7.elements.1.attributes.stroke-miterlimit
  rd_2_3_vor.svg, elements.2.elements.7.elements.2.attributes.stroke-miterlimit
  rd_2_3_vor.svg, elements.2.elements.8.elements.2.attributes.stroke-miterlimit
  rd_2_3_vor.svg, elements.2.elements.8.elements.3.attributes.stroke-miterlimit
  rd_2_3_vor.svg, elements.2.elements.8.elements.4.attributes.stroke-miterlimit
  rd_2_3_vor.svg, elements.2.elements.9.elements.2.attributes.stroke-miterlimit
  rd_2_3_vor.svg, elements.2.elements.9.elements.3.attributes.stroke-miterlimit
  rd_2_3_vor.svg, elements.2.elements.10.elements.1.attributes.stroke-miterlimit
  rd_2_3_vor.svg, elements.2.elements.10.elements.2.attributes.stroke-miterlimit
  rd_2_3_vor.svg, elements.2.elements.10.elements.3.attributes.stroke-miterlimit
  rd_2_3_vor.svg, elements.2.elements.11.elements.3.attributes.stroke-miterlimit
  rd_2_3_vor.svg, elements.2.elements.11.elements.4.attributes.stroke-miterlimit
  rd_2_3_vor.svg, elements.2.elements.11.elements.5.attributes.stroke-miterlimit
  rd_2_3_vor.svg, elements.2.elements.12.elements.3.attributes.stroke-miterlimit
  rd_2_3_vor.svg, elements.2.elements.12.elements.4.attributes.stroke-miterlimit
  rd_2_3_vor.svg, elements.2.elements.12.elements.5.attributes.stroke-miterlimit
  rd_2_4_vor.svg, elements.2.elements.0.elements.2.attributes.stroke-miterlimit
  rd_2_4_vor.svg, elements.2.elements.0.elements.3.attributes.stroke-miterlimit
  rd_2_4_vor.svg, elements.2.elements.0.elements.4.attributes.stroke-miterlimit
  rd_2_4_vor.svg, elements.2.elements.1.elements.3.attributes.stroke-miterlimit
  rd_2_4_vor.svg, elements.2.elements.1.elements.4.attributes.stroke-miterlimit
  rd_2_4_vor.svg, elements.2.elements.1.elements.5.attributes.stroke-miterlimit
  rd_2_4_vor.svg, elements.2.elements.2.elements.1.attributes.stroke-miterlimit
  rd_2_4_vor.svg, elements.2.elements.2.elements.2.attributes.stroke-miterlimit
  rd_2_4_vor.svg, elements.2.elements.3.elements.2.attributes.stroke-miterlimit
  rd_2_4_vor.svg, elements.2.elements.3.elements.3.attributes.stroke-miterlimit
  rd_2_4_vor.svg, elements.2.elements.3.elements.4.attributes.stroke-miterlimit
  rd_2_4_vor.svg, elements.2.elements.4.elements.1.attributes.stroke-miterlimit
  rd_2_4_vor.svg, elements.2.elements.4.elements.2.attributes.stroke-miterlimit
  rd_2_4_vor.svg, elements.2.elements.5.elements.1.attributes.stroke-miterlimit
  rd_2_4_vor.svg, elements.2.elements.5.elements.2.attributes.stroke-miterlimit
  rd_2_4_vor.svg, elements.2.elements.6.elements.2.attributes.stroke-miterlimit
  rd_2_4_vor.svg, elements.2.elements.6.elements.3.attributes.stroke-miterlimit
  rd_2_4_vor.svg, elements.2.elements.6.elements.4.attributes.stroke-miterlimit
  rd_2_4_vor.svg, elements.2.elements.7.elements.2.attributes.stroke-miterlimit
  rd_2_4_vor.svg, elements.2.elements.7.elements.3.attributes.stroke-miterlimit
  rd_2_4_vor.svg, elements.2.elements.7.elements.4.attributes.stroke-miterlimit
  rd_2_4_vor.svg, elements.2.elements.8.elements.2.attributes.stroke-miterlimit
  rd_2_4_vor.svg, elements.2.elements.8.elements.3.attributes.stroke-miterlimit
  rd_2_4_vor.svg, elements.2.elements.8.elements.4.attributes.stroke-miterlimit
  rd_2_4_vor.svg, elements.2.elements.9.elements.3.attributes.stroke-miterlimit
  rd_2_4_vor.svg, elements.2.elements.9.elements.4.attributes.stroke-miterlimit
  rd_2_4_vor.svg, elements.2.elements.9.elements.5.attributes.stroke-miterlimit
  rd_2_5_vor.svg, elements.2.elements.0.elements.2.attributes.stroke-miterlimit
  rd_2_5_vor.svg, elements.2.elements.0.elements.3.attributes.stroke-miterlimit
  rd_2_5_vor.svg, elements.2.elements.0.elements.4.attributes.stroke-miterlimit
  rd_2_5_vor.svg, elements.2.elements.1.elements.2.attributes.stroke-miterlimit
  rd_2_5_vor.svg, elements.2.elements.1.elements.3.attributes.stroke-miterlimit
  rd_2_5_vor.svg, elements.2.elements.1.elements.4.attributes.stroke-miterlimit
  rd_2_5_vor.svg, elements.2.elements.2.elements.1.attributes.stroke-miterlimit
  rd_2_5_vor.svg, elements.2.elements.2.elements.2.attributes.stroke-miterlimit
  rd_2_5_vor.svg, elements.2.elements.3.elements.2.attributes.stroke-miterlimit
  rd_2_5_vor.svg, elements.2.elements.3.elements.3.attributes.stroke-miterlimit
  rd_2_5_vor.svg, elements.2.elements.4.elements.3.attributes.stroke-miterlimit
  rd_2_5_vor.svg, elements.2.elements.4.elements.4.attributes.stroke-miterlimit
  rd_2_5_vor.svg, elements.2.elements.4.elements.5.attributes.stroke-miterlimit
  rd_2_5_vor.svg, elements.2.elements.5.elements.2.attributes.stroke-miterlimit
  rd_2_5_vor.svg, elements.2.elements.5.elements.3.attributes.stroke-miterlimit
  rd_2_5_vor.svg, elements.2.elements.6.elements.2.attributes.stroke-miterlimit
  rd_2_5_vor.svg, elements.2.elements.6.elements.3.attributes.stroke-miterlimit
  rd_2_5_vor.svg, elements.2.elements.6.elements.4.attributes.stroke-miterlimit
  rd_2_5_vor.svg, elements.2.elements.7.elements.2.attributes.stroke-miterlimit
  rd_2_5_vor.svg, elements.2.elements.7.elements.3.attributes.stroke-miterlimit
  rd_2_5_vor.svg, elements.2.elements.7.elements.4.attributes.stroke-miterlimit
  rd_2_5_vor.svg, elements.2.elements.8.elements.2.attributes.stroke-miterlimit
  rd_2_5_vor.svg, elements.2.elements.8.elements.3.attributes.stroke-miterlimit
  rd_2_5_vor.svg, elements.2.elements.8.elements.4.attributes.stroke-miterlimit
  rd_2_5_vor.svg, elements.2.elements.9.elements.3.attributes.stroke-miterlimit
  rd_2_5_vor.svg, elements.2.elements.9.elements.4.attributes.stroke-miterlimit
  rd_2_5_vor.svg, elements.2.elements.9.elements.5.attributes.stroke-miterlimit
  rd_2_intro.svg, elements.2.elements.0.elements.1.attributes.stroke-miterlimit
  rd_2_intro.svg, elements.2.elements.0.elements.2.attributes.stroke-miterlimit
  rd_2_intro.svg, elements.2.elements.1.elements.1.attributes.stroke-miterlimit
  rd_2_intro.svg, elements.2.elements.1.elements.2.attributes.stroke-miterlimit
  rd_2_intro.svg, elements.2.elements.2.elements.1.attributes.stroke-miterlimit
  rd_2_intro.svg, elements.2.elements.2.elements.2.attributes.stroke-miterlimit
  rd_2_intro.svg, elements.2.elements.2.elements.3.attributes.stroke-miterlimit
  rd_2_intro.svg, elements.2.elements.3.elements.1.attributes.stroke-miterlimit
  rd_2_intro.svg, elements.2.elements.3.elements.2.attributes.stroke-miterlimit
  rd_2_intro.svg, elements.2.elements.3.elements.3.attributes.stroke-miterlimit
  rd_2_intro.svg, elements.2.elements.4.elements.2.attributes.stroke-miterlimit
  rd_2_intro.svg, elements.2.elements.4.elements.3.attributes.stroke-miterlimit
  rd_2_intro.svg, elements.2.elements.5.elements.1.attributes.stroke-miterlimit
  rd_2_intro.svg, elements.2.elements.5.elements.2.attributes.stroke-miterlimit
  rd_2_intro.svg, elements.2.elements.6.elements.2.attributes.stroke-miterlimit
  rd_2_intro.svg, elements.2.elements.6.elements.3.attributes.stroke-miterlimit
  rd_2_intro.svg, elements.2.elements.7.elements.1.attributes.stroke-miterlimit
  rd_2_intro.svg, elements.2.elements.8.elements.1.attributes.stroke-miterlimit
  rd_2_intro.svg, elements.2.elements.9.elements.2.attributes.stroke-miterlimit
  rd_2_intro.svg, elements.2.elements.9.elements.3.attributes.stroke-miterlimit
  rd_2_intro.svg, elements.2.elements.9.elements.4.attributes.stroke-miterlimit
  rd_2_intro.svg, elements.2.elements.10.elements.1.attributes.stroke-miterlimit
  rd_2_intro.svg, elements.2.elements.10.elements.2.attributes.stroke-miterlimit
  rd_2_intro.svg, elements.2.elements.11.elements.1.attributes.stroke-miterlimit
  rd_2_intro.svg, elements.2.elements.11.elements.2.attributes.stroke-miterlimit
  rd_2_intro.svg, elements.2.elements.12.elements.2.attributes.stroke-miterlimit
  rd_2_intro.svg, elements.2.elements.12.elements.3.attributes.stroke-miterlimit
  rd_2_intro.svg, elements.2.elements.12.elements.4.attributes.stroke-miterlimit
  rd_2_intro.svg, elements.2.elements.13.elements.2.attributes.stroke-miterlimit
  rd_2_intro.svg, elements.2.elements.13.elements.3.attributes.stroke-miterlimit
  rd_2_intro.svg, elements.2.elements.14.elements.1.attributes.stroke-miterlimit
  rd_2_intro.svg, elements.2.elements.14.elements.2.attributes.stroke-miterlimit
  rd_2_intro.svg, elements.2.elements.15.elements.2.attributes.stroke-miterlimit
  rd_2_intro.svg, elements.2.elements.15.elements.3.attributes.stroke-miterlimit
  rd_2_intro.svg, elements.2.elements.16.elements.2.attributes.stroke-miterlimit
  rd_2_intro.svg, elements.2.elements.16.elements.3.attributes.stroke-miterlimit
  rd_2_intro.svg, elements.2.elements.17.elements.2.attributes.stroke-miterlimit
  rd_2_intro.svg, elements.2.elements.17.elements.3.attributes.stroke-miterlimit
  rd_2_intro.svg, elements.2.elements.18.elements.3.attributes.stroke-miterlimit
  rd_2_intro.svg, elements.2.elements.18.elements.4.attributes.stroke-miterlimit
  rd_2_intro.svg, elements.2.elements.18.elements.5.attributes.stroke-miterlimit
  */
  stroke-miterlimit: 10;
}

.svg-kOjx_P6JFu{
  /*
  Used In:
  SF_3_vor_4.svg, elements.2.elements.1.attributes.fill
  SF_3_vor_4.svg, elements.2.elements.3.attributes.fill
  SF_3_vor_4.svg, elements.2.elements.4.attributes.fill
  SF_3_vor_4.svg, elements.2.elements.5.attributes.fill
  SF_3_vor_4.svg, elements.2.elements.6.attributes.fill
  SF_3_vor_4.svg, elements.2.elements.7.attributes.fill
  */
  fill: #676767;
}

.svg-OI-1f_yJWc{
  /*
  Used In:
  SF_3_vor_4.svg, elements.2.elements.2.attributes.stroke
  */
  stroke: #999999;
}

.svg-YryJ7fNCSc{
  /*
  Used In:
  SF_3_vor_4.svg, elements.2.elements.2.attributes.stroke-width
  */
  stroke-width: 10;
}

.svg-wT36P9vel0{
  /*
  Used In:
  SF_3_vor_4.svg, elements.2.elements.2.attributes.stroke-linecap
  SF_3_vor_beispiel.svg, elements.2.elements.2.attributes.stroke-linecap
  SF_3_vor_beispiel.svg, elements.2.elements.3.attributes.stroke-linecap
  SF_3_vor_beispiel.svg, elements.2.elements.4.attributes.stroke-linecap
  SF_3_vor_beispiel.svg, elements.2.elements.5.attributes.stroke-linecap
  gm_2_10_1.svg, elements.2.elements.0.attributes.stroke-linecap
  gm_2_10_1.svg, elements.2.elements.1.attributes.stroke-linecap
  gm_2_10_1.svg, elements.2.elements.2.attributes.stroke-linecap
  gm_2_10_1.svg, elements.2.elements.3.attributes.stroke-linecap
  gm_2_10_2.svg, elements.2.elements.0.elements.0.attributes.stroke-linecap
  gm_2_10_2.svg, elements.2.elements.0.elements.1.attributes.stroke-linecap
  gm_2_10_2.svg, elements.2.elements.0.elements.2.attributes.stroke-linecap
  gm_2_10_2.svg, elements.2.elements.0.elements.3.attributes.stroke-linecap
  gm_2_10_3.svg, elements.2.elements.0.attributes.stroke-linecap
  gm_2_10_3.svg, elements.2.elements.1.attributes.stroke-linecap
  gm_2_10_3.svg, elements.2.elements.2.attributes.stroke-linecap
  gm_2_10_3.svg, elements.2.elements.3.attributes.stroke-linecap
  gm_2_10_4.svg, elements.2.elements.0.attributes.stroke-linecap
  gm_2_10_4.svg, elements.2.elements.1.attributes.stroke-linecap
  gm_2_10_4.svg, elements.2.elements.2.attributes.stroke-linecap
  gm_2_10_4.svg, elements.2.elements.3.attributes.stroke-linecap
  gm_2_10_4.svg, elements.2.elements.4.attributes.stroke-linecap
  gm_2_10_gesamt.svg, elements.2.elements.0.elements.0.attributes.stroke-linecap
  gm_2_10_gesamt.svg, elements.2.elements.0.elements.1.attributes.stroke-linecap
  gm_2_10_gesamt.svg, elements.2.elements.0.elements.2.attributes.stroke-linecap
  gm_2_10_gesamt.svg, elements.2.elements.0.elements.3.attributes.stroke-linecap
  gm_2_10_gesamt.svg, elements.2.elements.0.elements.4.attributes.stroke-linecap
  gm_2_10_gesamt.svg, elements.2.elements.0.elements.5.attributes.stroke-linecap
  gm_2_10_vor.svg, elements.2.elements.0.elements.0.attributes.stroke-linecap
  gm_2_10_vor.svg, elements.2.elements.0.elements.1.attributes.stroke-linecap
  gm_2_10_vor.svg, elements.2.elements.0.elements.2.attributes.stroke-linecap
  gm_2_10_vor.svg, elements.2.elements.0.elements.3.attributes.stroke-linecap
  gm_2_11_1.svg, elements.2.elements.6.attributes.stroke-linecap
  gm_2_11_1.svg, elements.2.elements.7.attributes.stroke-linecap
  gm_2_11_1.svg, elements.2.elements.8.attributes.stroke-linecap
  gm_2_11_1.svg, elements.2.elements.9.attributes.stroke-linecap
  gm_2_11_2.svg, elements.2.elements.0.elements.0.attributes.stroke-linecap
  gm_2_11_2.svg, elements.2.elements.0.elements.1.attributes.stroke-linecap
  gm_2_11_2.svg, elements.2.elements.0.elements.3.attributes.stroke-linecap
  gm_2_11_2.svg, elements.2.elements.0.elements.4.attributes.stroke-linecap
  gm_2_11_3.svg, elements.2.elements.0.elements.4.attributes.stroke-linecap
  gm_2_11_3.svg, elements.2.elements.0.elements.5.attributes.stroke-linecap
  gm_2_11_3.svg, elements.2.elements.0.elements.6.attributes.stroke-linecap
  gm_2_11_3.svg, elements.2.elements.0.elements.7.attributes.stroke-linecap
  gm_2_11_3.svg, elements.2.elements.0.elements.9.attributes.stroke-linecap
  gm_2_11_4.svg, elements.2.elements.6.attributes.stroke-linecap
  gm_2_11_4.svg, elements.2.elements.7.attributes.stroke-linecap
  gm_2_11_4.svg, elements.2.elements.8.attributes.stroke-linecap
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.6.attributes.stroke-linecap
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.7.attributes.stroke-linecap
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.8.attributes.stroke-linecap
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.9.attributes.stroke-linecap
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.12.attributes.stroke-linecap
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.13.attributes.stroke-linecap
  gm_2_11_vor.svg, elements.2.elements.0.elements.8.attributes.stroke-linecap
  gm_2_12_1.svg, elements.2.elements.0.attributes.stroke-linecap
  gm_2_12_1.svg, elements.2.elements.1.attributes.stroke-linecap
  gm_2_12_1.svg, elements.2.elements.2.attributes.stroke-linecap
  gm_2_12_1.svg, elements.2.elements.3.attributes.stroke-linecap
  gm_2_12_1.svg, elements.2.elements.4.attributes.stroke-linecap
  gm_2_12_2.svg, elements.2.elements.0.attributes.stroke-linecap
  gm_2_12_2.svg, elements.2.elements.1.attributes.stroke-linecap
  gm_2_12_2.svg, elements.2.elements.2.attributes.stroke-linecap
  gm_2_12_2.svg, elements.2.elements.3.attributes.stroke-linecap
  gm_2_12_2.svg, elements.2.elements.4.attributes.stroke-linecap
  gm_2_12_2.svg, elements.2.elements.5.attributes.stroke-linecap
  gm_2_12_3.svg, elements.2.elements.0.attributes.stroke-linecap
  gm_2_12_3.svg, elements.2.elements.1.attributes.stroke-linecap
  gm_2_12_3.svg, elements.2.elements.2.attributes.stroke-linecap
  gm_2_12_3.svg, elements.2.elements.3.attributes.stroke-linecap
  gm_2_12_3.svg, elements.2.elements.4.attributes.stroke-linecap
  gm_2_12_4.svg, elements.2.elements.0.attributes.stroke-linecap
  gm_2_12_4.svg, elements.2.elements.1.attributes.stroke-linecap
  gm_2_12_4.svg, elements.2.elements.2.attributes.stroke-linecap
  gm_2_12_4.svg, elements.2.elements.3.attributes.stroke-linecap
  gm_2_12_gesamt.svg, elements.2.elements.0.attributes.stroke-linecap
  gm_2_12_gesamt.svg, elements.2.elements.1.attributes.stroke-linecap
  gm_2_12_gesamt.svg, elements.2.elements.2.attributes.stroke-linecap
  gm_2_12_gesamt.svg, elements.2.elements.3.attributes.stroke-linecap
  gm_2_12_gesamt.svg, elements.2.elements.4.attributes.stroke-linecap
  gm_2_12_gesamt.svg, elements.2.elements.5.attributes.stroke-linecap
  gm_2_12_vor.svg, elements.2.elements.0.attributes.stroke-linecap
  gm_2_12_vor.svg, elements.2.elements.1.attributes.stroke-linecap
  gm_2_12_vor.svg, elements.2.elements.2.attributes.stroke-linecap
  gm_2_12_vor.svg, elements.2.elements.3.attributes.stroke-linecap
  gm_2_1_1.svg, elements.2.elements.0.attributes.stroke-linecap
  gm_2_1_2.svg, elements.2.elements.0.attributes.stroke-linecap
  gm_2_1_3.svg, elements.2.elements.2.attributes.stroke-linecap
  gm_2_1_4.svg, elements.2.elements.0.attributes.stroke-linecap
  gm_2_1_gesamt.svg, elements.2.elements.5.attributes.stroke-linecap
  gm_2_1_gesamt.svg, elements.2.elements.6.attributes.stroke-linecap
  gm_2_1_vor.svg, elements.2.elements.3.attributes.stroke-linecap
  gm_2_2_1.svg, elements.2.elements.0.elements.1.attributes.stroke-linecap
  gm_2_2_1.svg, elements.2.elements.0.elements.2.attributes.stroke-linecap
  gm_2_2_1.svg, elements.2.elements.0.elements.3.attributes.stroke-linecap
  gm_2_2_2.svg, elements.2.elements.0.attributes.stroke-linecap
  gm_2_2_2.svg, elements.2.elements.1.attributes.stroke-linecap
  gm_2_2_2.svg, elements.2.elements.3.attributes.stroke-linecap
  gm_2_2_2.svg, elements.2.elements.4.attributes.stroke-linecap
  gm_2_2_3.svg, elements.2.elements.0.elements.0.attributes.stroke-linecap
  gm_2_2_3.svg, elements.2.elements.0.elements.1.attributes.stroke-linecap
  gm_2_2_3.svg, elements.2.elements.0.elements.3.attributes.stroke-linecap
  gm_2_2_4.svg, elements.2.elements.0.elements.0.attributes.stroke-linecap
  gm_2_2_4.svg, elements.2.elements.0.elements.1.attributes.stroke-linecap
  gm_2_2_4.svg, elements.2.elements.0.elements.3.attributes.stroke-linecap
  gm_2_2_gesamt.svg, elements.2.elements.0.elements.1.attributes.stroke-linecap
  gm_2_2_gesamt.svg, elements.2.elements.0.elements.2.attributes.stroke-linecap
  gm_2_2_gesamt.svg, elements.2.elements.0.elements.5.attributes.stroke-linecap
  gm_2_2_gesamt.svg, elements.2.elements.0.elements.6.attributes.stroke-linecap
  gm_2_2_vor.svg, elements.2.elements.3.attributes.stroke-linecap
  gm_2_3_1.svg, elements.2.elements.0.elements.0.attributes.stroke-linecap
  gm_2_3_1.svg, elements.2.elements.0.elements.1.attributes.stroke-linecap
  gm_2_3_1.svg, elements.2.elements.0.elements.2.attributes.stroke-linecap
  gm_2_3_2.svg, elements.2.elements.0.attributes.stroke-linecap
  gm_2_3_2.svg, elements.2.elements.1.attributes.stroke-linecap
  gm_2_3_2.svg, elements.2.elements.2.attributes.stroke-linecap
  gm_2_3_3.svg, elements.2.elements.0.elements.0.attributes.stroke-linecap
  gm_2_3_3.svg, elements.2.elements.0.elements.1.attributes.stroke-linecap
  gm_2_3_3.svg, elements.2.elements.0.elements.2.attributes.stroke-linecap
  gm_2_3_4.svg, elements.2.elements.0.elements.0.attributes.stroke-linecap
  gm_2_3_4.svg, elements.2.elements.0.elements.1.attributes.stroke-linecap
  gm_2_3_4.svg, elements.2.elements.0.elements.2.attributes.stroke-linecap
  gm_2_3_gesamt.svg, elements.2.elements.0.elements.0.attributes.stroke-linecap
  gm_2_3_gesamt.svg, elements.2.elements.0.elements.1.attributes.stroke-linecap
  gm_2_3_gesamt.svg, elements.2.elements.0.elements.2.attributes.stroke-linecap
  gm_2_3_gesamt.svg, elements.2.elements.0.elements.3.attributes.stroke-linecap
  gm_2_3_gesamt.svg, elements.2.elements.0.elements.4.attributes.stroke-linecap
  gm_2_3_gesamt.svg, elements.2.elements.0.elements.5.attributes.stroke-linecap
  gm_2_3_vor.svg, elements.2.elements.0.elements.0.attributes.stroke-linecap
  gm_2_3_vor.svg, elements.2.elements.0.elements.1.attributes.stroke-linecap
  gm_2_3_vor.svg, elements.2.elements.0.elements.2.attributes.stroke-linecap
  gm_2_4_1.svg, elements.2.elements.1.attributes.stroke-linecap
  gm_2_4_1.svg, elements.2.elements.2.attributes.stroke-linecap
  gm_2_4_2.svg, elements.2.elements.0.attributes.stroke-linecap
  gm_2_4_2.svg, elements.2.elements.1.attributes.stroke-linecap
  gm_2_4_3.svg, elements.2.elements.0.attributes.stroke-linecap
  gm_2_4_3.svg, elements.2.elements.2.attributes.stroke-linecap
  gm_2_4_3.svg, elements.2.elements.3.attributes.stroke-linecap
  gm_2_4_3.svg, elements.2.elements.4.attributes.stroke-linecap
  gm_2_4_4.svg, elements.2.elements.1.attributes.stroke-linecap
  gm_2_4_4.svg, elements.2.elements.2.attributes.stroke-linecap
  gm_2_4_4.svg, elements.2.elements.3.attributes.stroke-linecap
  gm_2_4_gesamt.svg, elements.2.elements.0.attributes.stroke-linecap
  gm_2_4_gesamt.svg, elements.2.elements.1.attributes.stroke-linecap
  gm_2_4_gesamt.svg, elements.2.elements.2.attributes.stroke-linecap
  gm_2_4_vor.svg, elements.2.elements.0.elements.0.attributes.stroke-linecap
  gm_2_5_1.svg, elements.2.elements.0.attributes.stroke-linecap
  gm_2_5_1.svg, elements.2.elements.1.attributes.stroke-linecap
  gm_2_5_1.svg, elements.2.elements.2.attributes.stroke-linecap
  gm_2_5_1.svg, elements.2.elements.3.attributes.stroke-linecap
  gm_2_5_2.svg, elements.2.elements.0.attributes.stroke-linecap
  gm_2_5_2.svg, elements.2.elements.1.attributes.stroke-linecap
  gm_2_5_2.svg, elements.2.elements.2.attributes.stroke-linecap
  gm_2_5_2.svg, elements.2.elements.3.attributes.stroke-linecap
  gm_2_5_2.svg, elements.2.elements.4.attributes.stroke-linecap
  gm_2_5_3.svg, elements.2.elements.0.elements.0.attributes.stroke-linecap
  gm_2_5_3.svg, elements.2.elements.0.elements.1.attributes.stroke-linecap
  gm_2_5_3.svg, elements.2.elements.0.elements.2.attributes.stroke-linecap
  gm_2_5_3.svg, elements.2.elements.0.elements.3.attributes.stroke-linecap
  gm_2_5_4.svg, elements.2.elements.0.attributes.stroke-linecap
  gm_2_5_4.svg, elements.2.elements.1.attributes.stroke-linecap
  gm_2_5_4.svg, elements.2.elements.2.attributes.stroke-linecap
  gm_2_5_4.svg, elements.2.elements.3.attributes.stroke-linecap
  gm_2_5_4.svg, elements.2.elements.4.attributes.stroke-linecap
  gm_2_5_gesamt.svg, elements.2.elements.0.attributes.stroke-linecap
  gm_2_5_gesamt.svg, elements.2.elements.1.attributes.stroke-linecap
  gm_2_5_gesamt.svg, elements.2.elements.2.attributes.stroke-linecap
  gm_2_5_gesamt.svg, elements.2.elements.3.attributes.stroke-linecap
  gm_2_5_gesamt.svg, elements.2.elements.4.attributes.stroke-linecap
  gm_2_5_gesamt.svg, elements.2.elements.5.attributes.stroke-linecap
  gm_2_5_gesamt.svg, elements.2.elements.7.attributes.stroke-linecap
  gm_2_5_gesamt.svg, elements.2.elements.8.attributes.stroke-linecap
  gm_2_5_vor.svg, elements.2.elements.0.attributes.stroke-linecap
  gm_2_5_vor.svg, elements.2.elements.1.attributes.stroke-linecap
  gm_2_5_vor.svg, elements.2.elements.2.attributes.stroke-linecap
  gm_2_5_vor.svg, elements.2.elements.3.attributes.stroke-linecap
  gm_2_5_vor.svg, elements.2.elements.5.attributes.stroke-linecap
  gm_2_6_1.svg, elements.2.elements.0.attributes.stroke-linecap
  gm_2_6_2.svg, elements.2.elements.0.attributes.stroke-linecap
  gm_2_6_3.svg, elements.2.elements.0.attributes.stroke-linecap
  gm_2_6_4.svg, elements.2.elements.0.attributes.stroke-linecap
  gm_2_6_gesamt.svg, elements.2.elements.0.attributes.stroke-linecap
  gm_2_6_gesamt.svg, elements.2.elements.1.attributes.stroke-linecap
  gm_2_6_vor.svg, elements.2.elements.0.attributes.stroke-linecap
  gm_2_7_1.svg, elements.2.elements.0.attributes.stroke-linecap
  gm_2_7_1.svg, elements.2.elements.1.attributes.stroke-linecap
  gm_2_7_1.svg, elements.2.elements.2.attributes.stroke-linecap
  gm_2_7_2.svg, elements.2.elements.0.attributes.stroke-linecap
  gm_2_7_2.svg, elements.2.elements.1.attributes.stroke-linecap
  gm_2_7_2.svg, elements.2.elements.2.attributes.stroke-linecap
  gm_2_7_3.svg, elements.2.elements.0.elements.0.attributes.stroke-linecap
  gm_2_7_4.svg, elements.2.elements.0.attributes.stroke-linecap
  gm_2_7_4.svg, elements.2.elements.5.attributes.stroke-linecap
  gm_2_7_4.svg, elements.2.elements.6.attributes.stroke-linecap
  gm_2_7_gesamt.svg, elements.2.elements.0.attributes.stroke-linecap
  gm_2_7_gesamt.svg, elements.2.elements.1.attributes.stroke-linecap
  gm_2_7_gesamt.svg, elements.2.elements.2.attributes.stroke-linecap
  gm_2_7_gesamt.svg, elements.2.elements.3.attributes.stroke-linecap
  gm_2_7_vor.svg, elements.2.elements.0.attributes.stroke-linecap
  gm_2_7_vor.svg, elements.2.elements.1.attributes.stroke-linecap
  gm_2_7_vor.svg, elements.2.elements.2.attributes.stroke-linecap
  gm_2_8_1.svg, elements.2.elements.0.attributes.stroke-linecap
  gm_2_8_1.svg, elements.2.elements.1.attributes.stroke-linecap
  gm_2_8_1.svg, elements.2.elements.2.attributes.stroke-linecap
  gm_2_8_1.svg, elements.2.elements.3.attributes.stroke-linecap
  gm_2_8_1.svg, elements.2.elements.4.attributes.stroke-linecap
  gm_2_8_1.svg, elements.2.elements.5.attributes.stroke-linecap
  gm_2_8_1.svg, elements.2.elements.6.attributes.stroke-linecap
  gm_2_8_2.svg, elements.2.elements.0.elements.0.attributes.stroke-linecap
  gm_2_8_2.svg, elements.2.elements.0.elements.1.attributes.stroke-linecap
  gm_2_8_2.svg, elements.2.elements.0.elements.2.attributes.stroke-linecap
  gm_2_8_2.svg, elements.2.elements.0.elements.3.attributes.stroke-linecap
  gm_2_8_2.svg, elements.2.elements.0.elements.4.attributes.stroke-linecap
  gm_2_8_2.svg, elements.2.elements.0.elements.5.attributes.stroke-linecap
  gm_2_8_3.svg, elements.2.elements.0.attributes.stroke-linecap
  gm_2_8_3.svg, elements.2.elements.1.attributes.stroke-linecap
  gm_2_8_3.svg, elements.2.elements.2.attributes.stroke-linecap
  gm_2_8_3.svg, elements.2.elements.3.attributes.stroke-linecap
  gm_2_8_3.svg, elements.2.elements.4.attributes.stroke-linecap
  gm_2_8_3.svg, elements.2.elements.5.attributes.stroke-linecap
  gm_2_8_4.svg, elements.2.elements.0.elements.0.attributes.stroke-linecap
  gm_2_8_4.svg, elements.2.elements.0.elements.1.attributes.stroke-linecap
  gm_2_8_4.svg, elements.2.elements.0.elements.2.attributes.stroke-linecap
  gm_2_8_4.svg, elements.2.elements.0.elements.3.attributes.stroke-linecap
  gm_2_8_4.svg, elements.2.elements.0.elements.4.attributes.stroke-linecap
  gm_2_8_gesamt.svg, elements.2.elements.0.elements.0.attributes.stroke-linecap
  gm_2_8_gesamt.svg, elements.2.elements.0.elements.1.attributes.stroke-linecap
  gm_2_8_gesamt.svg, elements.2.elements.0.elements.2.attributes.stroke-linecap
  gm_2_8_gesamt.svg, elements.2.elements.0.elements.3.attributes.stroke-linecap
  gm_2_8_gesamt.svg, elements.2.elements.0.elements.4.attributes.stroke-linecap
  gm_2_8_gesamt.svg, elements.2.elements.0.elements.5.attributes.stroke-linecap
  gm_2_8_gesamt.svg, elements.2.elements.0.elements.6.attributes.stroke-linecap
  gm_2_8_gesamt.svg, elements.2.elements.0.elements.7.attributes.stroke-linecap
  gm_2_8_vor.svg, elements.2.elements.0.elements.0.attributes.stroke-linecap
  gm_2_8_vor.svg, elements.2.elements.0.elements.1.attributes.stroke-linecap
  gm_2_8_vor.svg, elements.2.elements.0.elements.2.attributes.stroke-linecap
  gm_2_8_vor.svg, elements.2.elements.0.elements.3.attributes.stroke-linecap
  gm_2_8_vor.svg, elements.2.elements.0.elements.4.attributes.stroke-linecap
  gm_2_9_1.svg, elements.2.elements.0.attributes.stroke-linecap
  gm_2_9_1.svg, elements.2.elements.1.attributes.stroke-linecap
  gm_2_9_1.svg, elements.2.elements.4.attributes.stroke-linecap
  gm_2_9_2.svg, elements.2.elements.0.attributes.stroke-linecap
  gm_2_9_2.svg, elements.2.elements.1.attributes.stroke-linecap
  gm_2_9_2.svg, elements.2.elements.2.attributes.stroke-linecap
  gm_2_9_3.svg, elements.2.elements.0.attributes.stroke-linecap
  gm_2_9_3.svg, elements.2.elements.1.attributes.stroke-linecap
  gm_2_9_3.svg, elements.2.elements.2.attributes.stroke-linecap
  gm_2_9_4.svg, elements.2.elements.0.attributes.stroke-linecap
  gm_2_9_4.svg, elements.2.elements.1.attributes.stroke-linecap
  gm_2_9_4.svg, elements.2.elements.2.attributes.stroke-linecap
  gm_2_9_gesamt.svg, elements.2.elements.0.attributes.stroke-linecap
  gm_2_9_gesamt.svg, elements.2.elements.1.attributes.stroke-linecap
  gm_2_9_gesamt.svg, elements.2.elements.6.attributes.stroke-linecap
  gm_2_9_gesamt.svg, elements.2.elements.7.attributes.stroke-linecap
  gm_2_9_vor.svg, elements.2.elements.0.attributes.stroke-linecap
  gm_2_9_vor.svg, elements.2.elements.1.attributes.stroke-linecap
  gm_2_9_vor.svg, elements.2.elements.4.attributes.stroke-linecap
  ptv_1_1.svg, elements.2.elements.2.attributes.stroke-linecap
  ptv_1_2.svg, elements.2.elements.2.attributes.stroke-linecap
  ptv_1_3.svg, elements.2.elements.2.attributes.stroke-linecap
  ptv_2_1.svg, elements.2.elements.1.attributes.stroke-linecap
  ptv_2_2.svg, elements.2.elements.0.elements.0.attributes.stroke-linecap
  ptv_2_2.svg, elements.2.elements.0.elements.1.attributes.stroke-linecap
  ptv_2_2.svg, elements.2.elements.0.elements.2.attributes.stroke-linecap
  ptv_2_2.svg, elements.2.elements.0.elements.3.attributes.stroke-linecap
  ptv_2_2.svg, elements.2.elements.2.attributes.stroke-linecap
  ptv_2_3.svg, elements.2.elements.1.attributes.stroke-linecap
  ptv_2_4.svg, elements.2.elements.2.attributes.stroke-linecap
  ptv_4_1.svg, elements.2.elements.5.attributes.stroke-linecap
  ptv_4_1.svg, elements.2.elements.12.attributes.stroke-linecap
  ptv_4_1.svg, elements.2.elements.16.elements.0.attributes.stroke-linecap
  ptv_4_2.svg, elements.2.elements.5.attributes.stroke-linecap
  ptv_4_2.svg, elements.2.elements.12.attributes.stroke-linecap
  ptv_4_2.svg, elements.2.elements.16.elements.0.attributes.stroke-linecap
  ptv_4_3.svg, elements.2.elements.5.attributes.stroke-linecap
  ptv_4_3.svg, elements.2.elements.12.attributes.stroke-linecap
  ptv_4_3.svg, elements.2.elements.16.elements.0.attributes.stroke-linecap
  ptv_4_4.svg, elements.2.elements.12.elements.0.attributes.stroke-linecap
  rd_1_1_1.svg, elements.2.elements.0.attributes.stroke-linecap
  rd_1_1_1.svg, elements.2.elements.1.attributes.stroke-linecap
  rd_1_1_2.svg, elements.2.elements.0.attributes.stroke-linecap
  rd_1_1_2.svg, elements.2.elements.1.attributes.stroke-linecap
  rd_1_1_3.svg, elements.2.elements.0.attributes.stroke-linecap
  rd_1_1_3.svg, elements.2.elements.1.attributes.stroke-linecap
  rd_1_1_vor.svg, elements.2.elements.2.attributes.stroke-linecap
  rd_1_2_3.svg, elements.2.elements.1.attributes.stroke-linecap
  rd_1_2_3.svg, elements.2.elements.3.attributes.stroke-linecap
  rd_1_2_3.svg, elements.2.elements.5.attributes.stroke-linecap
  rd_1_2_3.svg, elements.2.elements.7.attributes.stroke-linecap
  rd_1_2_3.svg, elements.2.elements.9.attributes.stroke-linecap
  rd_1_2_3.svg, elements.2.elements.11.attributes.stroke-linecap
  rd_1_2_3.svg, elements.2.elements.13.attributes.stroke-linecap
  rd_1_2_3.svg, elements.2.elements.15.attributes.stroke-linecap
  rd_1_3_1.svg, elements.2.elements.1.attributes.stroke-linecap
  rd_1_3_1.svg, elements.2.elements.3.attributes.stroke-linecap
  rd_1_3_1.svg, elements.2.elements.4.attributes.stroke-linecap
  rd_1_3_1.svg, elements.2.elements.5.attributes.stroke-linecap
  rd_1_3_2.svg, elements.2.elements.1.attributes.stroke-linecap
  rd_1_3_2.svg, elements.2.elements.3.attributes.stroke-linecap
  rd_1_3_2.svg, elements.2.elements.5.attributes.stroke-linecap
  rd_1_3_2.svg, elements.2.elements.6.attributes.stroke-linecap
  rd_1_3_2.svg, elements.2.elements.7.attributes.stroke-linecap
  rd_1_3_3.svg, elements.2.elements.1.attributes.stroke-linecap
  rd_1_3_3.svg, elements.2.elements.3.attributes.stroke-linecap
  rd_1_3_3.svg, elements.2.elements.5.attributes.stroke-linecap
  rd_1_3_3.svg, elements.2.elements.6.attributes.stroke-linecap
  rd_1_3_3.svg, elements.2.elements.7.attributes.stroke-linecap
  rd_1_3_vor.svg, elements.2.elements.3.attributes.stroke-linecap
  rd_1_3_vor.svg, elements.2.elements.4.attributes.stroke-linecap
  rd_1_3_vor.svg, elements.2.elements.5.attributes.stroke-linecap
  rd_1_3_vor.svg, elements.2.elements.6.attributes.stroke-linecap
  rd_1_4_1.svg, elements.2.elements.0.attributes.stroke-linecap
  rd_1_4_1.svg, elements.2.elements.1.attributes.stroke-linecap
  rd_1_4_1.svg, elements.2.elements.2.attributes.stroke-linecap
  rd_1_4_1.svg, elements.2.elements.3.attributes.stroke-linecap
  rd_1_4_1.svg, elements.2.elements.4.attributes.stroke-linecap
  rd_1_4_1.svg, elements.2.elements.6.attributes.stroke-linecap
  rd_1_4_1.svg, elements.2.elements.7.attributes.stroke-linecap
  rd_1_4_2.svg, elements.2.elements.0.attributes.stroke-linecap
  rd_1_4_2.svg, elements.2.elements.2.attributes.stroke-linecap
  rd_1_4_2.svg, elements.2.elements.3.attributes.stroke-linecap
  rd_1_4_2.svg, elements.2.elements.4.attributes.stroke-linecap
  rd_1_4_2.svg, elements.2.elements.5.attributes.stroke-linecap
  rd_1_4_3.svg, elements.2.elements.6.attributes.stroke-linecap
  rd_1_4_vor.svg, elements.2.elements.1.elements.1.attributes.stroke-linecap
  rd_1_4_vor.svg, elements.2.elements.1.elements.2.attributes.stroke-linecap
  rd_1_4_vor.svg, elements.2.elements.1.elements.3.attributes.stroke-linecap
  rd_1_4_vor.svg, elements.2.elements.1.elements.4.attributes.stroke-linecap
  rd_1_beispiel.svg, elements.2.elements.0.elements.0.attributes.stroke-linecap
  rd_1_beispiel.svg, elements.2.elements.0.elements.3.attributes.stroke-linecap
  rd_1_beispiel.svg, elements.2.elements.0.elements.6.attributes.stroke-linecap
  rd_1_beispiel.svg, elements.2.elements.0.elements.7.attributes.stroke-linecap
  rd_1_beispiel.svg, elements.2.elements.0.elements.8.attributes.stroke-linecap
  rd_1_beispiel_1.svg, elements.2.elements.0.attributes.stroke-linecap
  rd_1_beispiel_1.svg, elements.2.elements.1.attributes.stroke-linecap
  rd_1_beispiel_1.svg, elements.2.elements.2.attributes.stroke-linecap
  rd_1_beispiel_1.svg, elements.2.elements.3.attributes.stroke-linecap
  rd_1_beispiel_1.svg, elements.2.elements.4.attributes.stroke-linecap
  rd_1_beispiel_2.svg, elements.2.elements.0.attributes.stroke-linecap
  rd_1_beispiel_2.svg, elements.2.elements.1.attributes.stroke-linecap
  rd_1_beispiel_2.svg, elements.2.elements.2.attributes.stroke-linecap
  rd_1_beispiel_2.svg, elements.2.elements.3.attributes.stroke-linecap
  rd_1_beispiel_2.svg, elements.2.elements.4.attributes.stroke-linecap
  rd_1_beispiel_2.svg, elements.2.elements.5.attributes.stroke-linecap
  rd_1_beispiel_2.svg, elements.2.elements.6.attributes.stroke-linecap
  rd_1_beispiel_3.svg, elements.2.elements.0.attributes.stroke-linecap
  rd_1_beispiel_3.svg, elements.2.elements.1.attributes.stroke-linecap
  rd_1_beispiel_3.svg, elements.2.elements.2.attributes.stroke-linecap
  rd_1_beispiel_3.svg, elements.2.elements.3.attributes.stroke-linecap
  rd_1_beispiel_3.svg, elements.2.elements.4.attributes.stroke-linecap
  rd_1_beispiel_3.svg, elements.2.elements.5.attributes.stroke-linecap
  rd_2_1_vor.svg, elements.2.elements.0.elements.1.attributes.stroke-linecap
  rd_2_1_vor.svg, elements.2.elements.0.elements.2.attributes.stroke-linecap
  rd_2_1_vor.svg, elements.2.elements.0.elements.3.attributes.stroke-linecap
  rd_2_1_vor.svg, elements.2.elements.2.elements.2.attributes.stroke-linecap
  rd_2_1_vor.svg, elements.2.elements.2.elements.3.attributes.stroke-linecap
  rd_2_1_vor.svg, elements.2.elements.3.elements.1.attributes.stroke-linecap
  rd_2_1_vor.svg, elements.2.elements.3.elements.2.attributes.stroke-linecap
  rd_2_1_vor.svg, elements.2.elements.4.elements.1.attributes.stroke-linecap
  rd_2_1_vor.svg, elements.2.elements.4.elements.2.attributes.stroke-linecap
  rd_2_1_vor.svg, elements.2.elements.5.elements.2.attributes.stroke-linecap
  rd_2_1_vor.svg, elements.2.elements.5.elements.3.attributes.stroke-linecap
  rd_2_1_vor.svg, elements.2.elements.5.elements.4.attributes.stroke-linecap
  rd_2_1_vor.svg, elements.2.elements.6.elements.1.attributes.stroke-linecap
  rd_2_1_vor.svg, elements.2.elements.6.elements.2.attributes.stroke-linecap
  rd_2_1_vor.svg, elements.2.elements.6.elements.3.attributes.stroke-linecap
  rd_2_1_vor.svg, elements.2.elements.7.elements.3.attributes.stroke-linecap
  rd_2_1_vor.svg, elements.2.elements.7.elements.4.attributes.stroke-linecap
  rd_2_1_vor.svg, elements.2.elements.7.elements.5.attributes.stroke-linecap
  rd_2_1_vor.svg, elements.2.elements.8.elements.1.attributes.stroke-linecap
  rd_2_1_vor.svg, elements.2.elements.8.elements.2.attributes.stroke-linecap
  rd_2_1_vor.svg, elements.2.elements.9.elements.0.elements.1.attributes.stroke-linecap
  rd_2_1_vor.svg, elements.2.elements.9.elements.0.elements.2.attributes.stroke-linecap
  rd_2_1_vor.svg, elements.2.elements.9.elements.1.elements.1.attributes.stroke-linecap
  rd_2_1_vor.svg, elements.2.elements.9.elements.1.elements.2.attributes.stroke-linecap
  rd_2_1_vor.svg, elements.2.elements.9.elements.1.elements.3.attributes.stroke-linecap
  rd_2_1_vor.svg, elements.2.elements.9.elements.2.elements.3.attributes.stroke-linecap
  rd_2_1_vor.svg, elements.2.elements.9.elements.2.elements.4.attributes.stroke-linecap
  rd_2_1_vor.svg, elements.2.elements.9.elements.2.elements.5.attributes.stroke-linecap
  rd_2_1_vor.svg, elements.2.elements.10.elements.1.attributes.stroke-linecap
  rd_2_1_vor.svg, elements.2.elements.10.elements.2.attributes.stroke-linecap
  rd_2_1_vor.svg, elements.2.elements.10.elements.3.attributes.stroke-linecap
  rd_2_1_vor.svg, elements.2.elements.11.elements.3.attributes.stroke-linecap
  rd_2_1_vor.svg, elements.2.elements.11.elements.4.attributes.stroke-linecap
  rd_2_1_vor.svg, elements.2.elements.11.elements.5.attributes.stroke-linecap
  rd_2_1_vor.svg, elements.2.elements.12.elements.2.attributes.stroke-linecap
  rd_2_1_vor.svg, elements.2.elements.12.elements.3.attributes.stroke-linecap
  rd_2_1_vor.svg, elements.2.elements.12.elements.4.attributes.stroke-linecap
  rd_2_1_vor.svg, elements.2.elements.13.elements.2.attributes.stroke-linecap
  rd_2_1_vor.svg, elements.2.elements.13.elements.3.attributes.stroke-linecap
  rd_2_1_vor.svg, elements.2.elements.13.elements.4.attributes.stroke-linecap
  rd_2_1_vor.svg, elements.2.elements.14.elements.3.attributes.stroke-linecap
  rd_2_1_vor.svg, elements.2.elements.14.elements.4.attributes.stroke-linecap
  rd_2_1_vor.svg, elements.2.elements.14.elements.5.attributes.stroke-linecap
  rd_2_2_vor.svg, elements.2.elements.0.elements.1.attributes.stroke-linecap
  rd_2_2_vor.svg, elements.2.elements.0.elements.2.attributes.stroke-linecap
  rd_2_2_vor.svg, elements.2.elements.1.elements.1.attributes.stroke-linecap
  rd_2_2_vor.svg, elements.2.elements.1.elements.2.attributes.stroke-linecap
  rd_2_2_vor.svg, elements.2.elements.2.elements.1.attributes.stroke-linecap
  rd_2_2_vor.svg, elements.2.elements.2.elements.2.attributes.stroke-linecap
  rd_2_2_vor.svg, elements.2.elements.3.elements.1.attributes.stroke-linecap
  rd_2_2_vor.svg, elements.2.elements.3.elements.2.attributes.stroke-linecap
  rd_2_2_vor.svg, elements.2.elements.4.elements.1.attributes.stroke-linecap
  rd_2_2_vor.svg, elements.2.elements.4.elements.2.attributes.stroke-linecap
  rd_2_2_vor.svg, elements.2.elements.5.elements.2.attributes.stroke-linecap
  rd_2_2_vor.svg, elements.2.elements.5.elements.3.attributes.stroke-linecap
  rd_2_2_vor.svg, elements.2.elements.6.elements.2.attributes.stroke-linecap
  rd_2_2_vor.svg, elements.2.elements.6.elements.3.attributes.stroke-linecap
  rd_2_2_vor.svg, elements.2.elements.6.elements.4.attributes.stroke-linecap
  rd_2_2_vor.svg, elements.2.elements.7.elements.2.attributes.stroke-linecap
  rd_2_2_vor.svg, elements.2.elements.7.elements.3.attributes.stroke-linecap
  rd_2_2_vor.svg, elements.2.elements.7.elements.4.attributes.stroke-linecap
  rd_2_2_vor.svg, elements.2.elements.8.elements.2.attributes.stroke-linecap
  rd_2_2_vor.svg, elements.2.elements.8.elements.3.attributes.stroke-linecap
  rd_2_2_vor.svg, elements.2.elements.8.elements.4.attributes.stroke-linecap
  rd_2_2_vor.svg, elements.2.elements.9.elements.2.attributes.stroke-linecap
  rd_2_2_vor.svg, elements.2.elements.9.elements.3.attributes.stroke-linecap
  rd_2_2_vor.svg, elements.2.elements.9.elements.4.attributes.stroke-linecap
  rd_2_2_vor.svg, elements.2.elements.10.elements.2.attributes.stroke-linecap
  rd_2_2_vor.svg, elements.2.elements.10.elements.3.attributes.stroke-linecap
  rd_2_2_vor.svg, elements.2.elements.10.elements.4.attributes.stroke-linecap
  rd_2_2_vor.svg, elements.2.elements.11.elements.3.attributes.stroke-linecap
  rd_2_2_vor.svg, elements.2.elements.11.elements.4.attributes.stroke-linecap
  rd_2_2_vor.svg, elements.2.elements.11.elements.5.attributes.stroke-linecap
  rd_2_2_vor.svg, elements.2.elements.12.elements.3.attributes.stroke-linecap
  rd_2_2_vor.svg, elements.2.elements.12.elements.4.attributes.stroke-linecap
  rd_2_2_vor.svg, elements.2.elements.12.elements.5.attributes.stroke-linecap
  rd_2_3_vor.svg, elements.2.elements.0.elements.1.attributes.stroke-linecap
  rd_2_3_vor.svg, elements.2.elements.1.elements.2.attributes.stroke-linecap
  rd_2_3_vor.svg, elements.2.elements.1.elements.3.attributes.stroke-linecap
  rd_2_3_vor.svg, elements.2.elements.1.elements.4.attributes.stroke-linecap
  rd_2_3_vor.svg, elements.2.elements.2.elements.2.attributes.stroke-linecap
  rd_2_3_vor.svg, elements.2.elements.2.elements.3.attributes.stroke-linecap
  rd_2_3_vor.svg, elements.2.elements.2.elements.4.attributes.stroke-linecap
  rd_2_3_vor.svg, elements.2.elements.3.elements.2.attributes.stroke-linecap
  rd_2_3_vor.svg, elements.2.elements.3.elements.3.attributes.stroke-linecap
  rd_2_3_vor.svg, elements.2.elements.4.elements.1.attributes.stroke-linecap
  rd_2_3_vor.svg, elements.2.elements.4.elements.2.attributes.stroke-linecap
  rd_2_3_vor.svg, elements.2.elements.5.elements.2.attributes.stroke-linecap
  rd_2_3_vor.svg, elements.2.elements.5.elements.3.attributes.stroke-linecap
  rd_2_3_vor.svg, elements.2.elements.6.elements.2.attributes.stroke-linecap
  rd_2_3_vor.svg, elements.2.elements.6.elements.3.attributes.stroke-linecap
  rd_2_3_vor.svg, elements.2.elements.7.elements.1.attributes.stroke-linecap
  rd_2_3_vor.svg, elements.2.elements.7.elements.2.attributes.stroke-linecap
  rd_2_3_vor.svg, elements.2.elements.8.elements.2.attributes.stroke-linecap
  rd_2_3_vor.svg, elements.2.elements.8.elements.3.attributes.stroke-linecap
  rd_2_3_vor.svg, elements.2.elements.8.elements.4.attributes.stroke-linecap
  rd_2_3_vor.svg, elements.2.elements.9.elements.2.attributes.stroke-linecap
  rd_2_3_vor.svg, elements.2.elements.9.elements.3.attributes.stroke-linecap
  rd_2_3_vor.svg, elements.2.elements.10.elements.1.attributes.stroke-linecap
  rd_2_3_vor.svg, elements.2.elements.10.elements.2.attributes.stroke-linecap
  rd_2_3_vor.svg, elements.2.elements.10.elements.3.attributes.stroke-linecap
  rd_2_3_vor.svg, elements.2.elements.11.elements.3.attributes.stroke-linecap
  rd_2_3_vor.svg, elements.2.elements.11.elements.4.attributes.stroke-linecap
  rd_2_3_vor.svg, elements.2.elements.11.elements.5.attributes.stroke-linecap
  rd_2_3_vor.svg, elements.2.elements.12.elements.3.attributes.stroke-linecap
  rd_2_3_vor.svg, elements.2.elements.12.elements.4.attributes.stroke-linecap
  rd_2_3_vor.svg, elements.2.elements.12.elements.5.attributes.stroke-linecap
  rd_2_4_vor.svg, elements.2.elements.0.elements.2.attributes.stroke-linecap
  rd_2_4_vor.svg, elements.2.elements.0.elements.3.attributes.stroke-linecap
  rd_2_4_vor.svg, elements.2.elements.0.elements.4.attributes.stroke-linecap
  rd_2_4_vor.svg, elements.2.elements.1.elements.3.attributes.stroke-linecap
  rd_2_4_vor.svg, elements.2.elements.1.elements.4.attributes.stroke-linecap
  rd_2_4_vor.svg, elements.2.elements.1.elements.5.attributes.stroke-linecap
  rd_2_4_vor.svg, elements.2.elements.2.elements.1.attributes.stroke-linecap
  rd_2_4_vor.svg, elements.2.elements.2.elements.2.attributes.stroke-linecap
  rd_2_4_vor.svg, elements.2.elements.3.elements.2.attributes.stroke-linecap
  rd_2_4_vor.svg, elements.2.elements.3.elements.3.attributes.stroke-linecap
  rd_2_4_vor.svg, elements.2.elements.3.elements.4.attributes.stroke-linecap
  rd_2_4_vor.svg, elements.2.elements.4.elements.1.attributes.stroke-linecap
  rd_2_4_vor.svg, elements.2.elements.4.elements.2.attributes.stroke-linecap
  rd_2_4_vor.svg, elements.2.elements.5.elements.1.attributes.stroke-linecap
  rd_2_4_vor.svg, elements.2.elements.5.elements.2.attributes.stroke-linecap
  rd_2_4_vor.svg, elements.2.elements.6.elements.2.attributes.stroke-linecap
  rd_2_4_vor.svg, elements.2.elements.6.elements.3.attributes.stroke-linecap
  rd_2_4_vor.svg, elements.2.elements.6.elements.4.attributes.stroke-linecap
  rd_2_4_vor.svg, elements.2.elements.7.elements.2.attributes.stroke-linecap
  rd_2_4_vor.svg, elements.2.elements.7.elements.3.attributes.stroke-linecap
  rd_2_4_vor.svg, elements.2.elements.7.elements.4.attributes.stroke-linecap
  rd_2_4_vor.svg, elements.2.elements.8.elements.2.attributes.stroke-linecap
  rd_2_4_vor.svg, elements.2.elements.8.elements.3.attributes.stroke-linecap
  rd_2_4_vor.svg, elements.2.elements.8.elements.4.attributes.stroke-linecap
  rd_2_4_vor.svg, elements.2.elements.9.elements.3.attributes.stroke-linecap
  rd_2_4_vor.svg, elements.2.elements.9.elements.4.attributes.stroke-linecap
  rd_2_4_vor.svg, elements.2.elements.9.elements.5.attributes.stroke-linecap
  rd_2_5_vor.svg, elements.2.elements.0.elements.2.attributes.stroke-linecap
  rd_2_5_vor.svg, elements.2.elements.0.elements.3.attributes.stroke-linecap
  rd_2_5_vor.svg, elements.2.elements.0.elements.4.attributes.stroke-linecap
  rd_2_5_vor.svg, elements.2.elements.1.elements.2.attributes.stroke-linecap
  rd_2_5_vor.svg, elements.2.elements.1.elements.3.attributes.stroke-linecap
  rd_2_5_vor.svg, elements.2.elements.1.elements.4.attributes.stroke-linecap
  rd_2_5_vor.svg, elements.2.elements.2.elements.1.attributes.stroke-linecap
  rd_2_5_vor.svg, elements.2.elements.2.elements.2.attributes.stroke-linecap
  rd_2_5_vor.svg, elements.2.elements.3.elements.2.attributes.stroke-linecap
  rd_2_5_vor.svg, elements.2.elements.3.elements.3.attributes.stroke-linecap
  rd_2_5_vor.svg, elements.2.elements.4.elements.3.attributes.stroke-linecap
  rd_2_5_vor.svg, elements.2.elements.4.elements.4.attributes.stroke-linecap
  rd_2_5_vor.svg, elements.2.elements.4.elements.5.attributes.stroke-linecap
  rd_2_5_vor.svg, elements.2.elements.5.elements.2.attributes.stroke-linecap
  rd_2_5_vor.svg, elements.2.elements.5.elements.3.attributes.stroke-linecap
  rd_2_5_vor.svg, elements.2.elements.6.elements.2.attributes.stroke-linecap
  rd_2_5_vor.svg, elements.2.elements.6.elements.3.attributes.stroke-linecap
  rd_2_5_vor.svg, elements.2.elements.6.elements.4.attributes.stroke-linecap
  rd_2_5_vor.svg, elements.2.elements.7.elements.2.attributes.stroke-linecap
  rd_2_5_vor.svg, elements.2.elements.7.elements.3.attributes.stroke-linecap
  rd_2_5_vor.svg, elements.2.elements.7.elements.4.attributes.stroke-linecap
  rd_2_5_vor.svg, elements.2.elements.8.elements.2.attributes.stroke-linecap
  rd_2_5_vor.svg, elements.2.elements.8.elements.3.attributes.stroke-linecap
  rd_2_5_vor.svg, elements.2.elements.8.elements.4.attributes.stroke-linecap
  rd_2_5_vor.svg, elements.2.elements.9.elements.3.attributes.stroke-linecap
  rd_2_5_vor.svg, elements.2.elements.9.elements.4.attributes.stroke-linecap
  rd_2_5_vor.svg, elements.2.elements.9.elements.5.attributes.stroke-linecap
  rd_2_intro.svg, elements.2.elements.0.elements.1.attributes.stroke-linecap
  rd_2_intro.svg, elements.2.elements.0.elements.2.attributes.stroke-linecap
  rd_2_intro.svg, elements.2.elements.1.elements.1.attributes.stroke-linecap
  rd_2_intro.svg, elements.2.elements.1.elements.2.attributes.stroke-linecap
  rd_2_intro.svg, elements.2.elements.2.elements.1.attributes.stroke-linecap
  rd_2_intro.svg, elements.2.elements.2.elements.2.attributes.stroke-linecap
  rd_2_intro.svg, elements.2.elements.2.elements.3.attributes.stroke-linecap
  rd_2_intro.svg, elements.2.elements.3.elements.1.attributes.stroke-linecap
  rd_2_intro.svg, elements.2.elements.3.elements.2.attributes.stroke-linecap
  rd_2_intro.svg, elements.2.elements.3.elements.3.attributes.stroke-linecap
  rd_2_intro.svg, elements.2.elements.4.elements.2.attributes.stroke-linecap
  rd_2_intro.svg, elements.2.elements.4.elements.3.attributes.stroke-linecap
  rd_2_intro.svg, elements.2.elements.5.elements.1.attributes.stroke-linecap
  rd_2_intro.svg, elements.2.elements.5.elements.2.attributes.stroke-linecap
  rd_2_intro.svg, elements.2.elements.6.elements.2.attributes.stroke-linecap
  rd_2_intro.svg, elements.2.elements.6.elements.3.attributes.stroke-linecap
  rd_2_intro.svg, elements.2.elements.7.elements.1.attributes.stroke-linecap
  rd_2_intro.svg, elements.2.elements.8.elements.1.attributes.stroke-linecap
  rd_2_intro.svg, elements.2.elements.9.elements.2.attributes.stroke-linecap
  rd_2_intro.svg, elements.2.elements.9.elements.3.attributes.stroke-linecap
  rd_2_intro.svg, elements.2.elements.9.elements.4.attributes.stroke-linecap
  rd_2_intro.svg, elements.2.elements.10.elements.1.attributes.stroke-linecap
  rd_2_intro.svg, elements.2.elements.10.elements.2.attributes.stroke-linecap
  rd_2_intro.svg, elements.2.elements.11.elements.1.attributes.stroke-linecap
  rd_2_intro.svg, elements.2.elements.11.elements.2.attributes.stroke-linecap
  rd_2_intro.svg, elements.2.elements.12.elements.2.attributes.stroke-linecap
  rd_2_intro.svg, elements.2.elements.12.elements.3.attributes.stroke-linecap
  rd_2_intro.svg, elements.2.elements.12.elements.4.attributes.stroke-linecap
  rd_2_intro.svg, elements.2.elements.13.elements.2.attributes.stroke-linecap
  rd_2_intro.svg, elements.2.elements.13.elements.3.attributes.stroke-linecap
  rd_2_intro.svg, elements.2.elements.14.elements.1.attributes.stroke-linecap
  rd_2_intro.svg, elements.2.elements.14.elements.2.attributes.stroke-linecap
  rd_2_intro.svg, elements.2.elements.15.elements.2.attributes.stroke-linecap
  rd_2_intro.svg, elements.2.elements.15.elements.3.attributes.stroke-linecap
  rd_2_intro.svg, elements.2.elements.16.elements.2.attributes.stroke-linecap
  rd_2_intro.svg, elements.2.elements.16.elements.3.attributes.stroke-linecap
  rd_2_intro.svg, elements.2.elements.17.elements.2.attributes.stroke-linecap
  rd_2_intro.svg, elements.2.elements.17.elements.3.attributes.stroke-linecap
  rd_2_intro.svg, elements.2.elements.18.elements.3.attributes.stroke-linecap
  rd_2_intro.svg, elements.2.elements.18.elements.4.attributes.stroke-linecap
  rd_2_intro.svg, elements.2.elements.18.elements.5.attributes.stroke-linecap
  */
  stroke-linecap: round;
}

.svg-T9c56Uvo3I{
  /*
  Used In:
  gm_2_10_1.svg, elements.2.elements.0.attributes.stroke
  gm_2_10_1.svg, elements.2.elements.1.attributes.stroke
  gm_2_10_1.svg, elements.2.elements.2.attributes.stroke
  gm_2_10_1.svg, elements.2.elements.3.attributes.stroke
  gm_2_10_2.svg, elements.2.elements.0.elements.0.attributes.stroke
  gm_2_10_2.svg, elements.2.elements.0.elements.1.attributes.stroke
  gm_2_10_2.svg, elements.2.elements.0.elements.2.attributes.stroke
  gm_2_10_2.svg, elements.2.elements.0.elements.3.attributes.stroke
  gm_2_10_3.svg, elements.2.elements.0.attributes.stroke
  gm_2_10_3.svg, elements.2.elements.1.attributes.stroke
  gm_2_10_3.svg, elements.2.elements.2.attributes.stroke
  gm_2_10_3.svg, elements.2.elements.3.attributes.stroke
  gm_2_10_4.svg, elements.2.elements.0.attributes.stroke
  gm_2_10_4.svg, elements.2.elements.1.attributes.stroke
  gm_2_10_4.svg, elements.2.elements.2.attributes.stroke
  gm_2_10_4.svg, elements.2.elements.3.attributes.stroke
  gm_2_10_4.svg, elements.2.elements.4.attributes.stroke
  gm_2_10_gesamt.svg, elements.2.elements.0.elements.0.attributes.stroke
  gm_2_10_gesamt.svg, elements.2.elements.0.elements.1.attributes.stroke
  gm_2_10_gesamt.svg, elements.2.elements.0.elements.2.attributes.stroke
  gm_2_10_gesamt.svg, elements.2.elements.0.elements.3.attributes.stroke
  gm_2_10_gesamt.svg, elements.2.elements.0.elements.4.attributes.stroke
  gm_2_10_gesamt.svg, elements.2.elements.0.elements.5.attributes.stroke
  gm_2_10_vor.svg, elements.2.elements.0.elements.0.attributes.stroke
  gm_2_10_vor.svg, elements.2.elements.0.elements.1.attributes.stroke
  gm_2_10_vor.svg, elements.2.elements.0.elements.2.attributes.stroke
  gm_2_10_vor.svg, elements.2.elements.0.elements.3.attributes.stroke
  gm_2_11_1.svg, elements.2.elements.2.attributes.stroke
  gm_2_11_1.svg, elements.2.elements.3.attributes.stroke
  gm_2_11_1.svg, elements.2.elements.4.attributes.stroke
  gm_2_11_1.svg, elements.2.elements.5.attributes.stroke
  gm_2_11_1.svg, elements.2.elements.6.attributes.stroke
  gm_2_11_1.svg, elements.2.elements.7.attributes.stroke
  gm_2_11_1.svg, elements.2.elements.8.attributes.stroke
  gm_2_11_1.svg, elements.2.elements.9.attributes.stroke
  gm_2_11_2.svg, elements.2.elements.0.elements.0.attributes.stroke
  gm_2_11_2.svg, elements.2.elements.0.elements.1.attributes.stroke
  gm_2_11_2.svg, elements.2.elements.0.elements.2.attributes.stroke
  gm_2_11_2.svg, elements.2.elements.0.elements.3.attributes.stroke
  gm_2_11_2.svg, elements.2.elements.0.elements.4.attributes.stroke
  gm_2_11_3.svg, elements.2.elements.0.elements.1.attributes.stroke
  gm_2_11_3.svg, elements.2.elements.0.elements.2.attributes.stroke
  gm_2_11_3.svg, elements.2.elements.0.elements.3.attributes.stroke
  gm_2_11_3.svg, elements.2.elements.0.elements.4.attributes.stroke
  gm_2_11_3.svg, elements.2.elements.0.elements.5.attributes.stroke
  gm_2_11_3.svg, elements.2.elements.0.elements.6.attributes.stroke
  gm_2_11_3.svg, elements.2.elements.0.elements.7.attributes.stroke
  gm_2_11_3.svg, elements.2.elements.0.elements.8.attributes.stroke
  gm_2_11_3.svg, elements.2.elements.0.elements.9.attributes.stroke
  gm_2_11_4.svg, elements.2.elements.2.attributes.stroke
  gm_2_11_4.svg, elements.2.elements.3.attributes.stroke
  gm_2_11_4.svg, elements.2.elements.4.attributes.stroke
  gm_2_11_4.svg, elements.2.elements.5.attributes.stroke
  gm_2_11_4.svg, elements.2.elements.6.attributes.stroke
  gm_2_11_4.svg, elements.2.elements.7.attributes.stroke
  gm_2_11_4.svg, elements.2.elements.8.attributes.stroke
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.1.attributes.stroke
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.2.attributes.stroke
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.3.attributes.stroke
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.4.attributes.stroke
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.5.attributes.stroke
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.6.attributes.stroke
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.7.attributes.stroke
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.8.attributes.stroke
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.9.attributes.stroke
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.10.attributes.stroke
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.12.attributes.stroke
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.13.attributes.stroke
  gm_2_11_vor.svg, elements.2.elements.0.elements.1.attributes.stroke
  gm_2_11_vor.svg, elements.2.elements.0.elements.2.attributes.stroke
  gm_2_11_vor.svg, elements.2.elements.0.elements.3.attributes.stroke
  gm_2_11_vor.svg, elements.2.elements.0.elements.4.attributes.stroke
  gm_2_11_vor.svg, elements.2.elements.0.elements.5.attributes.stroke
  gm_2_11_vor.svg, elements.2.elements.0.elements.6.attributes.stroke
  gm_2_11_vor.svg, elements.2.elements.0.elements.8.attributes.stroke
  gm_2_12_1.svg, elements.2.elements.0.attributes.stroke
  gm_2_12_1.svg, elements.2.elements.1.attributes.stroke
  gm_2_12_1.svg, elements.2.elements.2.attributes.stroke
  gm_2_12_1.svg, elements.2.elements.3.attributes.stroke
  gm_2_12_1.svg, elements.2.elements.4.attributes.stroke
  gm_2_12_2.svg, elements.2.elements.0.attributes.stroke
  gm_2_12_2.svg, elements.2.elements.1.attributes.stroke
  gm_2_12_2.svg, elements.2.elements.2.attributes.stroke
  gm_2_12_2.svg, elements.2.elements.3.attributes.stroke
  gm_2_12_2.svg, elements.2.elements.4.attributes.stroke
  gm_2_12_2.svg, elements.2.elements.5.attributes.stroke
  gm_2_12_3.svg, elements.2.elements.0.attributes.stroke
  gm_2_12_3.svg, elements.2.elements.1.attributes.stroke
  gm_2_12_3.svg, elements.2.elements.2.attributes.stroke
  gm_2_12_3.svg, elements.2.elements.3.attributes.stroke
  gm_2_12_3.svg, elements.2.elements.4.attributes.stroke
  gm_2_12_4.svg, elements.2.elements.0.attributes.stroke
  gm_2_12_4.svg, elements.2.elements.1.attributes.stroke
  gm_2_12_4.svg, elements.2.elements.2.attributes.stroke
  gm_2_12_4.svg, elements.2.elements.3.attributes.stroke
  gm_2_12_gesamt.svg, elements.2.elements.0.attributes.stroke
  gm_2_12_gesamt.svg, elements.2.elements.1.attributes.stroke
  gm_2_12_gesamt.svg, elements.2.elements.2.attributes.stroke
  gm_2_12_gesamt.svg, elements.2.elements.3.attributes.stroke
  gm_2_12_gesamt.svg, elements.2.elements.4.attributes.stroke
  gm_2_12_gesamt.svg, elements.2.elements.5.attributes.stroke
  gm_2_12_vor.svg, elements.2.elements.0.attributes.stroke
  gm_2_12_vor.svg, elements.2.elements.1.attributes.stroke
  gm_2_12_vor.svg, elements.2.elements.2.attributes.stroke
  gm_2_12_vor.svg, elements.2.elements.3.attributes.stroke
  gm_2_1_1.svg, elements.2.elements.0.attributes.stroke
  gm_2_1_2.svg, elements.2.elements.0.attributes.stroke
  gm_2_1_3.svg, elements.2.elements.2.attributes.stroke
  gm_2_1_4.svg, elements.2.elements.0.attributes.stroke
  gm_2_1_gesamt.svg, elements.2.elements.5.attributes.stroke
  gm_2_1_gesamt.svg, elements.2.elements.6.attributes.stroke
  gm_2_1_vor.svg, elements.2.elements.3.attributes.stroke
  gm_2_2_1.svg, elements.2.elements.0.elements.1.attributes.stroke
  gm_2_2_1.svg, elements.2.elements.0.elements.2.attributes.stroke
  gm_2_2_1.svg, elements.2.elements.0.elements.3.attributes.stroke
  gm_2_2_2.svg, elements.2.elements.0.attributes.stroke
  gm_2_2_2.svg, elements.2.elements.1.attributes.stroke
  gm_2_2_2.svg, elements.2.elements.3.attributes.stroke
  gm_2_2_2.svg, elements.2.elements.4.attributes.stroke
  gm_2_2_3.svg, elements.2.elements.0.elements.0.attributes.stroke
  gm_2_2_3.svg, elements.2.elements.0.elements.1.attributes.stroke
  gm_2_2_3.svg, elements.2.elements.0.elements.3.attributes.stroke
  gm_2_2_4.svg, elements.2.elements.0.elements.0.attributes.stroke
  gm_2_2_4.svg, elements.2.elements.0.elements.1.attributes.stroke
  gm_2_2_4.svg, elements.2.elements.0.elements.3.attributes.stroke
  gm_2_2_gesamt.svg, elements.2.elements.0.elements.1.attributes.stroke
  gm_2_2_gesamt.svg, elements.2.elements.0.elements.2.attributes.stroke
  gm_2_2_gesamt.svg, elements.2.elements.0.elements.5.attributes.stroke
  gm_2_2_gesamt.svg, elements.2.elements.0.elements.6.attributes.stroke
  gm_2_2_vor.svg, elements.2.elements.3.attributes.stroke
  gm_2_3_1.svg, elements.2.elements.0.elements.0.attributes.stroke
  gm_2_3_1.svg, elements.2.elements.0.elements.1.attributes.stroke
  gm_2_3_1.svg, elements.2.elements.0.elements.2.attributes.stroke
  gm_2_3_2.svg, elements.2.elements.0.attributes.stroke
  gm_2_3_2.svg, elements.2.elements.1.attributes.stroke
  gm_2_3_2.svg, elements.2.elements.2.attributes.stroke
  gm_2_3_3.svg, elements.2.elements.0.elements.0.attributes.stroke
  gm_2_3_3.svg, elements.2.elements.0.elements.1.attributes.stroke
  gm_2_3_3.svg, elements.2.elements.0.elements.2.attributes.stroke
  gm_2_3_4.svg, elements.2.elements.0.elements.0.attributes.stroke
  gm_2_3_4.svg, elements.2.elements.0.elements.1.attributes.stroke
  gm_2_3_4.svg, elements.2.elements.0.elements.2.attributes.stroke
  gm_2_3_gesamt.svg, elements.2.elements.0.elements.0.attributes.stroke
  gm_2_3_gesamt.svg, elements.2.elements.0.elements.1.attributes.stroke
  gm_2_3_gesamt.svg, elements.2.elements.0.elements.2.attributes.stroke
  gm_2_3_gesamt.svg, elements.2.elements.0.elements.3.attributes.stroke
  gm_2_3_gesamt.svg, elements.2.elements.0.elements.4.attributes.stroke
  gm_2_3_gesamt.svg, elements.2.elements.0.elements.5.attributes.stroke
  gm_2_3_vor.svg, elements.2.elements.0.elements.0.attributes.stroke
  gm_2_3_vor.svg, elements.2.elements.0.elements.1.attributes.stroke
  gm_2_3_vor.svg, elements.2.elements.0.elements.2.attributes.stroke
  gm_2_4_1.svg, elements.2.elements.1.attributes.stroke
  gm_2_4_1.svg, elements.2.elements.2.attributes.stroke
  gm_2_4_2.svg, elements.2.elements.0.attributes.stroke
  gm_2_4_2.svg, elements.2.elements.1.attributes.stroke
  gm_2_4_3.svg, elements.2.elements.0.attributes.stroke
  gm_2_4_3.svg, elements.2.elements.2.attributes.stroke
  gm_2_4_3.svg, elements.2.elements.3.attributes.stroke
  gm_2_4_3.svg, elements.2.elements.4.attributes.stroke
  gm_2_4_4.svg, elements.2.elements.1.attributes.stroke
  gm_2_4_4.svg, elements.2.elements.2.attributes.stroke
  gm_2_4_4.svg, elements.2.elements.3.attributes.stroke
  gm_2_4_gesamt.svg, elements.2.elements.0.attributes.stroke
  gm_2_4_gesamt.svg, elements.2.elements.1.attributes.stroke
  gm_2_4_gesamt.svg, elements.2.elements.2.attributes.stroke
  gm_2_4_vor.svg, elements.2.elements.0.elements.0.attributes.stroke
  gm_2_5_1.svg, elements.2.elements.0.attributes.stroke
  gm_2_5_1.svg, elements.2.elements.1.attributes.stroke
  gm_2_5_1.svg, elements.2.elements.2.attributes.stroke
  gm_2_5_1.svg, elements.2.elements.3.attributes.stroke
  gm_2_5_2.svg, elements.2.elements.0.attributes.stroke
  gm_2_5_2.svg, elements.2.elements.1.attributes.stroke
  gm_2_5_2.svg, elements.2.elements.2.attributes.stroke
  gm_2_5_2.svg, elements.2.elements.3.attributes.stroke
  gm_2_5_2.svg, elements.2.elements.4.attributes.stroke
  gm_2_5_3.svg, elements.2.elements.0.elements.0.attributes.stroke
  gm_2_5_3.svg, elements.2.elements.0.elements.1.attributes.stroke
  gm_2_5_3.svg, elements.2.elements.0.elements.2.attributes.stroke
  gm_2_5_3.svg, elements.2.elements.0.elements.3.attributes.stroke
  gm_2_5_4.svg, elements.2.elements.0.attributes.stroke
  gm_2_5_4.svg, elements.2.elements.1.attributes.stroke
  gm_2_5_4.svg, elements.2.elements.2.attributes.stroke
  gm_2_5_4.svg, elements.2.elements.3.attributes.stroke
  gm_2_5_4.svg, elements.2.elements.4.attributes.stroke
  gm_2_5_gesamt.svg, elements.2.elements.0.attributes.stroke
  gm_2_5_gesamt.svg, elements.2.elements.1.attributes.stroke
  gm_2_5_gesamt.svg, elements.2.elements.2.attributes.stroke
  gm_2_5_gesamt.svg, elements.2.elements.3.attributes.stroke
  gm_2_5_gesamt.svg, elements.2.elements.4.attributes.stroke
  gm_2_5_gesamt.svg, elements.2.elements.5.attributes.stroke
  gm_2_5_gesamt.svg, elements.2.elements.7.attributes.stroke
  gm_2_5_gesamt.svg, elements.2.elements.8.attributes.stroke
  gm_2_5_vor.svg, elements.2.elements.0.attributes.stroke
  gm_2_5_vor.svg, elements.2.elements.1.attributes.stroke
  gm_2_5_vor.svg, elements.2.elements.2.attributes.stroke
  gm_2_5_vor.svg, elements.2.elements.3.attributes.stroke
  gm_2_5_vor.svg, elements.2.elements.5.attributes.stroke
  gm_2_6_1.svg, elements.2.elements.0.attributes.stroke
  gm_2_6_2.svg, elements.2.elements.0.attributes.stroke
  gm_2_6_3.svg, elements.2.elements.0.attributes.stroke
  gm_2_6_4.svg, elements.2.elements.0.attributes.stroke
  gm_2_6_gesamt.svg, elements.2.elements.0.attributes.stroke
  gm_2_6_gesamt.svg, elements.2.elements.1.attributes.stroke
  gm_2_6_vor.svg, elements.2.elements.0.attributes.stroke
  gm_2_7_1.svg, elements.2.elements.0.attributes.stroke
  gm_2_7_1.svg, elements.2.elements.1.attributes.stroke
  gm_2_7_1.svg, elements.2.elements.2.attributes.stroke
  gm_2_7_2.svg, elements.2.elements.0.attributes.stroke
  gm_2_7_2.svg, elements.2.elements.1.attributes.stroke
  gm_2_7_2.svg, elements.2.elements.2.attributes.stroke
  gm_2_7_3.svg, elements.2.elements.0.elements.0.attributes.stroke
  gm_2_7_4.svg, elements.2.elements.0.attributes.stroke
  gm_2_7_4.svg, elements.2.elements.5.attributes.stroke
  gm_2_7_4.svg, elements.2.elements.6.attributes.stroke
  gm_2_7_gesamt.svg, elements.2.elements.0.attributes.stroke
  gm_2_7_gesamt.svg, elements.2.elements.1.attributes.stroke
  gm_2_7_gesamt.svg, elements.2.elements.2.attributes.stroke
  gm_2_7_gesamt.svg, elements.2.elements.3.attributes.stroke
  gm_2_7_vor.svg, elements.2.elements.0.attributes.stroke
  gm_2_7_vor.svg, elements.2.elements.1.attributes.stroke
  gm_2_7_vor.svg, elements.2.elements.2.attributes.stroke
  gm_2_8_1.svg, elements.2.elements.0.attributes.stroke
  gm_2_8_1.svg, elements.2.elements.1.attributes.stroke
  gm_2_8_1.svg, elements.2.elements.2.attributes.stroke
  gm_2_8_1.svg, elements.2.elements.3.attributes.stroke
  gm_2_8_1.svg, elements.2.elements.4.attributes.stroke
  gm_2_8_1.svg, elements.2.elements.5.attributes.stroke
  gm_2_8_1.svg, elements.2.elements.6.attributes.stroke
  gm_2_8_2.svg, elements.2.elements.0.elements.0.attributes.stroke
  gm_2_8_2.svg, elements.2.elements.0.elements.1.attributes.stroke
  gm_2_8_2.svg, elements.2.elements.0.elements.2.attributes.stroke
  gm_2_8_2.svg, elements.2.elements.0.elements.3.attributes.stroke
  gm_2_8_2.svg, elements.2.elements.0.elements.4.attributes.stroke
  gm_2_8_2.svg, elements.2.elements.0.elements.5.attributes.stroke
  gm_2_8_3.svg, elements.2.elements.0.attributes.stroke
  gm_2_8_3.svg, elements.2.elements.1.attributes.stroke
  gm_2_8_3.svg, elements.2.elements.2.attributes.stroke
  gm_2_8_3.svg, elements.2.elements.3.attributes.stroke
  gm_2_8_3.svg, elements.2.elements.4.attributes.stroke
  gm_2_8_3.svg, elements.2.elements.5.attributes.stroke
  gm_2_8_4.svg, elements.2.elements.0.elements.0.attributes.stroke
  gm_2_8_4.svg, elements.2.elements.0.elements.1.attributes.stroke
  gm_2_8_4.svg, elements.2.elements.0.elements.2.attributes.stroke
  gm_2_8_4.svg, elements.2.elements.0.elements.3.attributes.stroke
  gm_2_8_4.svg, elements.2.elements.0.elements.4.attributes.stroke
  gm_2_8_gesamt.svg, elements.2.elements.0.elements.0.attributes.stroke
  gm_2_8_gesamt.svg, elements.2.elements.0.elements.1.attributes.stroke
  gm_2_8_gesamt.svg, elements.2.elements.0.elements.2.attributes.stroke
  gm_2_8_gesamt.svg, elements.2.elements.0.elements.3.attributes.stroke
  gm_2_8_gesamt.svg, elements.2.elements.0.elements.4.attributes.stroke
  gm_2_8_gesamt.svg, elements.2.elements.0.elements.5.attributes.stroke
  gm_2_8_gesamt.svg, elements.2.elements.0.elements.6.attributes.stroke
  gm_2_8_gesamt.svg, elements.2.elements.0.elements.7.attributes.stroke
  gm_2_8_vor.svg, elements.2.elements.0.elements.0.attributes.stroke
  gm_2_8_vor.svg, elements.2.elements.0.elements.1.attributes.stroke
  gm_2_8_vor.svg, elements.2.elements.0.elements.2.attributes.stroke
  gm_2_8_vor.svg, elements.2.elements.0.elements.3.attributes.stroke
  gm_2_8_vor.svg, elements.2.elements.0.elements.4.attributes.stroke
  gm_2_9_1.svg, elements.2.elements.0.attributes.stroke
  gm_2_9_1.svg, elements.2.elements.1.attributes.stroke
  gm_2_9_1.svg, elements.2.elements.4.attributes.stroke
  gm_2_9_2.svg, elements.2.elements.0.attributes.stroke
  gm_2_9_2.svg, elements.2.elements.1.attributes.stroke
  gm_2_9_2.svg, elements.2.elements.2.attributes.stroke
  gm_2_9_3.svg, elements.2.elements.0.attributes.stroke
  gm_2_9_3.svg, elements.2.elements.1.attributes.stroke
  gm_2_9_3.svg, elements.2.elements.2.attributes.stroke
  gm_2_9_4.svg, elements.2.elements.0.attributes.stroke
  gm_2_9_4.svg, elements.2.elements.1.attributes.stroke
  gm_2_9_4.svg, elements.2.elements.2.attributes.stroke
  gm_2_9_gesamt.svg, elements.2.elements.0.attributes.stroke
  gm_2_9_gesamt.svg, elements.2.elements.1.attributes.stroke
  gm_2_9_gesamt.svg, elements.2.elements.6.attributes.stroke
  gm_2_9_gesamt.svg, elements.2.elements.7.attributes.stroke
  gm_2_9_vor.svg, elements.2.elements.0.attributes.stroke
  gm_2_9_vor.svg, elements.2.elements.1.attributes.stroke
  gm_2_9_vor.svg, elements.2.elements.4.attributes.stroke
  ptv_2_4.svg, elements.2.elements.0.attributes.stroke
  ptv_4_1.svg, elements.2.elements.16.elements.0.attributes.stroke
  ptv_4_2.svg, elements.2.elements.16.elements.0.attributes.stroke
  ptv_4_3.svg, elements.2.elements.16.elements.0.attributes.stroke
  ptv_4_4.svg, elements.2.elements.12.elements.0.attributes.stroke
  */
  stroke: #000000;
}

.svg-VBZUPpI-tc{
  /*
  Used In:
  gm_2_10_1.svg, elements.2.elements.0.attributes.stroke-width
  gm_2_10_1.svg, elements.2.elements.1.attributes.stroke-width
  gm_2_10_1.svg, elements.2.elements.2.attributes.stroke-width
  gm_2_10_1.svg, elements.2.elements.3.attributes.stroke-width
  gm_2_10_2.svg, elements.2.elements.0.elements.0.attributes.stroke-width
  gm_2_10_2.svg, elements.2.elements.0.elements.1.attributes.stroke-width
  gm_2_10_2.svg, elements.2.elements.0.elements.2.attributes.stroke-width
  gm_2_10_2.svg, elements.2.elements.0.elements.3.attributes.stroke-width
  gm_2_10_3.svg, elements.2.elements.0.attributes.stroke-width
  gm_2_10_3.svg, elements.2.elements.1.attributes.stroke-width
  gm_2_10_3.svg, elements.2.elements.2.attributes.stroke-width
  gm_2_10_3.svg, elements.2.elements.3.attributes.stroke-width
  gm_2_10_4.svg, elements.2.elements.0.attributes.stroke-width
  gm_2_10_4.svg, elements.2.elements.1.attributes.stroke-width
  gm_2_10_4.svg, elements.2.elements.2.attributes.stroke-width
  gm_2_10_4.svg, elements.2.elements.3.attributes.stroke-width
  gm_2_10_4.svg, elements.2.elements.4.attributes.stroke-width
  gm_2_10_gesamt.svg, elements.2.elements.0.elements.0.attributes.stroke-width
  gm_2_10_gesamt.svg, elements.2.elements.0.elements.1.attributes.stroke-width
  gm_2_10_gesamt.svg, elements.2.elements.0.elements.2.attributes.stroke-width
  gm_2_10_gesamt.svg, elements.2.elements.0.elements.3.attributes.stroke-width
  gm_2_10_gesamt.svg, elements.2.elements.0.elements.4.attributes.stroke-width
  gm_2_10_gesamt.svg, elements.2.elements.0.elements.5.attributes.stroke-width
  gm_2_10_vor.svg, elements.2.elements.0.elements.0.attributes.stroke-width
  gm_2_10_vor.svg, elements.2.elements.0.elements.1.attributes.stroke-width
  gm_2_10_vor.svg, elements.2.elements.0.elements.2.attributes.stroke-width
  gm_2_10_vor.svg, elements.2.elements.0.elements.3.attributes.stroke-width
  gm_2_11_1.svg, elements.2.elements.2.attributes.stroke-width
  gm_2_11_1.svg, elements.2.elements.3.attributes.stroke-width
  gm_2_11_1.svg, elements.2.elements.4.attributes.stroke-width
  gm_2_11_1.svg, elements.2.elements.5.attributes.stroke-width
  gm_2_11_1.svg, elements.2.elements.6.attributes.stroke-width
  gm_2_11_1.svg, elements.2.elements.7.attributes.stroke-width
  gm_2_11_1.svg, elements.2.elements.8.attributes.stroke-width
  gm_2_11_1.svg, elements.2.elements.9.attributes.stroke-width
  gm_2_11_2.svg, elements.2.elements.0.elements.0.attributes.stroke-width
  gm_2_11_2.svg, elements.2.elements.0.elements.1.attributes.stroke-width
  gm_2_11_2.svg, elements.2.elements.0.elements.2.attributes.stroke-width
  gm_2_11_2.svg, elements.2.elements.0.elements.3.attributes.stroke-width
  gm_2_11_2.svg, elements.2.elements.0.elements.4.attributes.stroke-width
  gm_2_11_3.svg, elements.2.elements.0.elements.1.attributes.stroke-width
  gm_2_11_3.svg, elements.2.elements.0.elements.2.attributes.stroke-width
  gm_2_11_3.svg, elements.2.elements.0.elements.3.attributes.stroke-width
  gm_2_11_3.svg, elements.2.elements.0.elements.4.attributes.stroke-width
  gm_2_11_3.svg, elements.2.elements.0.elements.5.attributes.stroke-width
  gm_2_11_3.svg, elements.2.elements.0.elements.6.attributes.stroke-width
  gm_2_11_3.svg, elements.2.elements.0.elements.7.attributes.stroke-width
  gm_2_11_3.svg, elements.2.elements.0.elements.8.attributes.stroke-width
  gm_2_11_3.svg, elements.2.elements.0.elements.9.attributes.stroke-width
  gm_2_11_4.svg, elements.2.elements.2.attributes.stroke-width
  gm_2_11_4.svg, elements.2.elements.3.attributes.stroke-width
  gm_2_11_4.svg, elements.2.elements.4.attributes.stroke-width
  gm_2_11_4.svg, elements.2.elements.5.attributes.stroke-width
  gm_2_11_4.svg, elements.2.elements.6.attributes.stroke-width
  gm_2_11_4.svg, elements.2.elements.7.attributes.stroke-width
  gm_2_11_4.svg, elements.2.elements.8.attributes.stroke-width
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.1.attributes.stroke-width
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.2.attributes.stroke-width
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.3.attributes.stroke-width
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.4.attributes.stroke-width
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.5.attributes.stroke-width
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.6.attributes.stroke-width
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.7.attributes.stroke-width
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.8.attributes.stroke-width
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.9.attributes.stroke-width
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.10.attributes.stroke-width
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.12.attributes.stroke-width
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.13.attributes.stroke-width
  gm_2_11_vor.svg, elements.2.elements.0.elements.1.attributes.stroke-width
  gm_2_11_vor.svg, elements.2.elements.0.elements.2.attributes.stroke-width
  gm_2_11_vor.svg, elements.2.elements.0.elements.3.attributes.stroke-width
  gm_2_11_vor.svg, elements.2.elements.0.elements.4.attributes.stroke-width
  gm_2_11_vor.svg, elements.2.elements.0.elements.5.attributes.stroke-width
  gm_2_11_vor.svg, elements.2.elements.0.elements.6.attributes.stroke-width
  gm_2_11_vor.svg, elements.2.elements.0.elements.8.attributes.stroke-width
  gm_2_12_1.svg, elements.2.elements.0.attributes.stroke-width
  gm_2_12_1.svg, elements.2.elements.1.attributes.stroke-width
  gm_2_12_1.svg, elements.2.elements.2.attributes.stroke-width
  gm_2_12_1.svg, elements.2.elements.3.attributes.stroke-width
  gm_2_12_1.svg, elements.2.elements.4.attributes.stroke-width
  gm_2_12_2.svg, elements.2.elements.0.attributes.stroke-width
  gm_2_12_2.svg, elements.2.elements.1.attributes.stroke-width
  gm_2_12_2.svg, elements.2.elements.2.attributes.stroke-width
  gm_2_12_2.svg, elements.2.elements.3.attributes.stroke-width
  gm_2_12_2.svg, elements.2.elements.4.attributes.stroke-width
  gm_2_12_2.svg, elements.2.elements.5.attributes.stroke-width
  gm_2_12_3.svg, elements.2.elements.0.attributes.stroke-width
  gm_2_12_3.svg, elements.2.elements.1.attributes.stroke-width
  gm_2_12_3.svg, elements.2.elements.2.attributes.stroke-width
  gm_2_12_3.svg, elements.2.elements.3.attributes.stroke-width
  gm_2_12_3.svg, elements.2.elements.4.attributes.stroke-width
  gm_2_12_4.svg, elements.2.elements.0.attributes.stroke-width
  gm_2_12_4.svg, elements.2.elements.1.attributes.stroke-width
  gm_2_12_4.svg, elements.2.elements.2.attributes.stroke-width
  gm_2_12_4.svg, elements.2.elements.3.attributes.stroke-width
  gm_2_12_gesamt.svg, elements.2.elements.0.attributes.stroke-width
  gm_2_12_gesamt.svg, elements.2.elements.1.attributes.stroke-width
  gm_2_12_gesamt.svg, elements.2.elements.2.attributes.stroke-width
  gm_2_12_gesamt.svg, elements.2.elements.3.attributes.stroke-width
  gm_2_12_gesamt.svg, elements.2.elements.4.attributes.stroke-width
  gm_2_12_gesamt.svg, elements.2.elements.5.attributes.stroke-width
  gm_2_12_vor.svg, elements.2.elements.0.attributes.stroke-width
  gm_2_12_vor.svg, elements.2.elements.1.attributes.stroke-width
  gm_2_12_vor.svg, elements.2.elements.2.attributes.stroke-width
  gm_2_12_vor.svg, elements.2.elements.3.attributes.stroke-width
  gm_2_1_1.svg, elements.2.elements.0.attributes.stroke-width
  gm_2_1_2.svg, elements.2.elements.0.attributes.stroke-width
  gm_2_1_3.svg, elements.2.elements.2.attributes.stroke-width
  gm_2_1_4.svg, elements.2.elements.0.attributes.stroke-width
  gm_2_1_gesamt.svg, elements.2.elements.5.attributes.stroke-width
  gm_2_1_gesamt.svg, elements.2.elements.6.attributes.stroke-width
  gm_2_1_vor.svg, elements.2.elements.3.attributes.stroke-width
  gm_2_2_1.svg, elements.2.elements.0.elements.1.attributes.stroke-width
  gm_2_2_1.svg, elements.2.elements.0.elements.2.attributes.stroke-width
  gm_2_2_1.svg, elements.2.elements.0.elements.3.attributes.stroke-width
  gm_2_2_2.svg, elements.2.elements.0.attributes.stroke-width
  gm_2_2_2.svg, elements.2.elements.1.attributes.stroke-width
  gm_2_2_2.svg, elements.2.elements.3.attributes.stroke-width
  gm_2_2_2.svg, elements.2.elements.4.attributes.stroke-width
  gm_2_2_3.svg, elements.2.elements.0.elements.0.attributes.stroke-width
  gm_2_2_3.svg, elements.2.elements.0.elements.1.attributes.stroke-width
  gm_2_2_3.svg, elements.2.elements.0.elements.3.attributes.stroke-width
  gm_2_2_4.svg, elements.2.elements.0.elements.0.attributes.stroke-width
  gm_2_2_4.svg, elements.2.elements.0.elements.1.attributes.stroke-width
  gm_2_2_4.svg, elements.2.elements.0.elements.3.attributes.stroke-width
  gm_2_2_gesamt.svg, elements.2.elements.0.elements.1.attributes.stroke-width
  gm_2_2_gesamt.svg, elements.2.elements.0.elements.2.attributes.stroke-width
  gm_2_2_gesamt.svg, elements.2.elements.0.elements.5.attributes.stroke-width
  gm_2_2_gesamt.svg, elements.2.elements.0.elements.6.attributes.stroke-width
  gm_2_2_vor.svg, elements.2.elements.3.attributes.stroke-width
  gm_2_3_1.svg, elements.2.elements.0.elements.0.attributes.stroke-width
  gm_2_3_1.svg, elements.2.elements.0.elements.1.attributes.stroke-width
  gm_2_3_1.svg, elements.2.elements.0.elements.2.attributes.stroke-width
  gm_2_3_2.svg, elements.2.elements.0.attributes.stroke-width
  gm_2_3_2.svg, elements.2.elements.1.attributes.stroke-width
  gm_2_3_2.svg, elements.2.elements.2.attributes.stroke-width
  gm_2_3_3.svg, elements.2.elements.0.elements.0.attributes.stroke-width
  gm_2_3_3.svg, elements.2.elements.0.elements.1.attributes.stroke-width
  gm_2_3_3.svg, elements.2.elements.0.elements.2.attributes.stroke-width
  gm_2_3_4.svg, elements.2.elements.0.elements.0.attributes.stroke-width
  gm_2_3_4.svg, elements.2.elements.0.elements.1.attributes.stroke-width
  gm_2_3_4.svg, elements.2.elements.0.elements.2.attributes.stroke-width
  gm_2_3_gesamt.svg, elements.2.elements.0.elements.0.attributes.stroke-width
  gm_2_3_gesamt.svg, elements.2.elements.0.elements.1.attributes.stroke-width
  gm_2_3_gesamt.svg, elements.2.elements.0.elements.2.attributes.stroke-width
  gm_2_3_gesamt.svg, elements.2.elements.0.elements.3.attributes.stroke-width
  gm_2_3_gesamt.svg, elements.2.elements.0.elements.4.attributes.stroke-width
  gm_2_3_gesamt.svg, elements.2.elements.0.elements.5.attributes.stroke-width
  gm_2_3_vor.svg, elements.2.elements.0.elements.0.attributes.stroke-width
  gm_2_3_vor.svg, elements.2.elements.0.elements.1.attributes.stroke-width
  gm_2_3_vor.svg, elements.2.elements.0.elements.2.attributes.stroke-width
  gm_2_4_1.svg, elements.2.elements.1.attributes.stroke-width
  gm_2_4_1.svg, elements.2.elements.2.attributes.stroke-width
  gm_2_4_2.svg, elements.2.elements.0.attributes.stroke-width
  gm_2_4_2.svg, elements.2.elements.1.attributes.stroke-width
  gm_2_4_3.svg, elements.2.elements.0.attributes.stroke-width
  gm_2_4_3.svg, elements.2.elements.2.attributes.stroke-width
  gm_2_4_3.svg, elements.2.elements.3.attributes.stroke-width
  gm_2_4_3.svg, elements.2.elements.4.attributes.stroke-width
  gm_2_4_4.svg, elements.2.elements.1.attributes.stroke-width
  gm_2_4_4.svg, elements.2.elements.2.attributes.stroke-width
  gm_2_4_4.svg, elements.2.elements.3.attributes.stroke-width
  gm_2_4_gesamt.svg, elements.2.elements.0.attributes.stroke-width
  gm_2_4_gesamt.svg, elements.2.elements.1.attributes.stroke-width
  gm_2_4_gesamt.svg, elements.2.elements.2.attributes.stroke-width
  gm_2_4_vor.svg, elements.2.elements.0.elements.0.attributes.stroke-width
  gm_2_5_1.svg, elements.2.elements.0.attributes.stroke-width
  gm_2_5_1.svg, elements.2.elements.1.attributes.stroke-width
  gm_2_5_1.svg, elements.2.elements.2.attributes.stroke-width
  gm_2_5_1.svg, elements.2.elements.3.attributes.stroke-width
  gm_2_5_2.svg, elements.2.elements.0.attributes.stroke-width
  gm_2_5_2.svg, elements.2.elements.1.attributes.stroke-width
  gm_2_5_2.svg, elements.2.elements.2.attributes.stroke-width
  gm_2_5_2.svg, elements.2.elements.3.attributes.stroke-width
  gm_2_5_2.svg, elements.2.elements.4.attributes.stroke-width
  gm_2_5_3.svg, elements.2.elements.0.elements.0.attributes.stroke-width
  gm_2_5_3.svg, elements.2.elements.0.elements.1.attributes.stroke-width
  gm_2_5_3.svg, elements.2.elements.0.elements.2.attributes.stroke-width
  gm_2_5_3.svg, elements.2.elements.0.elements.3.attributes.stroke-width
  gm_2_5_4.svg, elements.2.elements.0.attributes.stroke-width
  gm_2_5_4.svg, elements.2.elements.1.attributes.stroke-width
  gm_2_5_4.svg, elements.2.elements.2.attributes.stroke-width
  gm_2_5_4.svg, elements.2.elements.3.attributes.stroke-width
  gm_2_5_4.svg, elements.2.elements.4.attributes.stroke-width
  gm_2_5_gesamt.svg, elements.2.elements.0.attributes.stroke-width
  gm_2_5_gesamt.svg, elements.2.elements.1.attributes.stroke-width
  gm_2_5_gesamt.svg, elements.2.elements.2.attributes.stroke-width
  gm_2_5_gesamt.svg, elements.2.elements.3.attributes.stroke-width
  gm_2_5_gesamt.svg, elements.2.elements.4.attributes.stroke-width
  gm_2_5_gesamt.svg, elements.2.elements.5.attributes.stroke-width
  gm_2_5_gesamt.svg, elements.2.elements.7.attributes.stroke-width
  gm_2_5_gesamt.svg, elements.2.elements.8.attributes.stroke-width
  gm_2_5_vor.svg, elements.2.elements.0.attributes.stroke-width
  gm_2_5_vor.svg, elements.2.elements.1.attributes.stroke-width
  gm_2_5_vor.svg, elements.2.elements.2.attributes.stroke-width
  gm_2_5_vor.svg, elements.2.elements.3.attributes.stroke-width
  gm_2_5_vor.svg, elements.2.elements.5.attributes.stroke-width
  gm_2_6_1.svg, elements.2.elements.0.attributes.stroke-width
  gm_2_6_2.svg, elements.2.elements.0.attributes.stroke-width
  gm_2_6_3.svg, elements.2.elements.0.attributes.stroke-width
  gm_2_6_4.svg, elements.2.elements.0.attributes.stroke-width
  gm_2_6_gesamt.svg, elements.2.elements.0.attributes.stroke-width
  gm_2_6_gesamt.svg, elements.2.elements.1.attributes.stroke-width
  gm_2_6_vor.svg, elements.2.elements.0.attributes.stroke-width
  gm_2_7_1.svg, elements.2.elements.0.attributes.stroke-width
  gm_2_7_1.svg, elements.2.elements.1.attributes.stroke-width
  gm_2_7_1.svg, elements.2.elements.2.attributes.stroke-width
  gm_2_7_2.svg, elements.2.elements.0.attributes.stroke-width
  gm_2_7_2.svg, elements.2.elements.1.attributes.stroke-width
  gm_2_7_2.svg, elements.2.elements.2.attributes.stroke-width
  gm_2_7_3.svg, elements.2.elements.0.elements.0.attributes.stroke-width
  gm_2_7_4.svg, elements.2.elements.0.attributes.stroke-width
  gm_2_7_4.svg, elements.2.elements.5.attributes.stroke-width
  gm_2_7_4.svg, elements.2.elements.6.attributes.stroke-width
  gm_2_7_gesamt.svg, elements.2.elements.0.attributes.stroke-width
  gm_2_7_gesamt.svg, elements.2.elements.1.attributes.stroke-width
  gm_2_7_gesamt.svg, elements.2.elements.2.attributes.stroke-width
  gm_2_7_gesamt.svg, elements.2.elements.3.attributes.stroke-width
  gm_2_7_vor.svg, elements.2.elements.0.attributes.stroke-width
  gm_2_7_vor.svg, elements.2.elements.1.attributes.stroke-width
  gm_2_7_vor.svg, elements.2.elements.2.attributes.stroke-width
  gm_2_8_1.svg, elements.2.elements.0.attributes.stroke-width
  gm_2_8_1.svg, elements.2.elements.1.attributes.stroke-width
  gm_2_8_1.svg, elements.2.elements.2.attributes.stroke-width
  gm_2_8_1.svg, elements.2.elements.3.attributes.stroke-width
  gm_2_8_1.svg, elements.2.elements.4.attributes.stroke-width
  gm_2_8_1.svg, elements.2.elements.5.attributes.stroke-width
  gm_2_8_1.svg, elements.2.elements.6.attributes.stroke-width
  gm_2_8_2.svg, elements.2.elements.0.elements.0.attributes.stroke-width
  gm_2_8_2.svg, elements.2.elements.0.elements.1.attributes.stroke-width
  gm_2_8_2.svg, elements.2.elements.0.elements.2.attributes.stroke-width
  gm_2_8_2.svg, elements.2.elements.0.elements.3.attributes.stroke-width
  gm_2_8_2.svg, elements.2.elements.0.elements.4.attributes.stroke-width
  gm_2_8_2.svg, elements.2.elements.0.elements.5.attributes.stroke-width
  gm_2_8_3.svg, elements.2.elements.0.attributes.stroke-width
  gm_2_8_3.svg, elements.2.elements.1.attributes.stroke-width
  gm_2_8_3.svg, elements.2.elements.2.attributes.stroke-width
  gm_2_8_3.svg, elements.2.elements.3.attributes.stroke-width
  gm_2_8_3.svg, elements.2.elements.4.attributes.stroke-width
  gm_2_8_3.svg, elements.2.elements.5.attributes.stroke-width
  gm_2_8_4.svg, elements.2.elements.0.elements.0.attributes.stroke-width
  gm_2_8_4.svg, elements.2.elements.0.elements.1.attributes.stroke-width
  gm_2_8_4.svg, elements.2.elements.0.elements.2.attributes.stroke-width
  gm_2_8_4.svg, elements.2.elements.0.elements.3.attributes.stroke-width
  gm_2_8_4.svg, elements.2.elements.0.elements.4.attributes.stroke-width
  gm_2_8_gesamt.svg, elements.2.elements.0.elements.0.attributes.stroke-width
  gm_2_8_gesamt.svg, elements.2.elements.0.elements.1.attributes.stroke-width
  gm_2_8_gesamt.svg, elements.2.elements.0.elements.2.attributes.stroke-width
  gm_2_8_gesamt.svg, elements.2.elements.0.elements.3.attributes.stroke-width
  gm_2_8_gesamt.svg, elements.2.elements.0.elements.4.attributes.stroke-width
  gm_2_8_gesamt.svg, elements.2.elements.0.elements.5.attributes.stroke-width
  gm_2_8_gesamt.svg, elements.2.elements.0.elements.6.attributes.stroke-width
  gm_2_8_gesamt.svg, elements.2.elements.0.elements.7.attributes.stroke-width
  gm_2_8_vor.svg, elements.2.elements.0.elements.0.attributes.stroke-width
  gm_2_8_vor.svg, elements.2.elements.0.elements.1.attributes.stroke-width
  gm_2_8_vor.svg, elements.2.elements.0.elements.2.attributes.stroke-width
  gm_2_8_vor.svg, elements.2.elements.0.elements.3.attributes.stroke-width
  gm_2_8_vor.svg, elements.2.elements.0.elements.4.attributes.stroke-width
  gm_2_9_1.svg, elements.2.elements.0.attributes.stroke-width
  gm_2_9_1.svg, elements.2.elements.1.attributes.stroke-width
  gm_2_9_1.svg, elements.2.elements.4.attributes.stroke-width
  gm_2_9_2.svg, elements.2.elements.0.attributes.stroke-width
  gm_2_9_2.svg, elements.2.elements.1.attributes.stroke-width
  gm_2_9_2.svg, elements.2.elements.2.attributes.stroke-width
  gm_2_9_3.svg, elements.2.elements.0.attributes.stroke-width
  gm_2_9_3.svg, elements.2.elements.1.attributes.stroke-width
  gm_2_9_3.svg, elements.2.elements.2.attributes.stroke-width
  gm_2_9_4.svg, elements.2.elements.0.attributes.stroke-width
  gm_2_9_4.svg, elements.2.elements.1.attributes.stroke-width
  gm_2_9_4.svg, elements.2.elements.2.attributes.stroke-width
  gm_2_9_gesamt.svg, elements.2.elements.0.attributes.stroke-width
  gm_2_9_gesamt.svg, elements.2.elements.1.attributes.stroke-width
  gm_2_9_gesamt.svg, elements.2.elements.6.attributes.stroke-width
  gm_2_9_gesamt.svg, elements.2.elements.7.attributes.stroke-width
  gm_2_9_vor.svg, elements.2.elements.0.attributes.stroke-width
  gm_2_9_vor.svg, elements.2.elements.1.attributes.stroke-width
  gm_2_9_vor.svg, elements.2.elements.4.attributes.stroke-width
  ptv_3.svg, elements.2.elements.6.elements.2.attributes.stroke-width
  ptv_3.svg, elements.2.elements.7.elements.2.attributes.stroke-width
  */
  stroke-width: 3;
}

.svg-ikKqvZ5oaA{
  /*
  Used In:
  gm_2_10_2.svg, elements.2.elements.0.elements.2.attributes.fill
  gm_2_10_gesamt.svg, elements.2.elements.0.elements.2.attributes.fill
  gm_2_10_gesamt.svg, elements.2.elements.0.elements.3.attributes.fill
  gm_2_10_vor.svg, elements.2.elements.0.elements.2.attributes.fill
  gm_2_11_1.svg, elements.2.elements.1.elements.0.attributes.fill
  gm_2_11_1.svg, elements.2.elements.7.attributes.fill
  gm_2_11_1.svg, elements.2.elements.8.attributes.fill
  gm_2_11_2.svg, elements.2.elements.0.elements.0.attributes.fill
  gm_2_11_2.svg, elements.2.elements.0.elements.1.attributes.fill
  gm_2_11_2.svg, elements.2.elements.0.elements.4.attributes.fill
  gm_2_11_3.svg, elements.2.elements.0.elements.0.elements.0.attributes.fill
  gm_2_11_3.svg, elements.2.elements.0.elements.4.attributes.fill
  gm_2_11_3.svg, elements.2.elements.0.elements.5.attributes.fill
  gm_2_11_3.svg, elements.2.elements.0.elements.6.attributes.fill
  gm_2_11_3.svg, elements.2.elements.0.elements.7.attributes.fill
  gm_2_11_4.svg, elements.2.elements.1.elements.0.attributes.fill
  gm_2_11_4.svg, elements.2.elements.7.attributes.fill
  gm_2_11_4.svg, elements.2.elements.8.attributes.fill
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.1.attributes.fill
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.2.attributes.fill
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.6.attributes.fill
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.7.attributes.fill
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.8.attributes.fill
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.9.attributes.fill
  gm_2_11_vor.svg, elements.2.elements.0.elements.1.attributes.fill
  gm_2_11_vor.svg, elements.2.elements.0.elements.2.attributes.fill
  gm_2_12_1.svg, elements.2.elements.3.attributes.fill
  gm_2_12_2.svg, elements.2.elements.5.attributes.fill
  gm_2_12_3.svg, elements.2.elements.4.attributes.fill
  gm_2_1_1.svg, elements.2.elements.3.elements.0.attributes.fill
  gm_2_1_2.svg, elements.2.elements.2.elements.0.attributes.fill
  gm_2_1_3.svg, elements.2.elements.1.elements.0.attributes.fill
  gm_2_1_gesamt.svg, elements.2.elements.0.elements.0.attributes.fill
  gm_2_1_gesamt.svg, elements.2.elements.2.elements.0.attributes.fill
  gm_2_1_gesamt.svg, elements.2.elements.4.elements.0.attributes.fill
  gm_2_1_vor.svg, elements.2.elements.0.elements.0.attributes.fill
  gm_2_1_vor.svg, elements.2.elements.2.elements.0.attributes.fill
  gm_2_2_1.svg, elements.2.elements.0.elements.2.attributes.fill
  gm_2_2_1.svg, elements.2.elements.0.elements.3.attributes.fill
  gm_2_2_2.svg, elements.2.elements.0.attributes.fill
  gm_2_2_2.svg, elements.2.elements.1.attributes.fill
  gm_2_2_2.svg, elements.2.elements.4.attributes.fill
  gm_2_2_3.svg, elements.2.elements.0.elements.0.attributes.fill
  gm_2_2_3.svg, elements.2.elements.0.elements.1.attributes.fill
  gm_2_2_4.svg, elements.2.elements.0.elements.0.attributes.fill
  gm_2_2_4.svg, elements.2.elements.0.elements.1.attributes.fill
  gm_2_2_gesamt.svg, elements.2.elements.0.elements.1.attributes.fill
  gm_2_2_gesamt.svg, elements.2.elements.0.elements.2.attributes.fill
  gm_2_3_1.svg, elements.2.elements.0.elements.0.attributes.fill
  gm_2_3_2.svg, elements.2.elements.1.attributes.fill
  gm_2_3_2.svg, elements.2.elements.2.attributes.fill
  gm_2_3_3.svg, elements.2.elements.0.elements.0.attributes.fill
  gm_2_3_3.svg, elements.2.elements.0.elements.1.attributes.fill
  gm_2_3_4.svg, elements.2.elements.0.elements.1.attributes.fill
  gm_2_3_gesamt.svg, elements.2.elements.0.elements.0.attributes.fill
  gm_2_3_gesamt.svg, elements.2.elements.0.elements.5.attributes.fill
  gm_2_3_vor.svg, elements.2.elements.0.elements.0.attributes.fill
  gm_2_4_1.svg, elements.2.elements.2.attributes.fill
  gm_2_4_2.svg, elements.2.elements.1.attributes.fill
  gm_2_4_3.svg, elements.2.elements.3.attributes.fill
  gm_2_4_3.svg, elements.2.elements.4.attributes.fill
  gm_2_4_4.svg, elements.2.elements.1.attributes.fill
  gm_2_4_4.svg, elements.2.elements.2.attributes.fill
  gm_2_4_gesamt.svg, elements.2.elements.2.attributes.fill
  gm_2_4_vor.svg, elements.2.elements.0.elements.0.attributes.fill
  gm_2_5_1.svg, elements.2.elements.2.attributes.fill
  gm_2_5_1.svg, elements.2.elements.3.attributes.fill
  gm_2_5_2.svg, elements.2.elements.1.attributes.fill
  gm_2_5_2.svg, elements.2.elements.3.attributes.fill
  gm_2_5_2.svg, elements.2.elements.4.attributes.fill
  gm_2_5_3.svg, elements.2.elements.0.elements.1.attributes.fill
  gm_2_5_4.svg, elements.2.elements.3.attributes.fill
  gm_2_5_4.svg, elements.2.elements.4.attributes.fill
  gm_2_5_gesamt.svg, elements.2.elements.4.attributes.fill
  gm_2_6_1.svg, elements.2.elements.1.elements.0.attributes.fill
  gm_2_6_2.svg, elements.2.elements.3.elements.0.attributes.fill
  gm_2_6_3.svg, elements.2.elements.2.elements.0.attributes.fill
  gm_2_6_4.svg, elements.2.elements.1.elements.0.attributes.fill
  gm_2_6_4.svg, elements.2.elements.2.elements.0.attributes.fill
  gm_2_6_4.svg, elements.2.elements.4.elements.0.attributes.fill
  gm_2_6_gesamt.svg, elements.2.elements.2.elements.0.attributes.fill
  gm_2_6_gesamt.svg, elements.2.elements.4.elements.0.attributes.fill
  gm_2_6_gesamt.svg, elements.2.elements.5.elements.0.attributes.fill
  gm_2_6_gesamt.svg, elements.2.elements.7.elements.0.attributes.fill
  gm_2_6_vor.svg, elements.2.elements.2.elements.0.attributes.fill
  gm_2_8_1.svg, elements.2.elements.2.attributes.fill
  gm_2_8_2.svg, elements.2.elements.0.elements.2.attributes.fill
  gm_2_8_2.svg, elements.2.elements.0.elements.4.attributes.fill
  gm_2_8_4.svg, elements.2.elements.0.elements.2.attributes.fill
  gm_2_8_4.svg, elements.2.elements.0.elements.3.attributes.fill
  gm_2_8_gesamt.svg, elements.2.elements.0.elements.3.attributes.fill
  gm_2_8_gesamt.svg, elements.2.elements.0.elements.4.attributes.fill
  gm_2_8_gesamt.svg, elements.2.elements.0.elements.5.attributes.fill
  gm_2_8_vor.svg, elements.2.elements.0.elements.3.attributes.fill
  gm_2_9_1.svg, elements.2.elements.3.elements.0.attributes.fill
  gm_2_9_2.svg, elements.2.elements.3.elements.0.attributes.fill
  gm_2_9_2.svg, elements.2.elements.4.elements.0.attributes.fill
  gm_2_9_3.svg, elements.2.elements.3.elements.0.attributes.fill
  gm_2_9_3.svg, elements.2.elements.4.elements.0.attributes.fill
  gm_2_9_4.svg, elements.2.elements.3.elements.0.attributes.fill
  gm_2_9_4.svg, elements.2.elements.4.elements.0.attributes.fill
  gm_2_9_4.svg, elements.2.elements.5.elements.0.attributes.fill
  gm_2_9_gesamt.svg, elements.2.elements.2.elements.0.attributes.fill
  gm_2_9_gesamt.svg, elements.2.elements.5.elements.0.attributes.fill
  gm_2_9_vor.svg, elements.2.elements.2.elements.0.attributes.fill
  ptv_4_1.svg, elements.2.elements.0.attributes.fill
  ptv_4_1.svg, elements.2.elements.1.attributes.fill
  ptv_4_2.svg, elements.2.elements.0.attributes.fill
  ptv_4_2.svg, elements.2.elements.1.attributes.fill
  ptv_4_3.svg, elements.2.elements.0.attributes.fill
  ptv_4_3.svg, elements.2.elements.1.attributes.fill
  ptv_4_4.svg, elements.2.elements.0.attributes.fill
  ptv_4_4.svg, elements.2.elements.1.attributes.fill
  rd_1_2_vor.svg, elements.2.elements.0.attributes.fill
  rd_1_3_vor.svg, elements.2.elements.0.attributes.fill
  rd_1_4_vor.svg, elements.2.elements.0.elements.1.attributes.fill
  rd_1_beispiel.svg, elements.2.elements.0.elements.5.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.1.elements.0.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.2.elements.1.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.7.elements.2.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.9.elements.2.elements.2.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.10.elements.0.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.11.elements.2.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.12.elements.1.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.13.elements.1.attributes.fill
  rd_2_1_vor.svg, elements.2.elements.14.elements.2.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.0.elements.0.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.2.elements.0.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.5.elements.1.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.7.elements.1.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.8.elements.1.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.9.elements.1.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.10.elements.1.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.11.elements.2.attributes.fill
  rd_2_2_vor.svg, elements.2.elements.12.elements.2.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.3.elements.1.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.7.elements.0.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.8.elements.1.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.9.elements.1.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.10.elements.0.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.11.elements.2.attributes.fill
  rd_2_3_vor.svg, elements.2.elements.12.elements.2.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.0.elements.1.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.1.elements.2.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.7.elements.1.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.8.elements.1.attributes.fill
  rd_2_4_vor.svg, elements.2.elements.9.elements.2.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.0.elements.1.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.1.elements.1.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.3.elements.1.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.4.elements.2.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.7.elements.1.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.8.elements.1.attributes.fill
  rd_2_5_vor.svg, elements.2.elements.9.elements.2.attributes.fill
  rd_2_intro.svg, elements.2.elements.10.elements.0.attributes.fill
  rd_2_intro.svg, elements.2.elements.11.elements.0.attributes.fill
  rd_2_intro.svg, elements.2.elements.12.elements.1.attributes.fill
  rd_2_intro.svg, elements.2.elements.13.elements.1.attributes.fill
  rd_2_intro.svg, elements.2.elements.14.elements.0.attributes.fill
  rd_2_intro.svg, elements.2.elements.15.elements.1.attributes.fill
  rd_2_intro.svg, elements.2.elements.16.elements.1.attributes.fill
  rd_2_intro.svg, elements.2.elements.17.elements.1.attributes.fill
  rd_2_intro.svg, elements.2.elements.18.elements.2.attributes.fill
  */
  fill: #FFFFFF;
}

.svg-FjUD8Uw8X4{
  /*
  Used In:
  gm_2_10_2.svg, elements.2.elements.0.elements.2.attributes.stroke-linejoin
  gm_2_10_gesamt.svg, elements.2.elements.0.elements.2.attributes.stroke-linejoin
  gm_2_10_gesamt.svg, elements.2.elements.0.elements.3.attributes.stroke-linejoin
  gm_2_10_vor.svg, elements.2.elements.0.elements.2.attributes.stroke-linejoin
  gm_2_11_1.svg, elements.2.elements.4.attributes.stroke-linejoin
  gm_2_11_1.svg, elements.2.elements.7.attributes.stroke-linejoin
  gm_2_11_1.svg, elements.2.elements.8.attributes.stroke-linejoin
  gm_2_11_1.svg, elements.2.elements.9.attributes.stroke-linejoin
  gm_2_11_2.svg, elements.2.elements.0.elements.0.attributes.stroke-linejoin
  gm_2_11_2.svg, elements.2.elements.0.elements.1.attributes.stroke-linejoin
  gm_2_11_2.svg, elements.2.elements.0.elements.4.attributes.stroke-linejoin
  gm_2_11_3.svg, elements.2.elements.0.elements.3.attributes.stroke-linejoin
  gm_2_11_3.svg, elements.2.elements.0.elements.4.attributes.stroke-linejoin
  gm_2_11_3.svg, elements.2.elements.0.elements.5.attributes.stroke-linejoin
  gm_2_11_3.svg, elements.2.elements.0.elements.6.attributes.stroke-linejoin
  gm_2_11_3.svg, elements.2.elements.0.elements.7.attributes.stroke-linejoin
  gm_2_11_4.svg, elements.2.elements.4.attributes.stroke-linejoin
  gm_2_11_4.svg, elements.2.elements.7.attributes.stroke-linejoin
  gm_2_11_4.svg, elements.2.elements.8.attributes.stroke-linejoin
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.1.attributes.stroke-linejoin
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.2.attributes.stroke-linejoin
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.5.attributes.stroke-linejoin
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.6.attributes.stroke-linejoin
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.7.attributes.stroke-linejoin
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.8.attributes.stroke-linejoin
  gm_2_11_gesamt.svg, elements.2.elements.0.elements.9.attributes.stroke-linejoin
  gm_2_11_vor.svg, elements.2.elements.0.elements.1.attributes.stroke-linejoin
  gm_2_11_vor.svg, elements.2.elements.0.elements.2.attributes.stroke-linejoin
  gm_2_11_vor.svg, elements.2.elements.0.elements.5.attributes.stroke-linejoin
  gm_2_12_1.svg, elements.2.elements.3.attributes.stroke-linejoin
  gm_2_12_1.svg, elements.2.elements.4.attributes.stroke-linejoin
  gm_2_12_2.svg, elements.2.elements.3.attributes.stroke-linejoin
  gm_2_12_2.svg, elements.2.elements.4.attributes.stroke-linejoin
  gm_2_12_2.svg, elements.2.elements.5.attributes.stroke-linejoin
  gm_2_12_3.svg, elements.2.elements.3.attributes.stroke-linejoin
  gm_2_12_3.svg, elements.2.elements.4.attributes.stroke-linejoin
  gm_2_12_4.svg, elements.2.elements.3.attributes.stroke-linejoin
  gm_2_12_gesamt.svg, elements.2.elements.4.attributes.stroke-linejoin
  gm_2_12_gesamt.svg, elements.2.elements.5.attributes.stroke-linejoin
  gm_2_12_vor.svg, elements.2.elements.3.attributes.stroke-linejoin
  gm_2_2_1.svg, elements.2.elements.0.elements.2.attributes.stroke-linejoin
  gm_2_2_1.svg, elements.2.elements.0.elements.3.attributes.stroke-linejoin
  gm_2_2_2.svg, elements.2.elements.0.attributes.stroke-linejoin
  gm_2_2_2.svg, elements.2.elements.1.attributes.stroke-linejoin
  gm_2_2_2.svg, elements.2.elements.4.attributes.stroke-linejoin
  gm_2_2_3.svg, elements.2.elements.0.elements.0.attributes.stroke-linejoin
  gm_2_2_3.svg, elements.2.elements.0.elements.1.attributes.stroke-linejoin
  gm_2_2_4.svg, elements.2.elements.0.elements.0.attributes.stroke-linejoin
  gm_2_2_4.svg, elements.2.elements.0.elements.1.attributes.stroke-linejoin
  gm_2_2_gesamt.svg, elements.2.elements.0.elements.1.attributes.stroke-linejoin
  gm_2_2_gesamt.svg, elements.2.elements.0.elements.2.attributes.stroke-linejoin
  gm_2_3_1.svg, elements.2.elements.0.elements.0.attributes.stroke-linejoin
  gm_2_3_1.svg, elements.2.elements.0.elements.1.attributes.stroke-linejoin
  gm_2_3_2.svg, elements.2.elements.1.attributes.stroke-linejoin
  gm_2_3_2.svg, elements.2.elements.2.attributes.stroke-linejoin
  gm_2_3_3.svg, elements.2.elements.0.elements.0.attributes.stroke-linejoin
  gm_2_3_3.svg, elements.2.elements.0.elements.1.attributes.stroke-linejoin
  gm_2_3_4.svg, elements.2.elements.0.elements.0.attributes.stroke-linejoin
  gm_2_3_4.svg, elements.2.elements.0.elements.1.attributes.stroke-linejoin
  gm_2_3_gesamt.svg, elements.2.elements.0.elements.0.attributes.stroke-linejoin
  gm_2_3_gesamt.svg, elements.2.elements.0.elements.1.attributes.stroke-linejoin
  gm_2_3_gesamt.svg, elements.2.elements.0.elements.2.attributes.stroke-linejoin
  gm_2_3_gesamt.svg, elements.2.elements.0.elements.5.attributes.stroke-linejoin
  gm_2_3_vor.svg, elements.2.elements.0.elements.0.attributes.stroke-linejoin
  gm_2_3_vor.svg, elements.2.elements.0.elements.1.attributes.stroke-linejoin
  gm_2_4_1.svg, elements.2.elements.2.attributes.stroke-linejoin
  gm_2_4_2.svg, elements.2.elements.1.attributes.stroke-linejoin
  gm_2_4_3.svg, elements.2.elements.0.attributes.stroke-linejoin
  gm_2_4_3.svg, elements.2.elements.3.attributes.stroke-linejoin
  gm_2_4_3.svg, elements.2.elements.4.attributes.stroke-linejoin
  gm_2_4_4.svg, elements.2.elements.1.attributes.stroke-linejoin
  gm_2_4_4.svg, elements.2.elements.2.attributes.stroke-linejoin
  gm_2_4_gesamt.svg, elements.2.elements.0.attributes.stroke-linejoin
  gm_2_4_gesamt.svg, elements.2.elements.2.attributes.stroke-linejoin
  gm_2_4_vor.svg, elements.2.elements.0.elements.0.attributes.stroke-linejoin
  gm_2_5_1.svg, elements.2.elements.2.attributes.stroke-linejoin
  gm_2_5_1.svg, elements.2.elements.3.attributes.stroke-linejoin
  gm_2_5_2.svg, elements.2.elements.1.attributes.stroke-linejoin
  gm_2_5_2.svg, elements.2.elements.3.attributes.stroke-linejoin
  gm_2_5_2.svg, elements.2.elements.4.attributes.stroke-linejoin
  gm_2_5_3.svg, elements.2.elements.0.elements.1.attributes.stroke-linejoin
  gm_2_5_3.svg, elements.2.elements.0.elements.2.attributes.stroke-linejoin
  gm_2_5_4.svg, elements.2.elements.2.attributes.stroke-linejoin
  gm_2_5_4.svg, elements.2.elements.3.attributes.stroke-linejoin
  gm_2_5_4.svg, elements.2.elements.4.attributes.stroke-linejoin
  gm_2_5_gesamt.svg, elements.2.elements.0.attributes.stroke-linejoin
  gm_2_5_gesamt.svg, elements.2.elements.1.attributes.stroke-linejoin
  gm_2_5_gesamt.svg, elements.2.elements.4.attributes.stroke-linejoin
  gm_2_5_gesamt.svg, elements.2.elements.5.attributes.stroke-linejoin
  gm_2_5_vor.svg, elements.2.elements.0.attributes.stroke-linejoin
  gm_2_5_vor.svg, elements.2.elements.1.attributes.stroke-linejoin
  gm_2_8_1.svg, elements.2.elements.2.attributes.stroke-linejoin
  gm_2_8_1.svg, elements.2.elements.3.attributes.stroke-linejoin
  gm_2_8_1.svg, elements.2.elements.4.attributes.stroke-linejoin
  gm_2_8_1.svg, elements.2.elements.5.attributes.stroke-linejoin
  gm_2_8_2.svg, elements.2.elements.0.elements.2.attributes.stroke-linejoin
  gm_2_8_2.svg, elements.2.elements.0.elements.4.attributes.stroke-linejoin
  gm_2_8_2.svg, elements.2.elements.0.elements.5.attributes.stroke-linejoin
  gm_2_8_3.svg, elements.2.elements.2.attributes.stroke-linejoin
  gm_2_8_3.svg, elements.2.elements.3.attributes.stroke-linejoin
  gm_2_8_3.svg, elements.2.elements.4.attributes.stroke-linejoin
  gm_2_8_4.svg, elements.2.elements.0.elements.2.attributes.stroke-linejoin
  gm_2_8_4.svg, elements.2.elements.0.elements.3.attributes.stroke-linejoin
  gm_2_8_gesamt.svg, elements.2.elements.0.elements.3.attributes.stroke-linejoin
  gm_2_8_gesamt.svg, elements.2.elements.0.elements.4.attributes.stroke-linejoin
  gm_2_8_gesamt.svg, elements.2.elements.0.elements.5.attributes.stroke-linejoin
  gm_2_8_vor.svg, elements.2.elements.0.elements.3.attributes.stroke-linejoin
  ptv_2_1.svg, elements.2.elements.1.attributes.stroke-linejoin
  ptv_2_2.svg, elements.2.elements.0.elements.0.attributes.stroke-linejoin
  ptv_2_2.svg, elements.2.elements.0.elements.1.attributes.stroke-linejoin
  ptv_2_2.svg, elements.2.elements.0.elements.2.attributes.stroke-linejoin
  ptv_2_2.svg, elements.2.elements.0.elements.3.attributes.stroke-linejoin
  ptv_2_2.svg, elements.2.elements.2.attributes.stroke-linejoin
  ptv_2_3.svg, elements.2.elements.1.attributes.stroke-linejoin
  ptv_2_4.svg, elements.2.elements.2.attributes.stroke-linejoin
  ptv_4_1.svg, elements.2.elements.5.attributes.stroke-linejoin
  ptv_4_1.svg, elements.2.elements.12.attributes.stroke-linejoin
  ptv_4_1.svg, elements.2.elements.16.elements.0.attributes.stroke-linejoin
  ptv_4_2.svg, elements.2.elements.5.attributes.stroke-linejoin
  ptv_4_2.svg, elements.2.elements.12.attributes.stroke-linejoin
  ptv_4_2.svg, elements.2.elements.16.elements.0.attributes.stroke-linejoin
  ptv_4_3.svg, elements.2.elements.5.attributes.stroke-linejoin
  ptv_4_3.svg, elements.2.elements.12.attributes.stroke-linejoin
  ptv_4_3.svg, elements.2.elements.16.elements.0.attributes.stroke-linejoin
  ptv_4_4.svg, elements.2.elements.12.elements.0.attributes.stroke-linejoin
  rd_1_1_1.svg, elements.2.elements.0.attributes.stroke-linejoin
  rd_1_1_1.svg, elements.2.elements.1.attributes.stroke-linejoin
  rd_1_1_2.svg, elements.2.elements.0.attributes.stroke-linejoin
  rd_1_1_2.svg, elements.2.elements.1.attributes.stroke-linejoin
  rd_1_1_3.svg, elements.2.elements.0.attributes.stroke-linejoin
  rd_1_1_3.svg, elements.2.elements.1.attributes.stroke-linejoin
  rd_1_1_vor.svg, elements.2.elements.2.attributes.stroke-linejoin
  rd_1_2_1.svg, elements.2.elements.1.attributes.stroke-linejoin
  rd_1_2_1.svg, elements.2.elements.3.attributes.stroke-linejoin
  rd_1_2_1.svg, elements.2.elements.5.attributes.stroke-linejoin
  rd_1_2_1.svg, elements.2.elements.7.attributes.stroke-linejoin
  rd_1_2_1.svg, elements.2.elements.9.attributes.stroke-linejoin
  rd_1_2_1.svg, elements.2.elements.11.attributes.stroke-linejoin
  rd_1_2_1.svg, elements.2.elements.13.attributes.stroke-linejoin
  rd_1_2_3.svg, elements.2.elements.1.attributes.stroke-linejoin
  rd_1_2_3.svg, elements.2.elements.3.attributes.stroke-linejoin
  rd_1_2_3.svg, elements.2.elements.5.attributes.stroke-linejoin
  rd_1_2_3.svg, elements.2.elements.7.attributes.stroke-linejoin
  rd_1_2_3.svg, elements.2.elements.9.attributes.stroke-linejoin
  rd_1_2_3.svg, elements.2.elements.11.attributes.stroke-linejoin
  rd_1_2_3.svg, elements.2.elements.13.attributes.stroke-linejoin
  rd_1_2_3.svg, elements.2.elements.15.attributes.stroke-linejoin
  rd_1_2_vor.svg, elements.2.elements.6.attributes.stroke-linejoin
  rd_1_2_vor.svg, elements.2.elements.8.attributes.stroke-linejoin
  rd_1_3_1.svg, elements.2.elements.1.attributes.stroke-linejoin
  rd_1_3_1.svg, elements.2.elements.3.attributes.stroke-linejoin
  rd_1_3_1.svg, elements.2.elements.4.attributes.stroke-linejoin
  rd_1_3_1.svg, elements.2.elements.5.attributes.stroke-linejoin
  rd_1_3_2.svg, elements.2.elements.1.attributes.stroke-linejoin
  rd_1_3_2.svg, elements.2.elements.3.attributes.stroke-linejoin
  rd_1_3_2.svg, elements.2.elements.5.attributes.stroke-linejoin
  rd_1_3_2.svg, elements.2.elements.6.attributes.stroke-linejoin
  rd_1_3_2.svg, elements.2.elements.7.attributes.stroke-linejoin
  rd_1_3_3.svg, elements.2.elements.1.attributes.stroke-linejoin
  rd_1_3_3.svg, elements.2.elements.3.attributes.stroke-linejoin
  rd_1_3_3.svg, elements.2.elements.5.attributes.stroke-linejoin
  rd_1_3_3.svg, elements.2.elements.6.attributes.stroke-linejoin
  rd_1_3_3.svg, elements.2.elements.7.attributes.stroke-linejoin
  rd_1_3_vor.svg, elements.2.elements.3.attributes.stroke-linejoin
  rd_1_3_vor.svg, elements.2.elements.4.attributes.stroke-linejoin
  rd_1_3_vor.svg, elements.2.elements.5.attributes.stroke-linejoin
  rd_1_3_vor.svg, elements.2.elements.6.attributes.stroke-linejoin
  rd_1_4_1.svg, elements.2.elements.0.attributes.stroke-linejoin
  rd_1_4_1.svg, elements.2.elements.1.attributes.stroke-linejoin
  rd_1_4_1.svg, elements.2.elements.2.attributes.stroke-linejoin
  rd_1_4_1.svg, elements.2.elements.3.attributes.stroke-linejoin
  rd_1_4_1.svg, elements.2.elements.4.attributes.stroke-linejoin
  rd_1_4_1.svg, elements.2.elements.6.attributes.stroke-linejoin
  rd_1_4_1.svg, elements.2.elements.7.attributes.stroke-linejoin
  rd_1_4_2.svg, elements.2.elements.0.attributes.stroke-linejoin
  rd_1_4_2.svg, elements.2.elements.2.attributes.stroke-linejoin
  rd_1_4_2.svg, elements.2.elements.3.attributes.stroke-linejoin
  rd_1_4_2.svg, elements.2.elements.4.attributes.stroke-linejoin
  rd_1_4_2.svg, elements.2.elements.5.attributes.stroke-linejoin
  rd_1_4_3.svg, elements.2.elements.6.attributes.stroke-linejoin
  rd_1_4_vor.svg, elements.2.elements.1.elements.1.attributes.stroke-linejoin
  rd_1_4_vor.svg, elements.2.elements.1.elements.2.attributes.stroke-linejoin
  rd_1_4_vor.svg, elements.2.elements.1.elements.3.attributes.stroke-linejoin
  rd_1_4_vor.svg, elements.2.elements.1.elements.4.attributes.stroke-linejoin
  rd_1_beispiel.svg, elements.2.elements.0.elements.0.attributes.stroke-linejoin
  rd_1_beispiel.svg, elements.2.elements.0.elements.3.attributes.stroke-linejoin
  rd_1_beispiel.svg, elements.2.elements.0.elements.6.attributes.stroke-linejoin
  rd_1_beispiel.svg, elements.2.elements.0.elements.7.attributes.stroke-linejoin
  rd_1_beispiel.svg, elements.2.elements.0.elements.8.attributes.stroke-linejoin
  rd_1_beispiel_1.svg, elements.2.elements.0.attributes.stroke-linejoin
  rd_1_beispiel_1.svg, elements.2.elements.1.attributes.stroke-linejoin
  rd_1_beispiel_1.svg, elements.2.elements.2.attributes.stroke-linejoin
  rd_1_beispiel_1.svg, elements.2.elements.3.attributes.stroke-linejoin
  rd_1_beispiel_1.svg, elements.2.elements.4.attributes.stroke-linejoin
  rd_1_beispiel_2.svg, elements.2.elements.0.attributes.stroke-linejoin
  rd_1_beispiel_2.svg, elements.2.elements.1.attributes.stroke-linejoin
  rd_1_beispiel_2.svg, elements.2.elements.2.attributes.stroke-linejoin
  rd_1_beispiel_2.svg, elements.2.elements.3.attributes.stroke-linejoin
  rd_1_beispiel_2.svg, elements.2.elements.4.attributes.stroke-linejoin
  rd_1_beispiel_2.svg, elements.2.elements.5.attributes.stroke-linejoin
  rd_1_beispiel_2.svg, elements.2.elements.6.attributes.stroke-linejoin
  rd_1_beispiel_3.svg, elements.2.elements.0.attributes.stroke-linejoin
  rd_1_beispiel_3.svg, elements.2.elements.1.attributes.stroke-linejoin
  rd_1_beispiel_3.svg, elements.2.elements.2.attributes.stroke-linejoin
  rd_1_beispiel_3.svg, elements.2.elements.3.attributes.stroke-linejoin
  rd_1_beispiel_3.svg, elements.2.elements.4.attributes.stroke-linejoin
  rd_1_beispiel_3.svg, elements.2.elements.5.attributes.stroke-linejoin
  rd_2_1_vor.svg, elements.2.elements.0.elements.1.attributes.stroke-linejoin
  rd_2_1_vor.svg, elements.2.elements.0.elements.2.attributes.stroke-linejoin
  rd_2_1_vor.svg, elements.2.elements.0.elements.3.attributes.stroke-linejoin
  rd_2_1_vor.svg, elements.2.elements.2.elements.2.attributes.stroke-linejoin
  rd_2_1_vor.svg, elements.2.elements.2.elements.3.attributes.stroke-linejoin
  rd_2_1_vor.svg, elements.2.elements.3.elements.1.attributes.stroke-linejoin
  rd_2_1_vor.svg, elements.2.elements.3.elements.2.attributes.stroke-linejoin
  rd_2_1_vor.svg, elements.2.elements.4.elements.1.attributes.stroke-linejoin
  rd_2_1_vor.svg, elements.2.elements.4.elements.2.attributes.stroke-linejoin
  rd_2_1_vor.svg, elements.2.elements.5.elements.2.attributes.stroke-linejoin
  rd_2_1_vor.svg, elements.2.elements.5.elements.3.attributes.stroke-linejoin
  rd_2_1_vor.svg, elements.2.elements.5.elements.4.attributes.stroke-linejoin
  rd_2_1_vor.svg, elements.2.elements.6.elements.1.attributes.stroke-linejoin
  rd_2_1_vor.svg, elements.2.elements.6.elements.2.attributes.stroke-linejoin
  rd_2_1_vor.svg, elements.2.elements.6.elements.3.attributes.stroke-linejoin
  rd_2_1_vor.svg, elements.2.elements.7.elements.3.attributes.stroke-linejoin
  rd_2_1_vor.svg, elements.2.elements.7.elements.4.attributes.stroke-linejoin
  rd_2_1_vor.svg, elements.2.elements.7.elements.5.attributes.stroke-linejoin
  rd_2_1_vor.svg, elements.2.elements.8.elements.1.attributes.stroke-linejoin
  rd_2_1_vor.svg, elements.2.elements.8.elements.2.attributes.stroke-linejoin
  rd_2_1_vor.svg, elements.2.elements.9.elements.0.elements.1.attributes.stroke-linejoin
  rd_2_1_vor.svg, elements.2.elements.9.elements.0.elements.2.attributes.stroke-linejoin
  rd_2_1_vor.svg, elements.2.elements.9.elements.1.elements.1.attributes.stroke-linejoin
  rd_2_1_vor.svg, elements.2.elements.9.elements.1.elements.2.attributes.stroke-linejoin
  rd_2_1_vor.svg, elements.2.elements.9.elements.1.elements.3.attributes.stroke-linejoin
  rd_2_1_vor.svg, elements.2.elements.9.elements.2.elements.3.attributes.stroke-linejoin
  rd_2_1_vor.svg, elements.2.elements.9.elements.2.elements.4.attributes.stroke-linejoin
  rd_2_1_vor.svg, elements.2.elements.9.elements.2.elements.5.attributes.stroke-linejoin
  rd_2_1_vor.svg, elements.2.elements.10.elements.1.attributes.stroke-linejoin
  rd_2_1_vor.svg, elements.2.elements.10.elements.2.attributes.stroke-linejoin
  rd_2_1_vor.svg, elements.2.elements.10.elements.3.attributes.stroke-linejoin
  rd_2_1_vor.svg, elements.2.elements.11.elements.3.attributes.stroke-linejoin
  rd_2_1_vor.svg, elements.2.elements.11.elements.4.attributes.stroke-linejoin
  rd_2_1_vor.svg, elements.2.elements.11.elements.5.attributes.stroke-linejoin
  rd_2_1_vor.svg, elements.2.elements.12.elements.2.attributes.stroke-linejoin
  rd_2_1_vor.svg, elements.2.elements.12.elements.3.attributes.stroke-linejoin
  rd_2_1_vor.svg, elements.2.elements.12.elements.4.attributes.stroke-linejoin
  rd_2_1_vor.svg, elements.2.elements.13.elements.2.attributes.stroke-linejoin
  rd_2_1_vor.svg, elements.2.elements.13.elements.3.attributes.stroke-linejoin
  rd_2_1_vor.svg, elements.2.elements.13.elements.4.attributes.stroke-linejoin
  rd_2_1_vor.svg, elements.2.elements.14.elements.3.attributes.stroke-linejoin
  rd_2_1_vor.svg, elements.2.elements.14.elements.4.attributes.stroke-linejoin
  rd_2_1_vor.svg, elements.2.elements.14.elements.5.attributes.stroke-linejoin
  rd_2_2_vor.svg, elements.2.elements.0.elements.1.attributes.stroke-linejoin
  rd_2_2_vor.svg, elements.2.elements.0.elements.2.attributes.stroke-linejoin
  rd_2_2_vor.svg, elements.2.elements.1.elements.1.attributes.stroke-linejoin
  rd_2_2_vor.svg, elements.2.elements.1.elements.2.attributes.stroke-linejoin
  rd_2_2_vor.svg, elements.2.elements.2.elements.1.attributes.stroke-linejoin
  rd_2_2_vor.svg, elements.2.elements.2.elements.2.attributes.stroke-linejoin
  rd_2_2_vor.svg, elements.2.elements.3.elements.1.attributes.stroke-linejoin
  rd_2_2_vor.svg, elements.2.elements.3.elements.2.attributes.stroke-linejoin
  rd_2_2_vor.svg, elements.2.elements.4.elements.1.attributes.stroke-linejoin
  rd_2_2_vor.svg, elements.2.elements.4.elements.2.attributes.stroke-linejoin
  rd_2_2_vor.svg, elements.2.elements.5.elements.2.attributes.stroke-linejoin
  rd_2_2_vor.svg, elements.2.elements.5.elements.3.attributes.stroke-linejoin
  rd_2_2_vor.svg, elements.2.elements.6.elements.2.attributes.stroke-linejoin
  rd_2_2_vor.svg, elements.2.elements.6.elements.3.attributes.stroke-linejoin
  rd_2_2_vor.svg, elements.2.elements.6.elements.4.attributes.stroke-linejoin
  rd_2_2_vor.svg, elements.2.elements.7.elements.2.attributes.stroke-linejoin
  rd_2_2_vor.svg, elements.2.elements.7.elements.3.attributes.stroke-linejoin
  rd_2_2_vor.svg, elements.2.elements.7.elements.4.attributes.stroke-linejoin
  rd_2_2_vor.svg, elements.2.elements.8.elements.2.attributes.stroke-linejoin
  rd_2_2_vor.svg, elements.2.elements.8.elements.3.attributes.stroke-linejoin
  rd_2_2_vor.svg, elements.2.elements.8.elements.4.attributes.stroke-linejoin
  rd_2_2_vor.svg, elements.2.elements.9.elements.2.attributes.stroke-linejoin
  rd_2_2_vor.svg, elements.2.elements.9.elements.3.attributes.stroke-linejoin
  rd_2_2_vor.svg, elements.2.elements.9.elements.4.attributes.stroke-linejoin
  rd_2_2_vor.svg, elements.2.elements.10.elements.2.attributes.stroke-linejoin
  rd_2_2_vor.svg, elements.2.elements.10.elements.3.attributes.stroke-linejoin
  rd_2_2_vor.svg, elements.2.elements.10.elements.4.attributes.stroke-linejoin
  rd_2_2_vor.svg, elements.2.elements.11.elements.3.attributes.stroke-linejoin
  rd_2_2_vor.svg, elements.2.elements.11.elements.4.attributes.stroke-linejoin
  rd_2_2_vor.svg, elements.2.elements.11.elements.5.attributes.stroke-linejoin
  rd_2_2_vor.svg, elements.2.elements.12.elements.3.attributes.stroke-linejoin
  rd_2_2_vor.svg, elements.2.elements.12.elements.4.attributes.stroke-linejoin
  rd_2_2_vor.svg, elements.2.elements.12.elements.5.attributes.stroke-linejoin
  rd_2_3_vor.svg, elements.2.elements.0.elements.1.attributes.stroke-linejoin
  rd_2_3_vor.svg, elements.2.elements.1.elements.2.attributes.stroke-linejoin
  rd_2_3_vor.svg, elements.2.elements.1.elements.3.attributes.stroke-linejoin
  rd_2_3_vor.svg, elements.2.elements.1.elements.4.attributes.stroke-linejoin
  rd_2_3_vor.svg, elements.2.elements.2.elements.2.attributes.stroke-linejoin
  rd_2_3_vor.svg, elements.2.elements.2.elements.3.attributes.stroke-linejoin
  rd_2_3_vor.svg, elements.2.elements.2.elements.4.attributes.stroke-linejoin
  rd_2_3_vor.svg, elements.2.elements.3.elements.2.attributes.stroke-linejoin
  rd_2_3_vor.svg, elements.2.elements.3.elements.3.attributes.stroke-linejoin
  rd_2_3_vor.svg, elements.2.elements.4.elements.1.attributes.stroke-linejoin
  rd_2_3_vor.svg, elements.2.elements.4.elements.2.attributes.stroke-linejoin
  rd_2_3_vor.svg, elements.2.elements.5.elements.2.attributes.stroke-linejoin
  rd_2_3_vor.svg, elements.2.elements.5.elements.3.attributes.stroke-linejoin
  rd_2_3_vor.svg, elements.2.elements.6.elements.2.attributes.stroke-linejoin
  rd_2_3_vor.svg, elements.2.elements.6.elements.3.attributes.stroke-linejoin
  rd_2_3_vor.svg, elements.2.elements.7.elements.1.attributes.stroke-linejoin
  rd_2_3_vor.svg, elements.2.elements.7.elements.2.attributes.stroke-linejoin
  rd_2_3_vor.svg, elements.2.elements.8.elements.2.attributes.stroke-linejoin
  rd_2_3_vor.svg, elements.2.elements.8.elements.3.attributes.stroke-linejoin
  rd_2_3_vor.svg, elements.2.elements.8.elements.4.attributes.stroke-linejoin
  rd_2_3_vor.svg, elements.2.elements.9.elements.2.attributes.stroke-linejoin
  rd_2_3_vor.svg, elements.2.elements.9.elements.3.attributes.stroke-linejoin
  rd_2_3_vor.svg, elements.2.elements.10.elements.1.attributes.stroke-linejoin
  rd_2_3_vor.svg, elements.2.elements.10.elements.2.attributes.stroke-linejoin
  rd_2_3_vor.svg, elements.2.elements.10.elements.3.attributes.stroke-linejoin
  rd_2_3_vor.svg, elements.2.elements.11.elements.3.attributes.stroke-linejoin
  rd_2_3_vor.svg, elements.2.elements.11.elements.4.attributes.stroke-linejoin
  rd_2_3_vor.svg, elements.2.elements.11.elements.5.attributes.stroke-linejoin
  rd_2_3_vor.svg, elements.2.elements.12.elements.3.attributes.stroke-linejoin
  rd_2_3_vor.svg, elements.2.elements.12.elements.4.attributes.stroke-linejoin
  rd_2_3_vor.svg, elements.2.elements.12.elements.5.attributes.stroke-linejoin
  rd_2_4_vor.svg, elements.2.elements.0.elements.2.attributes.stroke-linejoin
  rd_2_4_vor.svg, elements.2.elements.0.elements.3.attributes.stroke-linejoin
  rd_2_4_vor.svg, elements.2.elements.0.elements.4.attributes.stroke-linejoin
  rd_2_4_vor.svg, elements.2.elements.1.elements.3.attributes.stroke-linejoin
  rd_2_4_vor.svg, elements.2.elements.1.elements.4.attributes.stroke-linejoin
  rd_2_4_vor.svg, elements.2.elements.1.elements.5.attributes.stroke-linejoin
  rd_2_4_vor.svg, elements.2.elements.2.elements.1.attributes.stroke-linejoin
  rd_2_4_vor.svg, elements.2.elements.2.elements.2.attributes.stroke-linejoin
  rd_2_4_vor.svg, elements.2.elements.3.elements.2.attributes.stroke-linejoin
  rd_2_4_vor.svg, elements.2.elements.3.elements.3.attributes.stroke-linejoin
  rd_2_4_vor.svg, elements.2.elements.3.elements.4.attributes.stroke-linejoin
  rd_2_4_vor.svg, elements.2.elements.4.elements.1.attributes.stroke-linejoin
  rd_2_4_vor.svg, elements.2.elements.4.elements.2.attributes.stroke-linejoin
  rd_2_4_vor.svg, elements.2.elements.5.elements.1.attributes.stroke-linejoin
  rd_2_4_vor.svg, elements.2.elements.5.elements.2.attributes.stroke-linejoin
  rd_2_4_vor.svg, elements.2.elements.6.elements.2.attributes.stroke-linejoin
  rd_2_4_vor.svg, elements.2.elements.6.elements.3.attributes.stroke-linejoin
  rd_2_4_vor.svg, elements.2.elements.6.elements.4.attributes.stroke-linejoin
  rd_2_4_vor.svg, elements.2.elements.7.elements.2.attributes.stroke-linejoin
  rd_2_4_vor.svg, elements.2.elements.7.elements.3.attributes.stroke-linejoin
  rd_2_4_vor.svg, elements.2.elements.7.elements.4.attributes.stroke-linejoin
  rd_2_4_vor.svg, elements.2.elements.8.elements.2.attributes.stroke-linejoin
  rd_2_4_vor.svg, elements.2.elements.8.elements.3.attributes.stroke-linejoin
  rd_2_4_vor.svg, elements.2.elements.8.elements.4.attributes.stroke-linejoin
  rd_2_4_vor.svg, elements.2.elements.9.elements.3.attributes.stroke-linejoin
  rd_2_4_vor.svg, elements.2.elements.9.elements.4.attributes.stroke-linejoin
  rd_2_4_vor.svg, elements.2.elements.9.elements.5.attributes.stroke-linejoin
  rd_2_5_vor.svg, elements.2.elements.0.elements.2.attributes.stroke-linejoin
  rd_2_5_vor.svg, elements.2.elements.0.elements.3.attributes.stroke-linejoin
  rd_2_5_vor.svg, elements.2.elements.0.elements.4.attributes.stroke-linejoin
  rd_2_5_vor.svg, elements.2.elements.1.elements.2.attributes.stroke-linejoin
  rd_2_5_vor.svg, elements.2.elements.1.elements.3.attributes.stroke-linejoin
  rd_2_5_vor.svg, elements.2.elements.1.elements.4.attributes.stroke-linejoin
  rd_2_5_vor.svg, elements.2.elements.2.elements.1.attributes.stroke-linejoin
  rd_2_5_vor.svg, elements.2.elements.2.elements.2.attributes.stroke-linejoin
  rd_2_5_vor.svg, elements.2.elements.3.elements.2.attributes.stroke-linejoin
  rd_2_5_vor.svg, elements.2.elements.3.elements.3.attributes.stroke-linejoin
  rd_2_5_vor.svg, elements.2.elements.4.elements.3.attributes.stroke-linejoin
  rd_2_5_vor.svg, elements.2.elements.4.elements.4.attributes.stroke-linejoin
  rd_2_5_vor.svg, elements.2.elements.4.elements.5.attributes.stroke-linejoin
  rd_2_5_vor.svg, elements.2.elements.5.elements.2.attributes.stroke-linejoin
  rd_2_5_vor.svg, elements.2.elements.5.elements.3.attributes.stroke-linejoin
  rd_2_5_vor.svg, elements.2.elements.6.elements.2.attributes.stroke-linejoin
  rd_2_5_vor.svg, elements.2.elements.6.elements.3.attributes.stroke-linejoin
  rd_2_5_vor.svg, elements.2.elements.6.elements.4.attributes.stroke-linejoin
  rd_2_5_vor.svg, elements.2.elements.7.elements.2.attributes.stroke-linejoin
  rd_2_5_vor.svg, elements.2.elements.7.elements.3.attributes.stroke-linejoin
  rd_2_5_vor.svg, elements.2.elements.7.elements.4.attributes.stroke-linejoin
  rd_2_5_vor.svg, elements.2.elements.8.elements.2.attributes.stroke-linejoin
  rd_2_5_vor.svg, elements.2.elements.8.elements.3.attributes.stroke-linejoin
  rd_2_5_vor.svg, elements.2.elements.8.elements.4.attributes.stroke-linejoin
  rd_2_5_vor.svg, elements.2.elements.9.elements.3.attributes.stroke-linejoin
  rd_2_5_vor.svg, elements.2.elements.9.elements.4.attributes.stroke-linejoin
  rd_2_5_vor.svg, elements.2.elements.9.elements.5.attributes.stroke-linejoin
  rd_2_intro.svg, elements.2.elements.0.elements.1.attributes.stroke-linejoin
  rd_2_intro.svg, elements.2.elements.0.elements.2.attributes.stroke-linejoin
  rd_2_intro.svg, elements.2.elements.1.elements.1.attributes.stroke-linejoin
  rd_2_intro.svg, elements.2.elements.1.elements.2.attributes.stroke-linejoin
  rd_2_intro.svg, elements.2.elements.2.elements.1.attributes.stroke-linejoin
  rd_2_intro.svg, elements.2.elements.2.elements.2.attributes.stroke-linejoin
  rd_2_intro.svg, elements.2.elements.2.elements.3.attributes.stroke-linejoin
  rd_2_intro.svg, elements.2.elements.3.elements.1.attributes.stroke-linejoin
  rd_2_intro.svg, elements.2.elements.3.elements.2.attributes.stroke-linejoin
  rd_2_intro.svg, elements.2.elements.3.elements.3.attributes.stroke-linejoin
  rd_2_intro.svg, elements.2.elements.4.elements.2.attributes.stroke-linejoin
  rd_2_intro.svg, elements.2.elements.4.elements.3.attributes.stroke-linejoin
  rd_2_intro.svg, elements.2.elements.5.elements.1.attributes.stroke-linejoin
  rd_2_intro.svg, elements.2.elements.5.elements.2.attributes.stroke-linejoin
  rd_2_intro.svg, elements.2.elements.6.elements.2.attributes.stroke-linejoin
  rd_2_intro.svg, elements.2.elements.6.elements.3.attributes.stroke-linejoin
  rd_2_intro.svg, elements.2.elements.7.elements.1.attributes.stroke-linejoin
  rd_2_intro.svg, elements.2.elements.8.elements.1.attributes.stroke-linejoin
  rd_2_intro.svg, elements.2.elements.9.elements.2.attributes.stroke-linejoin
  rd_2_intro.svg, elements.2.elements.9.elements.3.attributes.stroke-linejoin
  rd_2_intro.svg, elements.2.elements.9.elements.4.attributes.stroke-linejoin
  rd_2_intro.svg, elements.2.elements.10.elements.1.attributes.stroke-linejoin
  rd_2_intro.svg, elements.2.elements.10.elements.2.attributes.stroke-linejoin
  rd_2_intro.svg, elements.2.elements.11.elements.1.attributes.stroke-linejoin
  rd_2_intro.svg, elements.2.elements.11.elements.2.attributes.stroke-linejoin
  rd_2_intro.svg, elements.2.elements.12.elements.2.attributes.stroke-linejoin
  rd_2_intro.svg, elements.2.elements.12.elements.3.attributes.stroke-linejoin
  rd_2_intro.svg, elements.2.elements.12.elements.4.attributes.stroke-linejoin
  rd_2_intro.svg, elements.2.elements.13.elements.2.attributes.stroke-linejoin
  rd_2_intro.svg, elements.2.elements.13.elements.3.attributes.stroke-linejoin
  rd_2_intro.svg, elements.2.elements.14.elements.1.attributes.stroke-linejoin
  rd_2_intro.svg, elements.2.elements.14.elements.2.attributes.stroke-linejoin
  rd_2_intro.svg, elements.2.elements.15.elements.2.attributes.stroke-linejoin
  rd_2_intro.svg, elements.2.elements.15.elements.3.attributes.stroke-linejoin
  rd_2_intro.svg, elements.2.elements.16.elements.2.attributes.stroke-linejoin
  rd_2_intro.svg, elements.2.elements.16.elements.3.attributes.stroke-linejoin
  rd_2_intro.svg, elements.2.elements.17.elements.2.attributes.stroke-linejoin
  rd_2_intro.svg, elements.2.elements.17.elements.3.attributes.stroke-linejoin
  rd_2_intro.svg, elements.2.elements.18.elements.3.attributes.stroke-linejoin
  rd_2_intro.svg, elements.2.elements.18.elements.4.attributes.stroke-linejoin
  rd_2_intro.svg, elements.2.elements.18.elements.5.attributes.stroke-linejoin
  */
  stroke-linejoin: round;
}

.svg-qOnXIp44qtc{
  /*
  Used In:
  gm_2_11_2.svg, elements.2.elements.1.elements.0.attributes.stroke
  gm_2_11_2.svg, elements.2.elements.1.elements.1.attributes.stroke
  gm_2_11_3.svg, elements.2.elements.1.elements.0.attributes.stroke
  gm_2_11_3.svg, elements.2.elements.1.elements.1.attributes.stroke
  gm_2_11_gesamt.svg, elements.2.elements.1.elements.0.attributes.stroke
  gm_2_11_gesamt.svg, elements.2.elements.1.elements.1.attributes.stroke
  gm_2_11_vor.svg, elements.2.elements.1.elements.0.attributes.stroke
  gm_2_11_vor.svg, elements.2.elements.1.elements.1.attributes.stroke
  */
  stroke: #99FFFF;
}

.svg-XL_GS5-6qqL{
  /*
  Used In:
  gm_2_11_2.svg, elements.2.elements.1.elements.0.attributes.stroke-width
  gm_2_11_2.svg, elements.2.elements.1.elements.1.attributes.stroke-width
  gm_2_11_3.svg, elements.2.elements.1.elements.0.attributes.stroke-width
  gm_2_11_3.svg, elements.2.elements.1.elements.1.attributes.stroke-width
  gm_2_11_gesamt.svg, elements.2.elements.1.elements.0.attributes.stroke-width
  gm_2_11_gesamt.svg, elements.2.elements.1.elements.1.attributes.stroke-width
  gm_2_11_vor.svg, elements.2.elements.1.elements.0.attributes.stroke-width
  gm_2_11_vor.svg, elements.2.elements.1.elements.1.attributes.stroke-width
  */
  stroke-width: 0.25;
}

.svg-DUsG7rzzZcS{
  /*
  Used In:
  ptv_1_1.svg, elements.2.elements.1.elements.0.attributes.fill
  ptv_1_1.svg, elements.2.elements.1.elements.1.elements.0.attributes.fill
  ptv_1_1.svg, elements.2.elements.3.elements.0.attributes.fill
  ptv_1_1.svg, elements.2.elements.4.elements.0.attributes.fill
  ptv_1_1.svg, elements.2.elements.6.attributes.fill
  ptv_1_1.svg, elements.2.elements.8.elements.0.attributes.fill
  ptv_1_2.svg, elements.2.elements.1.elements.0.attributes.fill
  ptv_1_2.svg, elements.2.elements.1.elements.1.elements.0.attributes.fill
  ptv_1_2.svg, elements.2.elements.3.elements.0.attributes.fill
  ptv_1_2.svg, elements.2.elements.4.elements.0.attributes.fill
  ptv_1_2.svg, elements.2.elements.7.elements.0.attributes.fill
  ptv_1_2.svg, elements.2.elements.9.attributes.fill
  ptv_1_3.svg, elements.2.elements.0.elements.0.attributes.fill
  ptv_1_3.svg, elements.2.elements.0.elements.1.elements.0.attributes.fill
  ptv_1_3.svg, elements.2.elements.3.elements.0.attributes.fill
  ptv_1_3.svg, elements.2.elements.4.elements.0.attributes.fill
  ptv_1_3.svg, elements.2.elements.6.attributes.fill
  ptv_1_3.svg, elements.2.elements.8.elements.0.attributes.fill
  ptv_5.svg, elements.2.elements.0.elements.0.attributes.fill
  ptv_5.svg, elements.2.elements.1.elements.0.attributes.fill
  ptv_5.svg, elements.2.elements.2.elements.0.attributes.fill
  rd_1_3_vor.svg, elements.2.elements.1.attributes.fill
  */
  fill: #9B9B9B;
}

.svg-_rQeK27Lnq3{
  /*
  Used In:
  ptv_1_1.svg, elements.2.elements.5.elements.0.attributes.fill
  ptv_1_3.svg, elements.2.elements.5.elements.0.attributes.fill
  ptv_3.svg, elements.2.elements.6.elements.0.elements.0.attributes.fill
  ptv_3.svg, elements.2.elements.6.elements.1.attributes.fill
  ptv_3.svg, elements.2.elements.7.elements.0.elements.0.attributes.fill
  ptv_3.svg, elements.2.elements.7.elements.1.attributes.fill
  */
  fill: #565655;
}

.svg-O2QLeU9C17T{
  /*
  Used In:
  ptv_1_1.svg, elements.2.elements.9.attributes.fill
  ptv_1_3.svg, elements.2.elements.9.attributes.fill
  ptv_3.svg, elements.2.elements.3.attributes.fill
  ptv_3.svg, elements.2.elements.4.attributes.fill
  ptv_3.svg, elements.2.elements.5.attributes.fill
  */
  fill: #575756;
}

.svg-oFBWtSYfrCQ{
  /*
  Used In:
  ptv_1_1.svg, elements.2.elements.0.attributes.stroke
  ptv_1_1.svg, elements.2.elements.2.attributes.stroke
  ptv_1_1.svg, elements.2.elements.7.attributes.stroke
  ptv_1_2.svg, elements.2.elements.6.attributes.stroke
  ptv_1_3.svg, elements.2.elements.1.attributes.stroke
  ptv_1_3.svg, elements.2.elements.2.attributes.stroke
  ptv_1_3.svg, elements.2.elements.7.attributes.stroke
  ptv_4_1.svg, elements.2.elements.12.attributes.stroke
  ptv_4_1.svg, elements.2.elements.13.attributes.stroke
  ptv_4_3.svg, elements.2.elements.12.attributes.stroke
  ptv_4_3.svg, elements.2.elements.13.attributes.stroke
  ptv_4_4.svg, elements.2.elements.3.attributes.stroke
  ptv_5.svg, elements.2.elements.0.elements.1.attributes.stroke
  ptv_5.svg, elements.2.elements.0.elements.2.attributes.stroke
  ptv_5.svg, elements.2.elements.0.elements.3.attributes.stroke
  ptv_5.svg, elements.2.elements.0.elements.4.attributes.stroke
  ptv_5.svg, elements.2.elements.0.elements.5.attributes.stroke
  ptv_5.svg, elements.2.elements.0.elements.6.attributes.stroke
  ptv_5.svg, elements.2.elements.0.elements.7.attributes.stroke
  ptv_5.svg, elements.2.elements.0.elements.8.attributes.stroke
  ptv_5.svg, elements.2.elements.0.elements.9.attributes.stroke
  ptv_5.svg, elements.2.elements.0.elements.10.attributes.stroke
  ptv_5.svg, elements.2.elements.1.elements.1.attributes.stroke
  ptv_5.svg, elements.2.elements.1.elements.2.attributes.stroke
  ptv_5.svg, elements.2.elements.1.elements.3.attributes.stroke
  ptv_5.svg, elements.2.elements.1.elements.4.attributes.stroke
  ptv_5.svg, elements.2.elements.1.elements.5.attributes.stroke
  ptv_5.svg, elements.2.elements.1.elements.6.attributes.stroke
  ptv_5.svg, elements.2.elements.1.elements.7.attributes.stroke
  ptv_5.svg, elements.2.elements.1.elements.8.attributes.stroke
  ptv_5.svg, elements.2.elements.1.elements.9.attributes.stroke
  ptv_5.svg, elements.2.elements.1.elements.10.attributes.stroke
  ptv_5.svg, elements.2.elements.2.elements.1.attributes.stroke
  ptv_5.svg, elements.2.elements.2.elements.2.attributes.stroke
  ptv_5.svg, elements.2.elements.2.elements.3.attributes.stroke
  ptv_5.svg, elements.2.elements.2.elements.4.attributes.stroke
  ptv_5.svg, elements.2.elements.2.elements.5.attributes.stroke
  ptv_5.svg, elements.2.elements.2.elements.6.attributes.stroke
  ptv_5.svg, elements.2.elements.2.elements.7.attributes.stroke
  ptv_5.svg, elements.2.elements.2.elements.8.attributes.stroke
  ptv_5.svg, elements.2.elements.2.elements.9.attributes.stroke
  ptv_5.svg, elements.2.elements.2.elements.10.attributes.stroke
  rd_1_1_1.svg, elements.2.elements.0.attributes.stroke
  rd_1_1_1.svg, elements.2.elements.1.attributes.stroke
  rd_1_1_2.svg, elements.2.elements.0.attributes.stroke
  rd_1_1_2.svg, elements.2.elements.1.attributes.stroke
  rd_1_1_3.svg, elements.2.elements.0.attributes.stroke
  rd_1_1_3.svg, elements.2.elements.1.attributes.stroke
  rd_1_3_vor.svg, elements.2.elements.3.attributes.stroke
  rd_1_3_vor.svg, elements.2.elements.4.attributes.stroke
  rd_1_3_vor.svg, elements.2.elements.5.attributes.stroke
  rd_1_3_vor.svg, elements.2.elements.6.attributes.stroke
  rd_1_4_2.svg, elements.2.elements.0.attributes.stroke
  rd_1_4_2.svg, elements.2.elements.2.attributes.stroke
  rd_1_4_2.svg, elements.2.elements.3.attributes.stroke
  rd_1_4_2.svg, elements.2.elements.4.attributes.stroke
  rd_1_4_2.svg, elements.2.elements.5.attributes.stroke
  rd_1_4_3.svg, elements.2.elements.1.attributes.stroke
  rd_1_4_3.svg, elements.2.elements.2.attributes.stroke
  rd_1_4_3.svg, elements.2.elements.3.attributes.stroke
  rd_1_4_3.svg, elements.2.elements.4.attributes.stroke
  rd_1_4_3.svg, elements.2.elements.6.attributes.stroke
  rd_1_4_3.svg, elements.2.elements.7.attributes.stroke
  rd_1_4_3.svg, elements.2.elements.8.attributes.stroke
  rd_1_4_vor.svg, elements.2.elements.1.elements.0.attributes.stroke
  rd_1_4_vor.svg, elements.2.elements.1.elements.1.attributes.stroke
  rd_1_4_vor.svg, elements.2.elements.1.elements.2.attributes.stroke
  rd_1_4_vor.svg, elements.2.elements.1.elements.3.attributes.stroke
  rd_1_4_vor.svg, elements.2.elements.1.elements.4.attributes.stroke
  rd_1_beispiel.svg, elements.2.elements.0.elements.0.attributes.stroke
  rd_1_beispiel.svg, elements.2.elements.0.elements.3.attributes.stroke
  rd_1_beispiel.svg, elements.2.elements.0.elements.6.attributes.stroke
  rd_1_beispiel.svg, elements.2.elements.0.elements.7.attributes.stroke
  rd_1_beispiel.svg, elements.2.elements.0.elements.8.attributes.stroke
  rd_1_beispiel_1.svg, elements.2.elements.0.attributes.stroke
  rd_1_beispiel_1.svg, elements.2.elements.1.attributes.stroke
  rd_1_beispiel_1.svg, elements.2.elements.2.attributes.stroke
  rd_1_beispiel_1.svg, elements.2.elements.3.attributes.stroke
  rd_1_beispiel_1.svg, elements.2.elements.4.attributes.stroke
  rd_1_beispiel_2.svg, elements.2.elements.0.attributes.stroke
  rd_1_beispiel_2.svg, elements.2.elements.1.attributes.stroke
  rd_1_beispiel_2.svg, elements.2.elements.2.attributes.stroke
  rd_1_beispiel_2.svg, elements.2.elements.3.attributes.stroke
  rd_1_beispiel_2.svg, elements.2.elements.4.attributes.stroke
  rd_1_beispiel_2.svg, elements.2.elements.5.attributes.stroke
  rd_1_beispiel_2.svg, elements.2.elements.6.attributes.stroke
  rd_1_beispiel_3.svg, elements.2.elements.0.attributes.stroke
  rd_1_beispiel_3.svg, elements.2.elements.1.attributes.stroke
  rd_1_beispiel_3.svg, elements.2.elements.2.attributes.stroke
  rd_1_beispiel_3.svg, elements.2.elements.3.attributes.stroke
  rd_1_beispiel_3.svg, elements.2.elements.4.attributes.stroke
  rd_1_beispiel_3.svg, elements.2.elements.5.attributes.stroke
  rd_2_1_vor.svg, elements.2.elements.0.elements.1.attributes.stroke
  rd_2_1_vor.svg, elements.2.elements.0.elements.2.attributes.stroke
  rd_2_1_vor.svg, elements.2.elements.0.elements.3.attributes.stroke
  rd_2_1_vor.svg, elements.2.elements.2.elements.2.attributes.stroke
  rd_2_1_vor.svg, elements.2.elements.2.elements.3.attributes.stroke
  rd_2_1_vor.svg, elements.2.elements.3.elements.1.attributes.stroke
  rd_2_1_vor.svg, elements.2.elements.3.elements.2.attributes.stroke
  rd_2_1_vor.svg, elements.2.elements.4.elements.1.attributes.stroke
  rd_2_1_vor.svg, elements.2.elements.4.elements.2.attributes.stroke
  rd_2_1_vor.svg, elements.2.elements.5.elements.2.attributes.stroke
  rd_2_1_vor.svg, elements.2.elements.5.elements.3.attributes.stroke
  rd_2_1_vor.svg, elements.2.elements.5.elements.4.attributes.stroke
  rd_2_1_vor.svg, elements.2.elements.6.elements.1.attributes.stroke
  rd_2_1_vor.svg, elements.2.elements.6.elements.2.attributes.stroke
  rd_2_1_vor.svg, elements.2.elements.6.elements.3.attributes.stroke
  rd_2_1_vor.svg, elements.2.elements.7.elements.3.attributes.stroke
  rd_2_1_vor.svg, elements.2.elements.7.elements.4.attributes.stroke
  rd_2_1_vor.svg, elements.2.elements.7.elements.5.attributes.stroke
  rd_2_1_vor.svg, elements.2.elements.8.elements.1.attributes.stroke
  rd_2_1_vor.svg, elements.2.elements.8.elements.2.attributes.stroke
  rd_2_1_vor.svg, elements.2.elements.9.elements.0.elements.1.attributes.stroke
  rd_2_1_vor.svg, elements.2.elements.9.elements.0.elements.2.attributes.stroke
  rd_2_1_vor.svg, elements.2.elements.9.elements.1.elements.1.attributes.stroke
  rd_2_1_vor.svg, elements.2.elements.9.elements.1.elements.2.attributes.stroke
  rd_2_1_vor.svg, elements.2.elements.9.elements.1.elements.3.attributes.stroke
  rd_2_1_vor.svg, elements.2.elements.9.elements.2.elements.3.attributes.stroke
  rd_2_1_vor.svg, elements.2.elements.9.elements.2.elements.4.attributes.stroke
  rd_2_1_vor.svg, elements.2.elements.9.elements.2.elements.5.attributes.stroke
  rd_2_1_vor.svg, elements.2.elements.10.elements.1.attributes.stroke
  rd_2_1_vor.svg, elements.2.elements.10.elements.2.attributes.stroke
  rd_2_1_vor.svg, elements.2.elements.10.elements.3.attributes.stroke
  rd_2_1_vor.svg, elements.2.elements.11.elements.3.attributes.stroke
  rd_2_1_vor.svg, elements.2.elements.11.elements.4.attributes.stroke
  rd_2_1_vor.svg, elements.2.elements.11.elements.5.attributes.stroke
  rd_2_1_vor.svg, elements.2.elements.12.elements.2.attributes.stroke
  rd_2_1_vor.svg, elements.2.elements.12.elements.3.attributes.stroke
  rd_2_1_vor.svg, elements.2.elements.12.elements.4.attributes.stroke
  rd_2_1_vor.svg, elements.2.elements.13.elements.2.attributes.stroke
  rd_2_1_vor.svg, elements.2.elements.13.elements.3.attributes.stroke
  rd_2_1_vor.svg, elements.2.elements.13.elements.4.attributes.stroke
  rd_2_1_vor.svg, elements.2.elements.14.elements.3.attributes.stroke
  rd_2_1_vor.svg, elements.2.elements.14.elements.4.attributes.stroke
  rd_2_1_vor.svg, elements.2.elements.14.elements.5.attributes.stroke
  rd_2_2_vor.svg, elements.2.elements.0.elements.1.attributes.stroke
  rd_2_2_vor.svg, elements.2.elements.0.elements.2.attributes.stroke
  rd_2_2_vor.svg, elements.2.elements.1.elements.1.attributes.stroke
  rd_2_2_vor.svg, elements.2.elements.1.elements.2.attributes.stroke
  rd_2_2_vor.svg, elements.2.elements.2.elements.1.attributes.stroke
  rd_2_2_vor.svg, elements.2.elements.2.elements.2.attributes.stroke
  rd_2_2_vor.svg, elements.2.elements.3.elements.1.attributes.stroke
  rd_2_2_vor.svg, elements.2.elements.3.elements.2.attributes.stroke
  rd_2_2_vor.svg, elements.2.elements.4.elements.1.attributes.stroke
  rd_2_2_vor.svg, elements.2.elements.4.elements.2.attributes.stroke
  rd_2_2_vor.svg, elements.2.elements.5.elements.2.attributes.stroke
  rd_2_2_vor.svg, elements.2.elements.5.elements.3.attributes.stroke
  rd_2_2_vor.svg, elements.2.elements.6.elements.2.attributes.stroke
  rd_2_2_vor.svg, elements.2.elements.6.elements.3.attributes.stroke
  rd_2_2_vor.svg, elements.2.elements.6.elements.4.attributes.stroke
  rd_2_2_vor.svg, elements.2.elements.7.elements.2.attributes.stroke
  rd_2_2_vor.svg, elements.2.elements.7.elements.3.attributes.stroke
  rd_2_2_vor.svg, elements.2.elements.7.elements.4.attributes.stroke
  rd_2_2_vor.svg, elements.2.elements.8.elements.2.attributes.stroke
  rd_2_2_vor.svg, elements.2.elements.8.elements.3.attributes.stroke
  rd_2_2_vor.svg, elements.2.elements.8.elements.4.attributes.stroke
  rd_2_2_vor.svg, elements.2.elements.9.elements.2.attributes.stroke
  rd_2_2_vor.svg, elements.2.elements.9.elements.3.attributes.stroke
  rd_2_2_vor.svg, elements.2.elements.9.elements.4.attributes.stroke
  rd_2_2_vor.svg, elements.2.elements.10.elements.2.attributes.stroke
  rd_2_2_vor.svg, elements.2.elements.10.elements.3.attributes.stroke
  rd_2_2_vor.svg, elements.2.elements.10.elements.4.attributes.stroke
  rd_2_2_vor.svg, elements.2.elements.11.elements.3.attributes.stroke
  rd_2_2_vor.svg, elements.2.elements.11.elements.4.attributes.stroke
  rd_2_2_vor.svg, elements.2.elements.11.elements.5.attributes.stroke
  rd_2_2_vor.svg, elements.2.elements.12.elements.3.attributes.stroke
  rd_2_2_vor.svg, elements.2.elements.12.elements.4.attributes.stroke
  rd_2_2_vor.svg, elements.2.elements.12.elements.5.attributes.stroke
  rd_2_3_vor.svg, elements.2.elements.0.elements.1.attributes.stroke
  rd_2_3_vor.svg, elements.2.elements.1.elements.2.attributes.stroke
  rd_2_3_vor.svg, elements.2.elements.1.elements.3.attributes.stroke
  rd_2_3_vor.svg, elements.2.elements.1.elements.4.attributes.stroke
  rd_2_3_vor.svg, elements.2.elements.2.elements.2.attributes.stroke
  rd_2_3_vor.svg, elements.2.elements.2.elements.3.attributes.stroke
  rd_2_3_vor.svg, elements.2.elements.2.elements.4.attributes.stroke
  rd_2_3_vor.svg, elements.2.elements.3.elements.2.attributes.stroke
  rd_2_3_vor.svg, elements.2.elements.3.elements.3.attributes.stroke
  rd_2_3_vor.svg, elements.2.elements.4.elements.1.attributes.stroke
  rd_2_3_vor.svg, elements.2.elements.4.elements.2.attributes.stroke
  rd_2_3_vor.svg, elements.2.elements.5.elements.2.attributes.stroke
  rd_2_3_vor.svg, elements.2.elements.5.elements.3.attributes.stroke
  rd_2_3_vor.svg, elements.2.elements.6.elements.2.attributes.stroke
  rd_2_3_vor.svg, elements.2.elements.6.elements.3.attributes.stroke
  rd_2_3_vor.svg, elements.2.elements.7.elements.1.attributes.stroke
  rd_2_3_vor.svg, elements.2.elements.7.elements.2.attributes.stroke
  rd_2_3_vor.svg, elements.2.elements.8.elements.2.attributes.stroke
  rd_2_3_vor.svg, elements.2.elements.8.elements.3.attributes.stroke
  rd_2_3_vor.svg, elements.2.elements.8.elements.4.attributes.stroke
  rd_2_3_vor.svg, elements.2.elements.9.elements.2.attributes.stroke
  rd_2_3_vor.svg, elements.2.elements.9.elements.3.attributes.stroke
  rd_2_3_vor.svg, elements.2.elements.10.elements.1.attributes.stroke
  rd_2_3_vor.svg, elements.2.elements.10.elements.2.attributes.stroke
  rd_2_3_vor.svg, elements.2.elements.10.elements.3.attributes.stroke
  rd_2_3_vor.svg, elements.2.elements.11.elements.3.attributes.stroke
  rd_2_3_vor.svg, elements.2.elements.11.elements.4.attributes.stroke
  rd_2_3_vor.svg, elements.2.elements.11.elements.5.attributes.stroke
  rd_2_3_vor.svg, elements.2.elements.12.elements.3.attributes.stroke
  rd_2_3_vor.svg, elements.2.elements.12.elements.4.attributes.stroke
  rd_2_3_vor.svg, elements.2.elements.12.elements.5.attributes.stroke
  rd_2_4_vor.svg, elements.2.elements.0.elements.2.attributes.stroke
  rd_2_4_vor.svg, elements.2.elements.0.elements.3.attributes.stroke
  rd_2_4_vor.svg, elements.2.elements.0.elements.4.attributes.stroke
  rd_2_4_vor.svg, elements.2.elements.1.elements.3.attributes.stroke
  rd_2_4_vor.svg, elements.2.elements.1.elements.4.attributes.stroke
  rd_2_4_vor.svg, elements.2.elements.1.elements.5.attributes.stroke
  rd_2_4_vor.svg, elements.2.elements.2.elements.1.attributes.stroke
  rd_2_4_vor.svg, elements.2.elements.2.elements.2.attributes.stroke
  rd_2_4_vor.svg, elements.2.elements.3.elements.2.attributes.stroke
  rd_2_4_vor.svg, elements.2.elements.3.elements.3.attributes.stroke
  rd_2_4_vor.svg, elements.2.elements.3.elements.4.attributes.stroke
  rd_2_4_vor.svg, elements.2.elements.4.elements.1.attributes.stroke
  rd_2_4_vor.svg, elements.2.elements.4.elements.2.attributes.stroke
  rd_2_4_vor.svg, elements.2.elements.5.elements.1.attributes.stroke
  rd_2_4_vor.svg, elements.2.elements.5.elements.2.attributes.stroke
  rd_2_4_vor.svg, elements.2.elements.6.elements.2.attributes.stroke
  rd_2_4_vor.svg, elements.2.elements.6.elements.3.attributes.stroke
  rd_2_4_vor.svg, elements.2.elements.6.elements.4.attributes.stroke
  rd_2_4_vor.svg, elements.2.elements.7.elements.2.attributes.stroke
  rd_2_4_vor.svg, elements.2.elements.7.elements.3.attributes.stroke
  rd_2_4_vor.svg, elements.2.elements.7.elements.4.attributes.stroke
  rd_2_4_vor.svg, elements.2.elements.8.elements.2.attributes.stroke
  rd_2_4_vor.svg, elements.2.elements.8.elements.3.attributes.stroke
  rd_2_4_vor.svg, elements.2.elements.8.elements.4.attributes.stroke
  rd_2_4_vor.svg, elements.2.elements.9.elements.3.attributes.stroke
  rd_2_4_vor.svg, elements.2.elements.9.elements.4.attributes.stroke
  rd_2_4_vor.svg, elements.2.elements.9.elements.5.attributes.stroke
  rd_2_5_vor.svg, elements.2.elements.0.elements.2.attributes.stroke
  rd_2_5_vor.svg, elements.2.elements.0.elements.3.attributes.stroke
  rd_2_5_vor.svg, elements.2.elements.0.elements.4.attributes.stroke
  rd_2_5_vor.svg, elements.2.elements.1.elements.2.attributes.stroke
  rd_2_5_vor.svg, elements.2.elements.1.elements.3.attributes.stroke
  rd_2_5_vor.svg, elements.2.elements.1.elements.4.attributes.stroke
  rd_2_5_vor.svg, elements.2.elements.2.elements.1.attributes.stroke
  rd_2_5_vor.svg, elements.2.elements.2.elements.2.attributes.stroke
  rd_2_5_vor.svg, elements.2.elements.3.elements.2.attributes.stroke
  rd_2_5_vor.svg, elements.2.elements.3.elements.3.attributes.stroke
  rd_2_5_vor.svg, elements.2.elements.4.elements.3.attributes.stroke
  rd_2_5_vor.svg, elements.2.elements.4.elements.4.attributes.stroke
  rd_2_5_vor.svg, elements.2.elements.4.elements.5.attributes.stroke
  rd_2_5_vor.svg, elements.2.elements.5.elements.2.attributes.stroke
  rd_2_5_vor.svg, elements.2.elements.5.elements.3.attributes.stroke
  rd_2_5_vor.svg, elements.2.elements.6.elements.2.attributes.stroke
  rd_2_5_vor.svg, elements.2.elements.6.elements.3.attributes.stroke
  rd_2_5_vor.svg, elements.2.elements.6.elements.4.attributes.stroke
  rd_2_5_vor.svg, elements.2.elements.7.elements.2.attributes.stroke
  rd_2_5_vor.svg, elements.2.elements.7.elements.3.attributes.stroke
  rd_2_5_vor.svg, elements.2.elements.7.elements.4.attributes.stroke
  rd_2_5_vor.svg, elements.2.elements.8.elements.2.attributes.stroke
  rd_2_5_vor.svg, elements.2.elements.8.elements.3.attributes.stroke
  rd_2_5_vor.svg, elements.2.elements.8.elements.4.attributes.stroke
  rd_2_5_vor.svg, elements.2.elements.9.elements.3.attributes.stroke
  rd_2_5_vor.svg, elements.2.elements.9.elements.4.attributes.stroke
  rd_2_5_vor.svg, elements.2.elements.9.elements.5.attributes.stroke
  rd_2_intro.svg, elements.2.elements.0.elements.1.attributes.stroke
  rd_2_intro.svg, elements.2.elements.0.elements.2.attributes.stroke
  rd_2_intro.svg, elements.2.elements.1.elements.1.attributes.stroke
  rd_2_intro.svg, elements.2.elements.1.elements.2.attributes.stroke
  rd_2_intro.svg, elements.2.elements.2.elements.1.attributes.stroke
  rd_2_intro.svg, elements.2.elements.2.elements.2.attributes.stroke
  rd_2_intro.svg, elements.2.elements.2.elements.3.attributes.stroke
  rd_2_intro.svg, elements.2.elements.3.elements.1.attributes.stroke
  rd_2_intro.svg, elements.2.elements.3.elements.2.attributes.stroke
  rd_2_intro.svg, elements.2.elements.3.elements.3.attributes.stroke
  rd_2_intro.svg, elements.2.elements.4.elements.2.attributes.stroke
  rd_2_intro.svg, elements.2.elements.4.elements.3.attributes.stroke
  rd_2_intro.svg, elements.2.elements.5.elements.1.attributes.stroke
  rd_2_intro.svg, elements.2.elements.5.elements.2.attributes.stroke
  rd_2_intro.svg, elements.2.elements.6.elements.2.attributes.stroke
  rd_2_intro.svg, elements.2.elements.6.elements.3.attributes.stroke
  rd_2_intro.svg, elements.2.elements.7.elements.1.attributes.stroke
  rd_2_intro.svg, elements.2.elements.8.elements.1.attributes.stroke
  rd_2_intro.svg, elements.2.elements.9.elements.2.attributes.stroke
  rd_2_intro.svg, elements.2.elements.9.elements.3.attributes.stroke
  rd_2_intro.svg, elements.2.elements.9.elements.4.attributes.stroke
  rd_2_intro.svg, elements.2.elements.10.elements.1.attributes.stroke
  rd_2_intro.svg, elements.2.elements.10.elements.2.attributes.stroke
  rd_2_intro.svg, elements.2.elements.11.elements.1.attributes.stroke
  rd_2_intro.svg, elements.2.elements.11.elements.2.attributes.stroke
  rd_2_intro.svg, elements.2.elements.12.elements.2.attributes.stroke
  rd_2_intro.svg, elements.2.elements.12.elements.3.attributes.stroke
  rd_2_intro.svg, elements.2.elements.12.elements.4.attributes.stroke
  rd_2_intro.svg, elements.2.elements.13.elements.2.attributes.stroke
  rd_2_intro.svg, elements.2.elements.13.elements.3.attributes.stroke
  rd_2_intro.svg, elements.2.elements.14.elements.1.attributes.stroke
  rd_2_intro.svg, elements.2.elements.14.elements.2.attributes.stroke
  rd_2_intro.svg, elements.2.elements.15.elements.2.attributes.stroke
  rd_2_intro.svg, elements.2.elements.15.elements.3.attributes.stroke
  rd_2_intro.svg, elements.2.elements.16.elements.2.attributes.stroke
  rd_2_intro.svg, elements.2.elements.16.elements.3.attributes.stroke
  rd_2_intro.svg, elements.2.elements.17.elements.2.attributes.stroke
  rd_2_intro.svg, elements.2.elements.17.elements.3.attributes.stroke
  rd_2_intro.svg, elements.2.elements.18.elements.3.attributes.stroke
  rd_2_intro.svg, elements.2.elements.18.elements.4.attributes.stroke
  rd_2_intro.svg, elements.2.elements.18.elements.5.attributes.stroke
  */
  stroke: #575756;
}

.svg-FkWzUJ9x7zp{
  /*
  Used In:
  ptv_1_1.svg, elements.2.elements.7.attributes.stroke-width
  ptv_1_2.svg, elements.2.elements.6.attributes.stroke-width
  ptv_1_3.svg, elements.2.elements.7.attributes.stroke-width
  ptv_5.svg, elements.2.elements.0.elements.2.attributes.stroke-width
  ptv_5.svg, elements.2.elements.0.elements.3.attributes.stroke-width
  ptv_5.svg, elements.2.elements.0.elements.4.attributes.stroke-width
  ptv_5.svg, elements.2.elements.0.elements.5.attributes.stroke-width
  ptv_5.svg, elements.2.elements.0.elements.6.attributes.stroke-width
  ptv_5.svg, elements.2.elements.0.elements.7.attributes.stroke-width
  ptv_5.svg, elements.2.elements.0.elements.8.attributes.stroke-width
  ptv_5.svg, elements.2.elements.0.elements.9.attributes.stroke-width
  ptv_5.svg, elements.2.elements.0.elements.10.attributes.stroke-width
  ptv_5.svg, elements.2.elements.1.elements.2.attributes.stroke-width
  ptv_5.svg, elements.2.elements.1.elements.3.attributes.stroke-width
  ptv_5.svg, elements.2.elements.1.elements.4.attributes.stroke-width
  ptv_5.svg, elements.2.elements.1.elements.5.attributes.stroke-width
  ptv_5.svg, elements.2.elements.1.elements.6.attributes.stroke-width
  ptv_5.svg, elements.2.elements.1.elements.7.attributes.stroke-width
  ptv_5.svg, elements.2.elements.1.elements.8.attributes.stroke-width
  ptv_5.svg, elements.2.elements.1.elements.9.attributes.stroke-width
  ptv_5.svg, elements.2.elements.1.elements.10.attributes.stroke-width
  ptv_5.svg, elements.2.elements.2.elements.2.attributes.stroke-width
  ptv_5.svg, elements.2.elements.2.elements.3.attributes.stroke-width
  ptv_5.svg, elements.2.elements.2.elements.4.attributes.stroke-width
  ptv_5.svg, elements.2.elements.2.elements.5.attributes.stroke-width
  ptv_5.svg, elements.2.elements.2.elements.6.attributes.stroke-width
  ptv_5.svg, elements.2.elements.2.elements.7.attributes.stroke-width
  ptv_5.svg, elements.2.elements.2.elements.8.attributes.stroke-width
  ptv_5.svg, elements.2.elements.2.elements.9.attributes.stroke-width
  ptv_5.svg, elements.2.elements.2.elements.10.attributes.stroke-width
  */
  stroke-width: 2;
}

.svg-vJxANwb3a4O{
  /*
  Used In:
  ptv_1_2.svg, elements.2.elements.5.elements.0.attributes.fill
  ptv_1_2.svg, elements.2.elements.8.attributes.fill
  ptv_2_1.svg, elements.2.elements.2.elements.0.attributes.fill
  ptv_2_2.svg, elements.2.elements.3.elements.0.attributes.fill
  ptv_2_3.svg, elements.2.elements.2.elements.0.attributes.fill
  ptv_2_4.svg, elements.2.elements.3.elements.0.attributes.fill
  */
  fill: #565656;
}

.svg-EjQ9kw_n5gG{
  /*
  Used In:
  ptv_1_2.svg, elements.2.elements.0.attributes.stroke
  ptv_1_2.svg, elements.2.elements.2.attributes.stroke
  ptv_2_1.svg, elements.2.elements.1.attributes.stroke
  ptv_2_2.svg, elements.2.elements.0.elements.0.attributes.stroke
  ptv_2_2.svg, elements.2.elements.0.elements.1.attributes.stroke
  ptv_2_2.svg, elements.2.elements.0.elements.2.attributes.stroke
  ptv_2_2.svg, elements.2.elements.0.elements.3.attributes.stroke
  ptv_2_2.svg, elements.2.elements.2.attributes.stroke
  ptv_2_3.svg, elements.2.elements.1.attributes.stroke
  ptv_2_4.svg, elements.2.elements.2.attributes.stroke
  rd_1_2_vor.svg, elements.2.elements.6.attributes.stroke
  rd_1_2_vor.svg, elements.2.elements.8.attributes.stroke
  rd_1_2_vor.svg, elements.2.elements.9.attributes.stroke
  */
  stroke: #565656;
}

.svg-u-aqEEKh9iV{
  /*
  Used In:
  ptv_2_1.svg, elements.2.elements.1.attributes.stroke-width
  ptv_2_2.svg, elements.2.elements.0.elements.0.attributes.stroke-width
  ptv_2_2.svg, elements.2.elements.0.elements.1.attributes.stroke-width
  ptv_2_2.svg, elements.2.elements.0.elements.2.attributes.stroke-width
  ptv_2_2.svg, elements.2.elements.0.elements.3.attributes.stroke-width
  ptv_2_2.svg, elements.2.elements.2.attributes.stroke-width
  ptv_2_3.svg, elements.2.elements.1.attributes.stroke-width
  ptv_2_4.svg, elements.2.elements.2.attributes.stroke-width
  */
  stroke-width: 6;
}

.svg-H2xKUAfo8cD{
  /*
  Used In:
  ptv_2_4.svg, elements.2.elements.4.attributes.font-size
  */
  font-size: 37.4;
}

.svg-AqDkIlLOG4j{
  /*
  Used In:
  ptv_3.svg, elements.2.elements.0.attributes.stroke
  ptv_3.svg, elements.2.elements.1.attributes.stroke
  ptv_3.svg, elements.2.elements.2.attributes.stroke
  */
  stroke: #CCCCCC;
}

.svg-EexU_4_oBG8{
  /*
  Used In:
  ptv_3.svg, elements.2.elements.6.elements.2.attributes.stroke
  ptv_3.svg, elements.2.elements.7.elements.2.attributes.stroke
  */
  stroke: #565655;
}

.svg-yw_3gs9fGSW{
  /*
  Used In:
  ptv_3.svg, elements.2.elements.3.attributes.font-size
  ptv_3.svg, elements.2.elements.4.attributes.font-size
  ptv_3.svg, elements.2.elements.5.attributes.font-size
  */
  font-size: 26.6448;
}

.svg-Xdo4s91WOXn{
  /*
  Used In:
  ptv_4_1.svg, elements.2.elements.3.attributes.fill
  ptv_4_2.svg, elements.2.elements.10.attributes.fill
  ptv_4_3.svg, elements.2.elements.3.attributes.fill
  ptv_4_4.svg, elements.2.elements.7.attributes.fill
  */
  fill: #B4B4B4;
}

.svg-k6Gfr6fiSEA{
  /*
  Used In:
  ptv_4_1.svg, elements.2.elements.10.attributes.fill
  ptv_4_2.svg, elements.2.elements.3.attributes.fill
  ptv_4_3.svg, elements.2.elements.10.attributes.fill
  */
  fill: #7D7D7D;
}

.svg-t8LYDKHHaZV{
  /*
  Used In:
  ptv_4_1.svg, elements.2.elements.4.attributes.stroke
  ptv_4_1.svg, elements.2.elements.5.attributes.stroke
  ptv_4_1.svg, elements.2.elements.6.attributes.stroke
  ptv_4_1.svg, elements.2.elements.11.attributes.stroke
  ptv_4_2.svg, elements.2.elements.4.attributes.stroke
  ptv_4_2.svg, elements.2.elements.5.attributes.stroke
  ptv_4_2.svg, elements.2.elements.6.attributes.stroke
  ptv_4_2.svg, elements.2.elements.11.attributes.stroke
  ptv_4_2.svg, elements.2.elements.12.attributes.stroke
  ptv_4_2.svg, elements.2.elements.13.attributes.stroke
  ptv_4_3.svg, elements.2.elements.4.attributes.stroke
  ptv_4_3.svg, elements.2.elements.5.attributes.stroke
  ptv_4_3.svg, elements.2.elements.6.attributes.stroke
  ptv_4_3.svg, elements.2.elements.11.attributes.stroke
  ptv_4_4.svg, elements.2.elements.2.attributes.stroke
  ptv_4_4.svg, elements.2.elements.8.attributes.stroke
  ptv_4_4.svg, elements.2.elements.9.attributes.stroke
  */
  stroke: #575757;
}

.svg-3tuyvIFV4ns{
  /*
  Used In:
  ptv_4_1.svg, elements.2.elements.16.elements.0.attributes.stroke-width
  ptv_4_2.svg, elements.2.elements.16.elements.0.attributes.stroke-width
  ptv_4_3.svg, elements.2.elements.16.elements.0.attributes.stroke-width
  ptv_4_4.svg, elements.2.elements.12.elements.0.attributes.stroke-width
  */
  stroke-width: 16;
}

.svg-hI4gTZcwV_T{
  /*
  Used In:
  ptv_4_1.svg, elements.2.elements.8.attributes.font-size
  ptv_4_1.svg, elements.2.elements.15.attributes.font-size
  ptv_4_2.svg, elements.2.elements.8.attributes.font-size
  ptv_4_2.svg, elements.2.elements.15.attributes.font-size
  ptv_4_3.svg, elements.2.elements.8.attributes.font-size
  ptv_4_3.svg, elements.2.elements.15.attributes.font-size
  ptv_4_4.svg, elements.2.elements.5.attributes.font-size
  ptv_4_4.svg, elements.2.elements.11.attributes.font-size
  */
  font-size: 32;
}

.svg-faDjA8hLnPf{
  /*
  Used In:
  ptv_5.svg, elements.2.elements.4.attributes.font-size
  ptv_5.svg, elements.2.elements.6.attributes.font-size
  ptv_5.svg, elements.2.elements.8.attributes.font-size
  */
  font-size: 36;
}

.svg-UyGKf_vNG3h{
  /*
  Used In:
  rd_1_2_vor.svg, elements.2.elements.1.attributes.gradientUnits
  rd_1_2_vor.svg, elements.2.elements.4.attributes.gradientUnits
  */
  gradientUnits: userSpaceOnUse;
}

.svg-fyyYUvmxZSL{
  /*
  Used In:
  rd_1_3_vor.svg, elements.2.elements.2.attributes.fill
  rd_1_4_vor.svg, elements.2.elements.0.elements.0.attributes.fill
  */
  fill: #DADADA;
}

.svg-TY2qZuXMYYB{
  /*
  Used In:
  rd_1_4_vor.svg, elements.2.elements.0.elements.2.attributes.fill
  */
  fill: #B0B0B0;
}

